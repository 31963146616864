/* eslint-disable react/prop-types */

import { format } from "date-fns";
import MDTypography from "components/MDTypography";

import { useMaterialUIController } from "context";

export default function useReferralData(referralData = []) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return {
    columns: [
      { Header: "Code", accessor: "code", align: "left" },
      { Header: "Date", accessor: "date", align: "left" },
      { Header: "Visits", accessor: "visits", align: "left" },
      { Header: "Charts", accessor: "charts", align: "left" },
      { Header: "Users", accessor: "users", align: "left" },
    ],
    rows: referralData.map((data) => {
      const visitsCount = Number(data?.traffic_count ?? "0").toFixed(0);
      const chartsCount = Number(data?.total_contract_count ?? "0").toFixed(0);
      const usersCount = Number(data?.total_ip_source_count ?? "0").toFixed(0);
      return {
        code: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data?.code ?? "-"}
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {format(new Date(data?.created_date), "yyyy-MM-dd")}
          </MDTypography>
        ),
        visits: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {visitsCount}
          </MDTypography>
        ),
        charts: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {chartsCount}
          </MDTypography>
        ),
        users: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {usersCount}
          </MDTypography>
        ),
      };
    }),
  };
}
