// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol) {
  // Match the format: шайлушай/WETH
  let match = fullSymbol.match(/^([\w$]+)\/([\w$]+)$/);

  // If the first format doesn't match, try the second format: $STACK/WETH
  if (!match) {
    match = fullSymbol.match(/^\$([\w$]+)\/([\w$]+)$/);
  }

  // If the second format doesn't match, try the third format: HELLO/WETH
  if (!match) {
    match = fullSymbol.match(/^([\w$]+)\/([\w$]+)$/);
  }

  // If the third format doesn't match, try the fourth format: 👋 /WETH
  if (!match) {
    match = fullSymbol.match(/^([\s\S]+)\/([\w$]+)$/); // Match any character including whitespace
  }

  // If the fourth format doesn't match, try the fifth format: 11234/WETH
  if (!match) {
    match = fullSymbol.match(/^([\d]+)\/([\w$]+)$/); // Match digits for the fromSymbol
  }

  if (!match) {
    return null;
  }

  return {
    exchange: match[1] || "0xHub",
    fromSymbol: match[2],
    toSymbol: match[3],
  };
}
