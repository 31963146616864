/* eslint-disable react/prop-types */

import React from "react";
import ReactGA from "react-ga4";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { debounce, Grid, Icon, InputAdornment, Chip, Modal } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import TextAd from "../../../layouts/advertisement/textAd";

import { truncate } from "lodash";
import { useMaterialUIController } from "context";
import { projectAtom } from "atoms";
import { getChainForUrl, getLogoByChain } from "utils/chain";
import useApi from "utils/useApi";
import useMobileView from "utils/useMobileView";

const SearchModal = ({ open, onClose }) => {
  const [controller] = useMaterialUIController();
  const { searchTokens } = useApi();
  const { mobileView } = useMobileView();
  const { darkMode } = controller;
  const projectData = useRecoilValue(projectAtom);
  const projectsList = projectData?.trendingProjects ?? [];
  const [options, setOptions] = React.useState([]);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [selectedChain, setSelectedChain] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const chains = ["eth", "base", "bsc", "polygon"];

  const handleSearch = async (keyword) => {
    ReactGA.event({
      category: "Search",
      action: "handleSearch",
      label: keyword,
    });

    setIsLoading(true);
    const data = await searchTokens(keyword);
    setOptions(data);
    setIsLoading(false);
  };

  const debouncedFetch = React.useCallback(
    debounce((newValue) => handleSearch(newValue), 1000),
    []
  );

  const handleInputChange = async (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
    debouncedFetch(keyword);
  };

  const handleClose = () => {
    onClose();
    setSearchKeyword("");
    setOptions([]);
    setSelectedChain("");
  };

  const filteredOptions = options.filter((option) => {
    if (selectedChain && option.chain !== selectedChain) {
      return false;
    }
    if (
      searchKeyword &&
      !(
        option.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        option.symbol.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        option.pool_address.toLowerCase().includes(searchKeyword.toLowerCase())
      )
    ) {
      return false;
    }
    return true;
  });
  const handleChipClick = (chain) => {
    setSelectedChain((prevSelectedChain) => (chain === prevSelectedChain ? null : chain));
  };

  const marketCapTok = (value) => {
    if (typeof value !== "number") {
      return value;
    }
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(2)}M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(2)}K`;
    }
    return value.toString();
  };
  return (
    <Modal open={open} onClose={handleClose} color="info">
      <MDBox
        bgColor={darkMode ? "#1A1F2B" : "light"}
        color="text"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", md: "75%", lg: "60vw" },
          border: "2px solid #000",
          boxShadow: 24,
          p: 2,
          pt: 0,
          height: { xs: "auto", sm: "auto" },
          minHeight: "60vh",
          maxHeight: { xs: "100%", sm: "80vh" },
          overflow: "scroll",
        }}
      >
        <Grid container spacing={2} display={"flex"}>
          {/* Search bar */}
          <Grid
            item
            xs={12}
            sx={{
              paddingBottom: "16px",
              position: "sticky",
              top: -1,
              zIndex: 1,
              backgroundColor: darkMode ? "#1A1F2B" : "light",
            }}
          >
            <MDInput
              color="text"
              type="text"
              autoFocus
              placeholder="Search by name, symbol or contract"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      color={darkMode ? "white" : "text"}
                      sx={({ typography: { size } }) => ({
                        fontSize: "25px !important",
                      })}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: { xs: "94%", sm: "100%" },
                "& fieldset": {
                  borderRadius: "50px",
                  border: "1.3px solid transparent !important",
                  background: "linear-gradient(70deg, #0062ff, #29e49d 70%) border-box !important",
                  WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
            {mobileView && (
              <Icon
                sx={({ typography: { size } }) => ({
                  fontSize: 30,
                  "&:hover": {
                    color: "red",
                  },
                  position: "absolute",
                  top: "50%",
                  right: -3,
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                })}
                onClick={handleClose}
              >
                close
              </Icon>
            )}
          </Grid>
          {/* Advertisement */}
          <Grid item xs={12}>
            <TextAd placement={"project_left_bar"} />
          </Grid>
          {/* List */}
          <Grid item xs={6} sm={4}>
            <MDTypography fontWeight="bold">
              {!searchKeyword && !selectedChain ? "Trending Tokens:" : "Search Result:"}
            </MDTypography>
          </Grid>
          <Grid container item xs={12} sm={8} justifyContent="flex-end" alignItems="center">
            {searchKeyword &&
              chains.map((chain) => (
                <MDTypography
                  fontSize={"13px"}
                  variant="button"
                  color="regular"
                  key={chain}
                  sx={{ marginLeft: "4px" }}
                >
                  <Chip
                    avatar={<MDAvatar alt={"alt"} src={getLogoByChain(chain)} size="xs" />}
                    label={chain}
                    value={chain}
                    color="secondary"
                    variant={selectedChain === chain ? "filled" : "outlined"}
                    sx={{
                      padding: "2px 2px",
                      height: "22px",
                      textTransform: "lowercase",
                      "& .MuiChip-label": {
                        display: "flex",
                        textTransform: "lowercase",
                        alignItems: "center",
                      },
                    }}
                    size="small"
                    onClick={() => handleChipClick(chain)}
                  />
                </MDTypography>
              ))}
          </Grid>
          {isLoading ? (
            <Grid item xs={12}>
              <MDTypography>Loading...</MDTypography>
            </Grid>
          ) : searchKeyword && filteredOptions.length === 0 ? (
            <Grid item xs={6} sm={4}></Grid>
          ) : !searchKeyword ? (
            <>
              {projectsList.map((project) => (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={project.id}>
                  <Link
                    to={`/${getChainForUrl(project.chain)}/${project.pool_address}`}
                    style={{ color: "inherit" }}
                  >
                    <MDBox
                      variant="button"
                      display="flex"
                      alignItems="center"
                      height="100%"
                      marginBottom={2}
                      bgColor={darkMode ? "transparent" : "rgba(0, 0, 0, 0.08)"}
                      borderRadius="xl"
                      border={darkMode ? "0.1px solid #344767" : "1px solid #b0adad"}
                      sx={{
                        cursor: "pointer",
                        overflow: "hidden",
                      }}
                      onClick={handleClose}
                    >
                      <MDAvatar
                        src={project.logo}
                        alt={project.name}
                        sx={{ width: 40, height: 40, ml: 1, mr: 1 }}
                      />
                      <MDTypography
                        variant="button"
                        fontWeight="medium"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {truncate(project?.name, {
                          length: 16,
                        })}
                        {project.pairSymbol && project.chain && (
                          <>
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              <small>{project.pairSymbol}</small>
                              <Chip
                                label={project.chain}
                                value={project.chain}
                                variant="outlined"
                                sx={{
                                  marginLeft: 1,
                                  color: "inherit",
                                  height: "16px",
                                  textTransform: "lowercase",
                                  "& .MuiChip-label": {
                                    display: "flex",
                                    textTransform: "lowercase",
                                    alignItems: "center",
                                  },
                                }}
                                size="small"
                              />
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              color="text"
                              fontWeight="small"
                              fontSize="13px"
                              sx={{
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <small>
                                <b>MCap:</b> {marketCapTok(project.marketcap)}
                              </small>
                              <small>
                                <b>Liquidity:</b> {marketCapTok(project.liquidity)}
                              </small>
                            </MDTypography>
                          </>
                        )}
                      </MDTypography>
                    </MDBox>
                  </Link>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {filteredOptions.map((project) => (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={project.id}>
                  <Link
                    to={`/${getChainForUrl(project.chain)}/${project.pool_address}`}
                    style={{ color: "inherit" }}
                  >
                    <MDBox
                      variant="button"
                      display="flex"
                      alignItems="center"
                      height="100%"
                      marginBottom={2}
                      bgColor={darkMode ? "#141b2c" : "rgba(0, 0, 0, 0.08)"}
                      borderRadius="xl"
                      border={darkMode ? "1px solid #d6d6d6" : "1px solid #b0adad"}
                      sx={{
                        cursor: "pointer",
                        overflow: "hidden",
                      }}
                      onClick={handleClose}
                    >
                      <MDAvatar
                        src={project.logo || project.name[0]}
                        alt={project.name}
                        sx={({ borders: { borderWidth }, palette: { white } }) => ({
                          width: 40,
                          color: "inherit",
                          height: 40,
                          ml: 1,
                          mr: 1,
                          border: `${borderWidth[1]} solid ${white.main}`,
                          boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)",
                          // backgroundColor: "#f0f2f5",
                        })}
                      />
                      <MDTypography
                        variant="button"
                        fontWeight="medium"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {truncate(project?.name, {
                          length: 16,
                        })}
                        {project.pairSymbol && project.chain && (
                          <>
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              <small>{project.pairSymbol}</small>
                              <Chip
                                label={project.chain}
                                value={project.chain}
                                variant="outlined"
                                sx={{
                                  marginLeft: 1,
                                  color: "inherit",
                                  height: "16px",
                                  textTransform: "lowercase",
                                  "& .MuiChip-label": {
                                    display: "flex",
                                    textTransform: "lowercase",
                                    alignItems: "center",
                                  },
                                }}
                                size="small"
                              />
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              color="text"
                              fontWeight="small"
                              fontSize="13px"
                              sx={{
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <small>
                                <b>MCap:</b> {marketCapTok(project.stats.fullyDilutedMarketCap)}
                              </small>
                              <small>
                                <b>Liquidity:</b> {marketCapTok(project.stats.trueValue * 2)}
                              </small>
                            </MDTypography>
                          </>
                        )}
                      </MDTypography>
                    </MDBox>
                  </Link>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </MDBox>
    </Modal>
  );
};

export default SearchModal;
