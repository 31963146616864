/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/*  */

import { format, parseISO } from "date-fns";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Link } from "react-router-dom";

import { useMaterialUIController } from "context";

import AddressCopy from "layouts/project/addressCopyWithTooltip";
import { numPrettyFormatter, getDecimalValue, formatPriceWithDecimals } from "utils/price";
import { getAddressLinkByChain } from "layouts/project/utils";
import { getChainForUrl } from "utils/chain";
import { truncate } from "lodash";

export default function useDashboardData(projects = []) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const Company = ({ image, name, pairSymbol, pair, chain }) => (
    <Link to={pair ? `/${getChainForUrl(chain)}/${pair}` : null}>
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar
          src={image}
          name={name}
          size="sm"
          sx={{
            marginRight: "8px",
            color: darkMode ? "#ffffffCC" : "#0A0A0A",
            fontWeight: "700",
            border: darkMode ? "0.5px solid #f0f2f5" : "none",
            boxShadow: 1,
            background: darkMode ? "transparent" : "#f0f2f5",
          }}
        >
          {name?.[0]}
        </MDAvatar>
        <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          {name}
          <br />
          <MDTypography variant="button" color="text" fontWeight="regular">
            <small>{pairSymbol}</small>
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Link>
  );

  return {
    columns: [
      { Header: "projects", accessor: "projects", align: "left", sortable: true },
      { Header: "Contract", accessor: "contract", align: "left" },
      { Header: "Last Traded", accessor: "lastTradeTime", align: "left", sortable: true },
      { Header: "Volume (1h)", accessor: "volume1h", align: "left", sortable: true },
      { Header: "Volume (24h)", accessor: "volume24h", align: "left", sortable: true },
      { Header: "Marketcap", accessor: "mcap", align: "left", sortable: true },
      { Header: "Price", accessor: "price", align: "left", sortable: true },
      // { Header: "Created", accessor: "created", align: "left" },
      { Header: "Liquidity", accessor: "liquidity", align: "left", sortable: true },
      // { Header: "Score", accessor: "score", align: "left" },
    ],
    rows: projects.map((project) => {
      const { stats = {} } = project;
      const { prices = {} } = stats;
      const volumePercentage = prices.day_1?.prettyPrint;
      const volumeAmount = prices.day_1?.volume;
      const lastHourVolumePercentage = prices.hour_1?.prettyPrint;
      const lastHourVolumeAmount = prices.hour_1?.volume;
      return {
        projects: (
          <Company
            image={project?.logo}
            name={`${truncate(project.name, { length: 20 })}`}
            pair={project.pool_address}
            pairSymbol={project?.pairSymbol}
            chain={project?.chain}
          />
        ),
        contract: (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{
              ...contractStyle,
            }}
          >
            <AddressCopy
              address={project.address?.toUpperCase()}
              fullUrl={getAddressLinkByChain(project?.chain, project?.address, "token")}
              fontSize={"12px"}
            />
          </MDTypography>
        ),
        mcap: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {numPrettyFormatter(stats?.fullyDilutedMarketCap ?? 0)}
          </MDTypography>
        ),
        lastTradeTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {format(new Date(project?.lastTradeTime * 1000), "yyyy-MM-dd HH:mm:ss")}
          </MDTypography>
        ),
        liquidity: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {numPrettyFormatter(stats?.trueValue * 2 ?? 0)}
          </MDTypography>
        ),
        price: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            $ {formatPriceWithDecimals(prices?.live)}
          </MDTypography>
        ),
        created: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {/* {format(parseISO(project.created_on), "yyyy-MM-dd")} */}
          </MDTypography>
        ),
        volume1h: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            <MDTypography
              component="span"
              variant="button"
              fontWeight="bold"
              color={lastHourVolumePercentage?.[0] === "+" ? "success" : "error"}
            >
              ${numPrettyFormatter(lastHourVolumeAmount)}&nbsp;
              <small>({lastHourVolumePercentage})</small>
            </MDTypography>
          </MDTypography>
        ),
        volume24h: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            <MDTypography
              component="span"
              variant="button"
              fontWeight="bold"
              color={volumePercentage?.[0] === "+" ? "success" : "error"}
            >
              ${numPrettyFormatter(volumeAmount)}&nbsp;<small>({volumePercentage})</small>
            </MDTypography>
          </MDTypography>
        ),
      };
    }),
  };
}

const contractStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
