import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// 0xHub Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Trending from "examples/Trending";
import ShareInTwitter from "layouts/common/shareInTwitter";

import { createTweetIntentLink } from "../project/utils";
import useProjectData from "layouts/dashboard/components/Projects/data";

import { useMaterialUIController } from "context";
import { projectAtom } from "atoms";

const TrendingView = () => {
  const projectData = useRecoilValue(projectAtom);
  const [controller] = useMaterialUIController();
  const { selectedChain } = controller;

  const trendingProjects = projectData?.trendingProjects ?? [];
  const { columns, rows } = useProjectData(trendingProjects);

  const tweetIntentLink = getTweetShareLink(trendingProjects, selectedChain);

  const { pathname } = useLocation();
  const titleKey = pathname.replace("/", "");
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Trending />
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                bgColor="inherit"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" style={displayFlexCenter}>
                  <MDTypography fontWeight="bold">{getTitle()[titleKey]}</MDTypography>
                  <MDTypography
                    component="div"
                    variant="button"
                    fontWeight="bold"
                    color={"red"}
                    sx={{ paddingTop: "6px", marginLeft: "4px" }}
                  >
                    {getIcon()[titleKey]}
                  </MDTypography>
                </MDTypography>
                <ShareInTwitter title={"Tweet"} tweetLinkWithContent={tweetIntentLink} />
              </MDBox>
              <hr style={{ border: "1px solid inherit" }} />
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default TrendingView;

const getTitle = () => ({
  gainers: "Gainers",
  losers: "Losers",
  recent: "Recently Added",
  trending: "Trending",
});

const getIcon = () => ({
  gainers: (
    <Icon fontSize="small" color="success">
      moving
    </Icon>
  ),
  losers: (
    <Icon fontSize="small" color="error">
      trending_down
    </Icon>
  ),
  recent: (
    <Icon fontSize="small" color="info">
      stroller
    </Icon>
  ),
  trending: (
    <Icon fontSize="small" color="error">
      whatshot
    </Icon>
  ),
});

const displayFlexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

const getTweetShareLink = (projects = [], chain = "") => {
  const hashtag = chain === "ethereum" ? " #ETH " : chain === "bnb" ? " #BNB " : " ";
  if (projects.length === 0) {
    return "#";
  }
  const url = `https://0xhub.io/trending`;

  let trendingList = "";
  projects.forEach((project, i) => {
    trendingList += `${trendingNumber[`${i + 1}`]} $${project.symbol?.replace("$", "")}\n`;
  });
  const text = `Top 10 Trending${hashtag}tokens in 0xHub 🔥🚀

${trendingList}
Check out real-time market data on 0xHub:`;
  return createTweetIntentLink(url, text);
};

const trendingNumber = {
  1: "1️⃣",
  2: "2️⃣",
  3: "3️⃣",
  4: "4️⃣",
  5: "5️⃣",
  6: "6️⃣",
  7: "7️⃣",
  8: "8️⃣",
  9: "9️⃣",
  10: "🔟",
};
