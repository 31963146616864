import { useRecoilState } from "recoil";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";

// 0xHub Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import Trending from "examples/Trending";

import { projectAtom } from "atoms";
import useMobileView from "utils/useMobileView";
import { Link } from "react-router-dom";

function Dashboard() {
  const [projectData, setProjectData] = useRecoilState(projectAtom);
  const { mobileView } = useMobileView();

  const isFetching = false;
  const isLoading = false;

  const refetchAllProjects = async (pageOffset = 0, isResetCache = true) => {
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      pageNumber: pageOffset + 1,
    }));
  };

  const projectsList = projectData?.allProjects ?? [];
  const gainersProjects = projectData?.gainersProjects ?? [];
  const losersProjects = projectData?.losersProjects ?? [];
  const recentlyAddedProjects = projectData?.recentlyAddedProjects ?? [];
  const totalProjectCount = projectData?.totalProjects ?? 0;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Trending />
      <MDBox py={1}>
        <MDBox
          sx={{
            marginTop: mobileView ? "4px" : "16px",
            marginBottom: mobileView ? "12px" : "24px",
          }}
        >
          <ImageGallery
            items={images}
            showBullets={false}
            infinite={true}
            autoPlay={true}
            showPlayButton={false}
            useBrowserFullscreen={false}
            showFullscreenButton={false}
            showNav={false}
            slideDuration={500}
            slideInterval={8000}
            renderItem={(item) => (
              <div className={`image-gallery-image`}>
                <Link to={item.url} target={item.url ? "_blank" : "_self"}>
                  <img
                    src={item.original}
                    alt="hero banner ad"
                    style={{
                      height: "auto",
                      width: mobileView ? "100%" : "auto",
                    }}
                  />
                </Link>
              </div>
            )}
          />
        </MDBox>
        <Grid
          container
          rowSpacing={{ xs: 0, sm: 1, md: 1 }}
          columnSpacing={{ xs: 0, sm: 1, md: 1 }}
        >
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                color="dark"
                title="0xHub Listed Projects"
                count={totalProjectCount}
                percentage={{
                  color: "success",
                  amount: "+10",
                  label: "Past 24 hours",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                title="Today's Gainers"
                count={gainersProjects?.length ?? "0"}
                linkTo={"/gainers"}
                percentage={{
                  color: "success",
                  amount: "+0%",
                  label: "Past 24 hours",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                color="success"
                title="Today's Losers"
                count={losersProjects?.length ?? "0"}
                linkTo={"/losers"}
                percentage={{
                  color: "success",
                  amount: "+0%",
                  label: "Past 24 hours",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1}>
              <ComplexStatisticsCard
                color="primary"
                title="Recently Added"
                linkTo={"/recent"}
                count={recentlyAddedProjects?.length ?? "0"}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Past 7 days",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Projects
                projects={projectsList}
                refetchProjects={(pageIndex, isReset = true) =>
                  refetchAllProjects(pageIndex, isReset)
                }
                totalProjectCount={totalProjectCount}
                // currentPage={currentPage}
                isLoading={isFetching | isLoading}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

const images = [
  {
    original: "https://0xhub.s3.eu-west-1.amazonaws.com/landing-page-images/0xhub-img-token.jpg",
    originalHeight: 100,
  },
  {
    original: "https://0xhub.s3.eu-west-1.amazonaws.com/landing-page-images/0xHub-chains.jpg",
    originalHeight: 100,
  },
  {
    original: "https://0xhub.s3.eu-west-1.amazonaws.com/landing-page-images/0xhub+sign+up.jpg",
    originalHeight: 100,
    url: "https://0xhub.io/authentication/sign-up",
  },
];
