/* eslint-disable react/prop-types */
import { useState } from "react";
import FileUpload from "react-material-file-upload";
import { toast } from "react-toastify";
import { Grid, Modal, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import useApi from "utils/useApi";
import AddressCopy from "layouts/project/addressCopyWithTooltip";

const initialValues = [
  {
    label: "Pair Address",
    name: "pair",
    required: true,
    disabled: true,
  },
  {
    label: "Contract Address",
    name: "contract",
    required: true,
    disabled: true,
  },
  {
    label: "Website",
    name: "website",
    required: true,
    placeholder: "Project website link",
  },
  {
    label: "Twitter",
    name: "twitter",
    required: true,
    placeholder: "Project X link",
  },
  {
    label: "Telegram",
    name: "telegram",
    placeholder: "Project telegram link",
  },
  {
    label: "Whitepaper",
    name: "whitepaper",
    placeholder: "Whitepaper or docs link",
  },
  {
    label: "Medium",
    name: "medium",
    placeholder: "Medium link",
  },
  {
    label: "Discord",
    name: "discord",
    placeholder: "Discord link",
  },
  {
    label: "Youtube",
    name: "youtube",
    placeholder: "Youtube channel",
  },
  {
    label: "Github",
    name: "github",
    placeholder: "Github link",
  },
  {
    label: "Email",
    name: "email",
    placeholder: "Project contact email",
  },
  {
    label: "Circulating supply",
    name: "supply",
    type: "number",
    placeholder: "Token circulating supply",
  },
  {
    label: "CMC",
    name: "cmc",
    placeholder: "Coinmarketcap link",
  },
  {
    label: "CG",
    name: "cg",
    placeholder: "Coingecko link",
  },
  {
    label: "Description",
    name: "description",
    placeholder: "Project short description",
  },
  // TODO: when payment is implemented
  // {
  //   label: "Transaction Link/Hash",
  //   name: "transaction",
  //   required: true,
  // },
];
const valuesObj = initialValues.reduce((acc, curr) => {
  acc[curr.name] = "";
  return acc;
}, {});

const requiredFields = initialValues.filter((o) => o.required).map((each) => each.name);

const ProjectInfoSubmitModal = ({
  open,
  onClose,
  projectId,
  contractAddress,
  pairAddress,
  chain,
}) => {
  const [adPayload, setAdPayload] = useState({
    ...valuesObj,
    pair: pairAddress,
    contract: contractAddress,
  });
  const [controller] = useMaterialUIController();
  const { createListingRequest } = useApi();
  const { darkMode } = controller;
  const [error, setError] = useState(valuesObj);
  const [files, setFiles] = useState([]);
  const [isSubmitPending, setIsSubmitPending] = useState(false);

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setAdPayload({
      ...adPayload,
      [field]: value,
    });
    if (requiredFields.includes(field)) {
      setError({
        ...error,
        [field]: value ? "" : `${field} is required`,
      });
    }
  };

  const handleSetFiles = (files) => {
    setFiles(files);
    setError({
      ...error,
      logo: files?.length ? "" : "Logo is required",
    });
  };

  const validateRequiredFields = (data) => {
    const newError = {};
    let isValid = true;

    for (let field of requiredFields) {
      if (!data[field]) {
        newError[field] = "This field is required";
        isValid = false;
      }
    }
    if (files.length === 0) {
      newError["logo"] = "Logo is required";
      isValid = false;
    }
    setError(newError);
    return isValid;
  };

  const handleClose = () => {
    onClose();
    setError(valuesObj);
    setAdPayload({
      ...valuesObj,
      pair: pairAddress,
      contract: contractAddress,
    });
    setIsSubmitPending(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateRequiredFields(adPayload)) {
      return;
    }
    setIsSubmitPending(true);
    // Create a new FormData object
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("project_id", projectId);
    formData.append("chain", chain);
    Object.keys(valuesObj).forEach((key) => {
      // Append the corresponding value from adPayload if it exists
      formData.append(key, adPayload[key] || valuesObj[key]);
    });

    // Submit
    try {
      await createListingRequest(formData);
      handleClose();
      setFiles([]);
      toast.success("Your token update request has been submitted successfully!", {
        autoClose: 10000,
      });
    } catch (e) {
      setIsSubmitPending(false);
      toast.error(`Your token update request failed: ${e?.message}`, {
        autoClose: 20000,
      });
    }
  };

  const isSubmitDisabled = [...requiredFields, "logo"].some((field) => error?.[field]?.length > 0);

  return (
    <Modal open={open} onClose={handleClose} color="info">
      <MDBox
        bgColor={darkMode ? "#1A1F2B" : "light"}
        color="text"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", md: "70%", lg: "60%" },
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          height: { xs: "100%", sm: "90vh" },
          overflow: "scroll",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <MDTypography variant="h4" fontWeight="medium">
              0xHub.io - Project info update
            </MDTypography>
          </Grid>
          <Grid item xs={4}>
            <Icon
              sx={({ typography: { size }, palette: { dark, white } }) => ({
                fontSize: `${size.lg} !important`,
                color: "light",
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                transform: "translateY(5px)",
                fontSize: 30,
                cursor: "pointer",
                "&:hover": {
                  color: "red",
                },
              })}
              style={{
                position: "absolute",
                top: "24px",
                right: "20px",
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <MDTypography variant="button" fontWeight="small">
              Please provide your project/token information by filling out the form below. We will
              update your information as soon as possible.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <MDTypography variant="button" fontWeight="medium">
              Price: <small style={{ fontWeight: 400, fontSize: 13 }}>Free (0 ETH)</small>
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <MDTypography variant="button" fontWeight="medium">
              One-time payment benefits:
            </MDTypography>
            <MDTypography variant="button" fontWeight="small">
              1. Social info update forever for a single contract.
            </MDTypography>
            <MDTypography variant="button" fontWeight="small">
              2. Official thank-you tweet highlighting the basic info of the project.
            </MDTypography>
            <MDTypography variant="button" fontWeight="small">
              3. Real-time notification to our private and insider groups.
            </MDTypography>
            <MDTypography variant="button" fontWeight="small">
              4. Advertisement and trending on 0xhub.io randomly in the free slots.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <MDTypography variant="button" fontWeight="medium">
              Need Help?
            </MDTypography>
            <MDTypography variant="button" fontWeight="small">
              Contact 0xHub admins for support:
              <AddressCopy
                address={"https://t.me/OxHub_io"}
                fullUrl={"https://t.me/OxHub_io"}
                fontSize={"12px"}
                addressFormat="20"
              />
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
            <MDTypography variant="subtitle2" fontWeight="small">
              <b>Note: </b>You can apply for token info updates for free for an unlimited period
              after your first update.
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography
              variant="text"
              fontWeight="regular"
              mb={1}
              mt={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Logo upload (
              <div style={{ fontSize: 12 }}>
                <span style={{ color: "red" }}>*</span>
              </div>
              )
            </MDTypography>
            <MDTypography
              variant="text"
              fontWeight="regular"
              mt={-1}
              mb={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              <div style={{ fontSize: 12 }}>Recommended size 400 x 400</div>
            </MDTypography>
            <MDBox
              sx={{
                color: "inherit",
                "& > div": {
                  border: "1px solid gray !important",
                  padding: "8px",
                },
                "& svg": {
                  color: "gray !important",
                },
              }}
              mb={2}
            >
              <FileUpload
                sx={{
                  ".MuiFormHelperText-root": {
                    color: "#344767 !important",
                  },
                  "li>div": {
                    color: "#344767 !important",
                  },
                }}
                maxFiles={1}
                value={files}
                onChange={handleSetFiles}
              />
            </MDBox>
          </Grid>
          {initialValues.map(
            ({
              label,
              name,
              disabled = false,
              required = false,
              type = "text",
              placeholder = "",
            }) => (
              <Grid item xs={12} sm={name === "transaction" ? 12 : 6} key={name}>
                <MDTypography variant="subtitle2" fontWeight="medium">
                  {label}
                  {required && <span style={{ color: "red" }}>*</span>}
                </MDTypography>
                <MDInput
                  type={type}
                  multiline={name === "description"}
                  rows={5}
                  label={placeholder}
                  error={!!error[name]}
                  value={adPayload[name]}
                  fullWidth
                  InputProps={{
                    disabled: disabled,
                  }}
                  onChange={(e) => handleInputChange(e, name)}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "text",
                    },
                    "& .MuiInputBase-input": {
                      color: "text",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      background: "#FFFFFF1A !important",
                    },
                  }}
                />
              </Grid>
            )
          )}
        </Grid>
        <MDBox sx={{ display: "flex", mt: 2, mb: 1, justifyContent: "flex-end" }}>
          <MDButton variant="outlined" color="error" onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </MDButton>
          <MDButton
            disabled={isSubmitDisabled || isSubmitPending}
            variant="gradient"
            color="info"
            onClick={handleSubmit}
          >
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ProjectInfoSubmitModal;
