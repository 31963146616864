import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import MDBox from "components/MDBox";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useMaterialUIController } from "context";

function DataTable({ table, rowsPerPageActiveTrade }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const columns = useMemo(() => table.columns, [table]);
  const rows = useMemo(() => table.rows, [table]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageActiveTrade || 20);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [iconVisibility, setIconVisibility] = useState(columns.map(() => true));
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key, clickedIndex) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const newIconVisibility = iconVisibility.map((visibility, index) =>
      index === clickedIndex ? false : true
    );
    setIconVisibility(newIconVisibility);
  };

  const sortedRows = useMemo(() => {
    if (sortConfig.key) {
      return [...rows].sort((a, b) => {
        let valueA = a[sortConfig.key];
        let valueB = b[sortConfig.key];

        if (sortConfig.key === "projects") {
          valueA = valueA.props.name.toLowerCase();
          valueB = valueB.props.name.toLowerCase();
        } else {
          valueA = valueA.props.children;
          valueB = valueB.props.children;
        }
        const parseVolumeChange = (volumeChange) => {
          if (volumeChange && volumeChange.props && volumeChange.props.children) {
            const percentageElement = volumeChange.props.children.find(
              (child) =>
                child && child.props && child.props.children && Array.isArray(child.props.children)
            );
            if (percentageElement) {
              const percentageString = percentageElement.props.children
                .filter((child) => typeof child === "string")
                .join("");
              const numericString = percentageString.replace(/[^0-9.-]/g, "");
              const numericValue = parseFloat(numericString);
              return numericValue;
            }
          }
          return NaN;
        };

        const parseOneDayChange = (change) => {
          if (change && change.props && change.props.children) {
            const percentageString = Array.isArray(change.props.children)
              ? change.props.children.join("")
              : change.props.children;
            const numericString = percentageString.replace(/[^0-9.-]/g, "");
            const numericValue = parseFloat(numericString);
            return numericValue;
          }
          return NaN;
        };
        const parseMarketcap = (marketcap) => {
          const numericString = marketcap.replace(/[^0-9.]/g, "");
          if (marketcap.endsWith("K")) {
            return parseFloat(numericString) * 1000;
          } else if (marketcap.endsWith("M")) {
            return parseFloat(numericString) * 1000000;
          } else {
            return parseFloat(numericString);
          }
        };
        const parsePrice = (price) => {
          return parseFloat(price[1]);
        };
        const parseScore = (score) => {
          return parseFloat(score[0]);
        };
        const parseContractCreated = (dateString) => {
          const timestamp = new Date(dateString).getTime();
          return timestamp;
        };

        if (sortConfig.key === "oneDayChange") {
          valueA = parseOneDayChange(valueA);
          valueB = parseOneDayChange(valueB);
        } else if (sortConfig.key === "volume1h" || sortConfig.key === "volume24h") {
          valueA = parseVolumeChange(valueA);
          valueB = parseVolumeChange(valueB);
        } else if (sortConfig.key === "price") {
          valueA = parsePrice(valueA);
          valueB = parsePrice(valueB);
        } else if (sortConfig.key === "score") {
          valueA = parseScore(valueA);
          valueB = parseScore(valueB);
        } else if (
          sortConfig.key === "Marketcap" ||
          sortConfig.key === "liquidity" ||
          sortConfig.key === "mcap"
        ) {
          valueA = parseMarketcap(valueA);
          valueB = parseMarketcap(valueB);
        } else if (sortConfig.key === "contract_created") {
          valueA = parseContractCreated(valueA);
          valueB = parseContractCreated(valueB);
        }

        if (valueA < valueB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return rows;
  }, [rows, sortConfig]);
  const CustomSortIcon = () => (
    <MDBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "1em" }}
    >
      <KeyboardArrowUpOutlinedIcon
        fontSize="small"
        sx={{ height: "0.5em", color: darkMode ? "rgb(123, 128, 154)" : "secondary" }}
      />
      <KeyboardArrowDownOutlinedIcon
        fontSize="small"
        sx={{ height: "0.5em", color: darkMode ? "rgb(123, 128, 154)" : "secondary" }}
      />
    </MDBox>
  );

  return (
    <MDBox>
      <TableContainer sx={{ boxShadow: "none" }}>
        <Table>
          <MDBox component="thead">
            <TableRow>
              {columns.map((column, index) => (
                <DataTableHeadCell
                  key={column.accessor}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sortDirection={sortConfig.key === column.accessor ? sortConfig.direction : false}
                  sortable={column.sortable}
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    userSelect: "none",
                  }}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={sortConfig.key === column.accessor}
                      direction={sortConfig.key === column.accessor ? sortConfig.direction : "asc"}
                      onClick={() => {
                        handleSort(column.accessor, index);
                      }}
                      IconComponent={iconVisibility[index] ? CustomSortIcon : undefined}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: darkMode ? "#ffffff" : "secondary",
                        },
                        "&.Mui-active": {
                          color: darkMode ? "#ffffff" : "secondary",
                        },
                        "& .MuiTableSortLabel-icon": {
                          color: darkMode ? "#ffffff !important" : "secondary.main !important",
                          opacity: iconVisibility[index] ? 1 : 0,
                        },
                      }}
                    >
                      {column.Header}
                    </TableSortLabel>
                  ) : (
                    column.Header
                  )}
                </DataTableHeadCell>
              ))}
            </TableRow>
          </MDBox>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <DataTableBodyCell key={column.accessor}>
                      {row[column.accessor]}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-select,.MuiTablePagination-actions":
            {
              color: darkMode ? "rgb(123, 128, 154)" : "secondary",
            },
          ".MuiTablePagination-actions .Mui-disabled": {
            color: darkMode ? "rgb(64 65 71)" : "secondary",
          },
        }}
      />
    </MDBox>
  );
}

DataTable.propTypes = {
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        accessor: PropTypes.string.isRequired,
        Header: PropTypes.string.isRequired,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        align: PropTypes.oneOf(["left", "right", "center"]),
        sortable: PropTypes.bool, // Add sortable prop type
      })
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  sortable: PropTypes.bool,
  rowsPerPageActiveTrade: PropTypes.number,
};

export default DataTable;
