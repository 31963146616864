/* eslint-disable react/prop-types */

import React from "react";

function RatingsBar({ positiveRatings, negativeRatings }) {
  const total = positiveRatings + negativeRatings;
  const positiveWidth = ((positiveRatings * 100) / total).toFixed(2) || 50;
  const negativeWidth = 100 - positiveWidth;
  return (
    <div id="ratingsBar">
      <span id="positiveProgress" style={{ width: `${positiveWidth}%` }} />
      <span id="negativeProgress" style={{ width: `${negativeWidth}%` }} />
    </div>
  );
}

export default RatingsBar;
