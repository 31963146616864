/* eslint-disable react/prop-types */
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import LaunchIcon from "@mui/icons-material/Launch";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import EGSwapLogo from "assets/images/EGSwap.jpeg";

import React, { useState } from "react";

const EggSwapPopup = ({ contractAddress, darkMode = true, chain }) => {
  const [open, setOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsFullScreen(false);
  };

  const buyUrl =
    chain === "bnb"
      ? `https://egswap.exchange/embed-swap?outputCurrency=${contractAddress}&inputCurrency=BNB`
      : chain === "ethereum"
      ? `https://egswap.exchange/embed-swap?chain=eth&outputCurrency=${contractAddress}`
      : "";

  if (!buyUrl) {
    return;
  }

  return (
    <>
      <MDButton
        variant="outlined"
        target="_blank"
        size="small"
        fullWidth
        sx={{
          border: darkMode
            ? `1px solid linear-gradient(90deg, rgb(100, 172, 255) -10%, rgb(117, 225, 180) 33%, rgb(104, 217, 221) 66%, rgb(100, 172, 255) 110%)`
            : "1px solid rgb(252, 114, 255)",
          transition: "all 250ms ease 0s",
          "&:hover": {
            background:
              "linear-gradient(90deg, rgb(100, 172, 255) -10%, rgb(117, 225, 180) 33%, rgb(104, 217, 221) 66%, rgb(100, 172, 255) 110%)",
          },
          "&:focus:not(:hover)": {
            outline: "0px !important",
            border: "1px solid rgb(252, 114, 255)",
            boxShadow: "none",
          },
        }}
        onClick={handleOpen}
      >
        <MDAvatar
          src={EGSwapLogo}
          alt="EGSwap logo"
          shadow="xs"
          sx={{
            padding: 0,
            height: "auto",
            width: 24,
          }}
        />
        &nbsp;&nbsp;
        <MDTypography variant="button" fontSize="14px" fontWeight="regular">
          Buy in EGSwap
        </MDTypography>
      </MDButton>
      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        aria-labelledby="draggable-dialog-title"
        open={open}
        PaperComponent={isFullScreen ? null : PaperComponent}
        TransitionComponent={Transition}
        fullScreen={isFullScreen}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 0,
            paddingLeft: "12px",
            paddingTop: "4px",
            paddingBottom: "4px",
            cursor: "move",
            background: darkMode ? "#1A1F2B" : "linear-gradient(195deg, #42424a, #191919)",
            display: "flex",
            alignItems: "center",
          }}
          id="draggable-dialog-title"
        >
          <LaunchIcon
            sx={{ cursor: "pointer" }}
            onClick={() => window.open(buyUrl, "_blank")}
            fontSize="small"
          />{" "}
          {isFullScreen ? (
            <>
              <CloseFullscreenIcon
                fontSize="small"
                sx={{
                  cursor: "pointer",
                  marginLeft: "8px",
                }}
                onClick={() => setIsFullScreen(false)}
                htmlColor={darkMode ? "inherit" : "#FFFFFFCC"}
              />
            </>
          ) : (
            <FullscreenIcon
              fontSize="medium"
              sx={{ cursor: "pointer", marginLeft: "8px" }}
              onClick={() => setIsFullScreen(true)}
              htmlColor={darkMode ? "inherit" : "#FFFFFFCC"}
            />
          )}
          &nbsp;
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "4px",
            top: 0,
          }}
        >
          <MDTypography>
            <CloseIcon htmlColor={darkMode ? "inherit" : "#FFFFFFCC"} />
          </MDTypography>
        </IconButton>
        <DialogContent>
          <iframe
            title="EgSwap"
            src={buyUrl}
            width="100%"
            height={isFullScreen ? "100%" : "500px"}
            frameBorder="0"
            allowFullScreen
          />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
  "& .MuiPaper-root": {
    width: "100%",
  },
}));

export default EggSwapPopup;
