/* eslint-disable react/prop-types */

import { useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { shuffle } from "lodash";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { useMaterialUIController } from "context";
import useApi from "utils/useApi";

const TextAd = ({ placement }) => {
  const [controller] = useMaterialUIController();
  const location = useLocation();
  const [showAd, setShowAd] = useState(true);

  const { getAdvertisements, createAdImpression } = useApi();
  const { darkMode, transparentNavbar } = controller;

  const { data: advertisementDataRes = {} } = useQuery(
    `getAdvertisements`,
    () => getAdvertisements(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const advertisementData = advertisementDataRes?.data ?? [];
  const textAdDataList = advertisementData.filter((each) => each.placement === placement);

  const textAdData = useMemo(() => {
    if (textAdDataList.length > 0) {
      const shuffled = shuffle(textAdDataList);
      return shuffled?.[0];
    }
    return null;
  }, [textAdDataList.length]);

  if (!(showAd && textAdData)) {
    return null;
  }

  const trackAdImpressions = async (type = "click") => {
    await createAdImpression({
      adId: textAdData.id,
      source: location ? location.pathname : "0xhub",
      eventType: type,
    });
  };

  const { header, media: logo, link_to: link, subheader: content } = textAdData || {};

  return (
    <Card
      sx={{
        "&.MuiPaper-root": {
          padding: "4px",
        },
      }}
      onClick={() => trackAdImpressions("click")}
    >
      <CardHeader
        title={
          <MDTypography
            variant="button"
            fontWeight="medium"
            fontSize="13px"
            sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
          >
            {logo && (
              <MDAvatar
                src={logo}
                alt={"Logo"}
                size="xs"
                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                  border: `${borderWidth[2]} solid ${white.main}`,
                  cursor: "pointer",
                  position: "relative",
                  mr: "8px",

                  "&:hover, &:focus": {
                    zIndex: "10",
                  },
                })}
              />
            )}
            {header || "Advertisement"}
          </MDTypography>
        }
        action={
          <Icon
            fontSize="small"
            sx={iconsStyle}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAd(false);
              trackAdImpressions("close");
            }}
            style={{ cursor: "pointer", paddingTop: "2px" }}
          >
            cancel
          </Icon>
        }
        sx={{
          padding: "8px 12px 8px 4px",
          paddingBottom: "2px",
          marginBottom: "6px",
        }}
        component={Link}
        to={link}
        target="_blank"
      />
      <MDBox
        sx={{ textAlign: "center", lineHeight: "1" }}
        borderRadius="sm"
        p="4px"
        pb={"8px"}
        pt={"0px"}
        display="flex"
        justifyContent="flex-start"
      >
        <MDTypography
          variant="p"
          color="text"
          fontWeight="regular"
          fontSize="13px"
          style={{ textAlign: "left" }}
        >
          {content}
        </MDTypography>
      </MDBox>
    </Card>
  );
};

TextAd.defaultProps = {
  // darkMode: false,
};

// Typechecking props for the TextAd
TextAd.propTypes = {
  // projectData: PropTypes.bool,
};
export default TextAd;
