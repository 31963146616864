/* eslint-disable react/prop-types */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import memeDefiBgImage from "assets/images/memedefibg.jpeg";
import memeDefiLogo from "assets/images/0hHubTransparent.png";

import useApi from "utils/useApi";
import useLogin from "utils/useLogin";
import { truncate } from "lodash";

function Cover() {
  const { signupUserByUsernamePassword } = useApi();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    signup: "",
  });

  useLogin("", true);

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const isEmailValid = emailRegex.test(email);

    if (!isEmailValid) {
      setError({
        ...error,
        email: "Email is not valid",
      });
      return;
    }
    const payload = {
      name,
      email,
      password,
    };
    try {
      await signupUserByUsernamePassword(payload);
      navigate("/authentication/sign-in?signup_success=true");
    } catch (e) {
      setError({
        ...error,
        signup: e?.message ?? "Signup Error",
      });
    }
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    let errorMessage = "";

    if (field === "email") {
      setEmail(value);
    } else if (field === "password") {
      setPassword(value);
      // Validate password for passwordOne field
      const isValidPassword = validatePassword(value);
      errorMessage = !isValidPassword ? "Password must be 8 digits." : "";
    } else {
      setName(value);
    }

    if (!value) {
      setError({
        ...error,
        [field]: "Field is required",
        signup: "",
      });
    } else {
      setError({
        ...error,
        [field]: errorMessage,
        signup: "",
      });
    }
  };

  return (
    <BasicLayout image={memeDefiBgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            Join us today
            <MDAvatar
              src={memeDefiLogo}
              size="sm"
              sx={{ marginLeft: "4px", border: "1px solid #0A0A0A", background: "#f5f5f5" }}
            />
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                variant="standard"
                fullWidth
                error={!!error?.name}
                helperText={
                  <MDTypography
                    sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                    variant="text"
                    color="error"
                  >
                    {error?.name}
                  </MDTypography>
                }
                onChange={(e) => handleInputChange(e, "name")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                error={!!error?.email}
                helperText={
                  <MDTypography
                    sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                    variant="text"
                    color="error"
                  >
                    {error?.email}
                  </MDTypography>
                }
                onChange={(e) => handleInputChange(e, "email")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                error={!!error?.password}
                helperText={
                  <MDTypography
                    sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                    variant="text"
                    color="error"
                  >
                    {error?.password}
                  </MDTypography>
                }
                onChange={(e) => handleInputChange(e, "password")}
              />
            </MDBox>
            <MDBox
              mt={4}
              mb={1}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDTypography
                sx={{
                  position: "absolute",
                  top: "40px",
                }}
                variant="text"
                color="error"
                fontSize="14px"
              >
                {truncate(error?.signup, {
                  length: 50,
                })}
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleEmailSignup}
                disabled={!(name && email && password && validatePassword(password))}
              >
                sign Up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
