import { atom } from "recoil";

export const newCommentState = atom({
  key: "newCommentState",
  default: {
    bearishCount: "",
    bullishCount: "",
    comment: "",
    createdOn: "",
    threads: [],
  },
});
