import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Delete as DeleteIcon, AddCircleOutline as AddIcon } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconStyle from "./iconStyle";
import useTokenData from "./data";
import { Button } from "@mui/material";

import { useMaterialUIController } from "context";

const walletsData = [
  {
    address: "0xC980c75e876e77110E4207acc24b20c09EA69968",
    ethBalance: "1.424615790998301828",
    tokens: [
      {
        id: "0x8162b5bc8f651007cc38a09f557bab2bf4cefb5b",
        contract: "0x8162b5bc8f651007cc38a09f557bab2bf4cefb5b",
        logo: "",
        name: "StreamerInu",
        symbol: "STRM/USDC",
        priceChangeOneDay: "+24%",
        marketcap: 2222222,
        price: 0.001557,
        balance: 4736109,
        dollarValue: 3000,
      },
      {
        id: "asdasdas",
        contract: "asdasdasd",
        logo: "",
        name: "CatInu",
        symbol: "CAT/USDC",
        priceChangeOneDay: "+44%",
        marketcap: 222221222,
        price: 0.00122557,
        balance: 47321216109,
        dollarValue: 3222000,
      },
    ],
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Protein (g)",
  },
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {numSelected > 0 ? (
        <MDTypography variant="h6" color="text" fontSize={"14px"}>
          Remove {numSelected} tokens
        </MDTypography>
      ) : (
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          Tokens (8)
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <IconStyle icon={DeleteIcon} fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Track more token">
          <IconButton>
            <IconStyle icon={AddIcon} fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const tokens = walletsData.map((each) => each.tokens)[0];

  const rows = tokens;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box
      sx={{
        width: "100%",
        background: "inherit",
        margin: "8px 0px",
      }}
    >
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer
        sx={{
          boxShadow: "none",
        }}
      >
        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" id={labelId} scope="row" padding="none">
                    <MDTypography fontSize="14px" variant="button" fontWeight="normal">
                      {row.name}
                    </MDTypography>
                  </TableCell>
                  <TableCell align="right">
                    <MDBox display="flex" flexDirection="column" justifyContent="center">
                      <MDTypography fontSize="14px" variant="button" fontWeight="bold">
                        ${row.dollarValue}
                      </MDTypography>
                      <MDTypography fontSize="13px" variant="button" fontWeight="normal">
                        {row.balance} {row.symbol?.split("/")?.[0] ?? ""}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component={(props) => (
          <Typography
            {...props}
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              "& .MuiTablePagination-toolbar": {
                color: `${darkMode ? white.main : dark.main} !important`,
              },
              "& .MuiTablePagination-toolbar button": {
                color: `${darkMode ? white.main : dark.main} !important`,
              },
            })}
          />
        )}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
