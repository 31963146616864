/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/*  */

import { useParams } from "react-router-dom";
import { getDecimalValue, numPrettyFormatter } from "utils/price";
import { getAddressLinkByChain, getTokenTransactionLink } from "../utils";
import AddressCopyWithTooltip from "../addressCopyWithTooltip";

export default function holderData(data = []) {
  const params = useParams();
  const chain = params.chain;

  return {
    columns: [
      { label: "Address", id: "address", align: "center" },
      { label: "Supply %", id: "supply", align: "center" },
      { label: "Supply Amount", id: "amount", align: "center" },
      { label: "Value (USD)", id: "value", align: "center" },
    ],
    rows: data.map((each) => {
      return {
        address: (
          <AddressCopyWithTooltip
            fullUrl={getAddressLinkByChain(chain, each.address, "address")}
            address={each.address}
            fontSize={"13px"}
            addressFormat={8}
          />
        ),
        supply: `${getDecimalValue((each.balance * 100) / each.total_supply)}%`,
        amount: numPrettyFormatter(each.balance),
        value: (
          <span
            style={{
              textWrap: "nowrap",
              flexWrap: "nowrap",
              whiteSpace: "nowrap",
            }}
          >
            $ {getDecimalValue(each.value || 0)}
          </span>
        ),
      };
    }),
  };
}
