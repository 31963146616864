import React from "react";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";

import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "App";
import ReactGA from "react-ga4";
import HttpsRedirect from "react-https-redirect";

import { MaterialUIControllerProvider } from "context";
import "react-toastify/dist/ReactToastify.css";
import "./index-styles.css";

const container = document.getElementById("app");
const root = createRoot(container);

const queryClient = new QueryClient();
ReactGA.initialize("G-8HE8V8WS6M");

root.render(
  <HttpsRedirect>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <GoogleOAuthProvider clientId="530769203598-5pq0fsc7ss2he83udm849kdc0hm3bbaf.apps.googleusercontent.com">
          <QueryClientProvider client={queryClient}>
            <RecoilRoot>
              <App />
            </RecoilRoot>
          </QueryClientProvider>
        </GoogleOAuthProvider>
      </MaterialUIControllerProvider>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        hideProgressBar
        newestOnTop
        progressClassName="toastProgress"
        bodyClassName="toastBody"
      />
    </BrowserRouter>
  </HttpsRedirect>
);
