/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/*  */

import { format, parseISO } from "date-fns";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Link } from "react-router-dom";
import { truncate } from "lodash";

import { useMaterialUIController } from "context";

import AddressCopy from "layouts/project/addressCopyWithTooltip";
import { numPrettyFormatter, formatPriceWithDecimals } from "utils/price";
import { getAddressLinkByChain } from "layouts/project/utils";
import { getChainForUrl } from "utils/chain";

export default function useDashboardData(projects = []) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const Company = ({ image, name, pair, pairSymbol, chain }) => (
    <Link to={pair ? `/${getChainForUrl(chain)}/${pair}` : null}>
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar
          src={image}
          name={name}
          size="sm"
          sx={{
            marginRight: "8px",
            color: darkMode ? "#ffffffCC" : "#0A0A0A",
            fontWeight: "700",
            border: darkMode ? "0.5px solid #f0f2f5" : "none",
            boxShadow: 1,
            background: darkMode ? "transparent" : "#f0f2f5",
          }}
        >
          A
        </MDAvatar>
        <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          <span>{name}</span>
          <br />
          {pairSymbol && (
            <MDTypography variant="button" color="text" fontWeight="regular">
              <small>{pairSymbol}</small>
            </MDTypography>
          )}
        </MDTypography>
      </MDBox>
    </Link>
  );

  return {
    columns: [
      { Header: "projects", accessor: "projects", align: "left", sortable: true },
      { Header: "Contract", accessor: "contract_address", align: "left", sortable: false },
      { Header: "Volume (24h)", accessor: "oneDayChange", align: "left", sortable: true },
      { Header: "Marketcap", accessor: "marketcap", align: "left", sortable: true },
      { Header: "Price", accessor: "price", align: "left", sortable: true },
      { Header: "Pool Created", accessor: "contract_created", align: "left", sortable: true },
      { Header: "Liquidity", accessor: "liquidity", align: "left", sortable: true },
      { Header: "Score", accessor: "score", align: "left", sortable: true },
    ],
    rows: projects.map((project) => {
      const score = Number(project?.score ?? "50").toFixed(0);
      return {
        projects: (
          <Company
            image={project.logo}
            name={`${truncate(project.name, { length: 16 })}`}
            pair={project.pool_address}
            pairSymbol={project?.pairSymbol}
            chain={project?.chain}
          />
        ),
        contract_address: (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{
              ...contractStyle,
            }}
          >
            <AddressCopy
              address={project.contract_address}
              fullUrl={getAddressLinkByChain(project?.chain, project?.contract_address, "token")}
              fontSize={"12px"}
            />
          </MDTypography>
        ),
        marketcap: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {numPrettyFormatter(project.marketcap)}
          </MDTypography>
        ),
        liquidity: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {numPrettyFormatter((project.liquidity || 0) * 2)}
          </MDTypography>
        ),
        price: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            $ {formatPriceWithDecimals(project.price)}
          </MDTypography>
        ),
        contract_created: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {format(new Date(Number(project.contract_created) * 1000), "yyyy-MM-dd HH:mm:ss")}
          </MDTypography>
        ),
        oneDayChange: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              color={project.oneDayChange?.[0] === "+" ? "success" : "error"}
            >
              {project.oneDayChange}
            </MDTypography>
          </MDTypography>
        ),
        score: (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{ color: score < 50 ? "#F44335" : "#36BB91" }}
          >
            {score}
            <span style={{ color: "cadetblue" }}>/100</span>
          </MDTypography>
        ),
      };
    }),
  };
}

const contractStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
