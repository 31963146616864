import { useState, useEffect } from "react";
import React from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { projectAtom } from "atoms";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";

// 0xHub Dashboard React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import FavoriteMobileSidebar from "examples/Navbars/DashboardNavbar/favoriteSidebar";

// 0xHub Dashboard React themes
import theme from "assets/theme";

// 0xHub Dashboard React Dark Mode themes
import themeDark from "assets/theme-dark";

// 0xHub Dashboard React routes
import routes from "routes";

// 0xHub Dashboard React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandDark from "assets/images/0hHubTransparent.png";
import brandWhite from "assets/images/0hHubTransparent.png";

import BannerAd from "layouts/advertisement/bannerAd";
import useApi from "utils/useApi";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const [projectData, setProjectData] = useRecoilState(projectAtom);
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    selectedChain,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const {
    getAdvertisements,
    resetCache,
    fetchAllProjects,
    fetchMemeDefiTopTrending,
    fetchMemeDefiTopLosers,
    fetchMemeDefiTopGainers,
    fetchRecentlyAddedProjects,
  } = useApi();
  const pageNumber = projectData?.pageNumber ?? 1;

  useQuery(`getAdvertisements`, () => getAdvertisements(), {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: projectsDataObj = {},
    isFetching,
    isLoading,
    refetch: refetchProjects,
  } = useQuery(
    `fetchAllProjects-${pageNumber}-${selectedChain}`,
    () => fetchAllProjects(20, pageNumber),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  const projectsData = projectsDataObj?.data ?? [];

  const { data: trendingData = [], refetch: refetchTrendingProjects } = useQuery(
    `fetchMemeDefiTopTrending-${selectedChain}`,
    () => fetchMemeDefiTopTrending(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  const { data: topLosersData = [], refetch: refetchLosersProjects } = useQuery(
    `fetchMemeDefiTopLosers-${selectedChain}`,
    () => fetchMemeDefiTopLosers(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );
  const { data: topGainersData = [], refetch: refetchGainersProjects } = useQuery(
    `fetchMemeDefiTopGainers-${selectedChain}`,
    () => fetchMemeDefiTopGainers(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  const { data: recentlyAddedData = [], refetch: refetchRecentlyAddedProjects } = useQuery(
    `fetchRecentlyAddedProjects-${selectedChain}`,
    () => fetchRecentlyAddedProjects(20, 1),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const resetCacheAndRefetch = async () => {
    if (projectData?.allProjects?.length > 0) {
      setProjectData({
        allProjects: [],
        totalProjects: 0,
        gainersProjects: [],
        losersProjects: [],
        trendingProjects: [],
        recentlyAddedProjects: [],
        pageNumber: 1,
      });
    }
    await resetCache();
    refetchProjects();
    refetchTrendingProjects();
    refetchLosersProjects();
    refetchGainersProjects();
    refetchRecentlyAddedProjects();
  };

  useEffect(() => {
    resetCacheAndRefetch();
  }, [selectedChain]);

  useEffect(() => {
    if (projectData?.allProjects?.length === 0 && projectsData?.length > 0) {
      setProjectData((prevProjectData) => ({
        ...prevProjectData,
        allProjects: projectsDataObj?.data ?? [],
        totalProjects: projectsDataObj?.totalCount ?? 0,
      }));
    }
  }, [projectData, projectsData]);

  useEffect(() => {
    if (projectData?.trendingProjects?.length === 0 && trendingData.length > 0) {
      setProjectData((prevProjectData) => ({
        ...prevProjectData,
        trendingProjects: trendingData,
      }));
    }
  }, [projectData?.trendingProjects, trendingData]);

  useEffect(() => {
    if (projectData?.gainersProjects?.length === 0 && topGainersData.length > 0) {
      setProjectData((prevProjectData) => ({
        ...prevProjectData,
        gainersProjects: topGainersData,
      }));
    }
  }, [projectData?.gainersProjects, topGainersData]);

  useEffect(() => {
    if (projectData?.losersProjects?.length === 0 && topLosersData.length > 0) {
      setProjectData((prevProjectData) => ({
        ...prevProjectData,
        losersProjects: topLosersData,
      }));
    }
  }, [projectData?.losersProjects, topLosersData]);

  useEffect(() => {
    if (projectData?.recentlyAddedProjects?.length === 0 && recentlyAddedData.length > 0) {
      setProjectData((prevProjectData) => ({
        ...prevProjectData,
        recentlyAddedProjects: recentlyAddedData,
      }));
    }
  }, [projectData?.recentlyAddedProjects, recentlyAddedData]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.disabled) {
        return null;
      }
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="none"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <BannerAd />
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="0xHub"
            routes={routes}
            // onMouseEnter={handleOnMouseEnter}
            // onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          <FavoriteMobileSidebar />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
