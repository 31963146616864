/* eslint-disable react/prop-types */

import * as React from "react";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";

import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import IconButton from "@mui/material/IconButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

import WalletSelect from "./selectWallet";
import DataTable from "./filterableTable";

export default function PortfolioTracker({}) {
  const [controller] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <MDBox>
      <Card sx={{ borderRadius: 0, padding: "16px", marginTop: "8px", boxShadow: "none" }}>
        <WalletSelect />
      </Card>
      <Card sx={{ borderRadius: 0, padding: "16px", marginTop: "8px", boxShadow: "none" }}>
        <DataTable />
      </Card>
    </MDBox>
  );
}
