/* eslint-disable react/prop-types */

import React from "react";
import ReactGA from "react-ga4";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import VoteStatIcon from "@mui/icons-material/WhereToVote";
import PeopleIcon from "@mui/icons-material/People";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

import useApi from "utils/useApi";
import useTrackEvent from "utils/useTrackEvent";
import useLogin from "utils/useLogin";

import ScoreIndicator from "./scoreIndicator";
import ThumbsUp from "./thumbsUp";
import ThumbsDown from "./thumbsDown";
import RatingsBar from "./ratingsBar";

import { numPrettyFormatter } from "utils/price";
import "./styles.css";

const defaultVote = 50;

const Voting = ({ projectId, memeDefiScore = defaultVote }) => {
  const { getEventsForProject, getTodayEventVoteForProjectByUser } = useApi();
  const { trackEvent } = useTrackEvent();
  const { isLoggedIn, isTokenValid, userInfo } = useLogin("");

  const { data: allEventsData = [], refetch: refetchAllEventsData } = useQuery(
    `getEventsForProject-${projectId}`,
    () => getEventsForProject(projectId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      // staleTime: Infinity
    }
  );
  const { data: todayVotingStatusData = {}, refetch: refetchTodayVotingStatusData } = useQuery(
    `getTodayEventVoteForProjectByUser-${projectId}`,
    () => getTodayEventVoteForProjectByUser(projectId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isTokenValid,
    }
  );

  const createVoting = async (type) => {
    try {
      if (!isTokenValid) {
        return;
      }
      // Track voting event
      ReactGA.event({
        category: "Vote",
        action: "createVoting",
        label: type,
      });

      await trackEvent({
        projectId: projectId,
        eventName: type,
        userId: userInfo?.id,
      });
      toast.success(
        `We have received your vote. Next voting for the project will open tomorrow for you!`
      );
      refetchAllEventsData();
      refetchTodayVotingStatusData();
    } catch (e) {
      toast.error(`Voting failed! Please try again.`);
    }
  };

  const isVotingAllowed =
    (todayVotingStatusData?.data?.allowVote && isTokenValid && isLoggedIn) || false;

  const votingMessage = isTokenValid
    ? todayVotingStatusData?.data?.statusMessage || "Voting opens once a day for this project."
    : "Log in to vote.";
  const eventsData = allEventsData?.data ?? {};

  const positiveRatings = (eventsData.positiveVoteCount || 0) + defaultVote;
  const negativeRatings = (eventsData.negativeVoteCount || 0) + defaultVote;
  const totalUsersWhoVoted = eventsData.votedUserCount || 0;

  return (
    <Card
      sx={{
        "&.MuiPaper-root": {
          padding: "12px",
        },
      }}
    >
      <ScoreIndicator value={memeDefiScore} maxValue={100} />
      <CardHeader
        title={
          <MDBox>
            <MDTypography
              variant="button"
              fontWeight="medium"
              sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <VoteStatIcon fontSize="medium" />
              &nbsp;Community Votes (
              {numPrettyFormatter(positiveRatings + negativeRatings - 100, 0, 1000, 2)})
            </MDTypography>
            <MDTypography
              variant="button"
              fontWeight="medium"
              sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <PeopleIcon fontSize="small" sx={{ marginTop: "-2px", marginRight: "4px" }} />
              {totalUsersWhoVoted} Users&nbsp;
              <Tooltip
                title={<span>Vote contributed by {totalUsersWhoVoted} users.</span>}
                placement="top"
              >
                <InfoIcon sx={{ opacity: 0.6 }} />
              </Tooltip>
            </MDTypography>
          </MDBox>
        }
        sx={{
          padding: "0px",
          paddingBottom: "16px",
        }}
      />
      <MDBox
        sx={{ textAlign: "center", lineHeight: "1" }}
        borderRadius="sm"
        p="4px"
        pb={"8px"}
        pt={"0px"}
      >
        <section id="ratingsContainer">
          <ThumbsUp
            positiveRatings={positiveRatings}
            setPositiveRating={() => createVoting("vote_positive")}
            total={negativeRatings + positiveRatings}
            isVotingAllowed={isVotingAllowed}
            votingMessage={votingMessage}
          />
          <ThumbsDown
            negativeRatings={negativeRatings}
            setNegativeRating={() => createVoting("vote_negative")}
            total={negativeRatings + positiveRatings}
            isVotingAllowed={isVotingAllowed}
            votingMessage={votingMessage}
          />
          <RatingsBar positiveRatings={positiveRatings} negativeRatings={negativeRatings} />
        </section>
      </MDBox>
      {!isTokenValid && (
        <MDTypography
          variant="button"
          color="text"
          fontWeight="medium"
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <InfoIcon sx={{ opacity: 0.6, marginRight: "4px" }} /> Log in to vote
        </MDTypography>
      )}
    </Card>
  );
};

export default Voting;
