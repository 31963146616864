import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useLogin = (token = "", isLoggingIn = false) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const navigate = useNavigate();

  const verifyToken = (storedToken, navigateToRoute = false) => {
    try {
      const decodedToken = jwtDecode(storedToken);
      const user = decodedToken.user;

      const ifTokenValid = decodedToken.exp >= Date.now() / 1000;
      setUserInfo(user);
      setIsTokenValid(ifTokenValid);

      const isTokenExpired = userInfo && decodedToken.exp < Date.now() / 1000;

      if (isTokenExpired) {
        setIsLoggedIn(false);
        if (navigateToRoute) {
          navigate("/signin");
        }
      } else {
        setIsLoggedIn(true);
        localStorage.setItem("0xhub-token", token);
        if (navigateToRoute) {
          if (!user.verified) {
            navigate("/authentication/verify");
            return;
          }
          navigate("/profile");
        }
      }
    } catch (error) {
      setIsTokenValid(false);
      setIsLoggedIn(false);
      if (navigateToRoute) {
        navigate("/signin");
      }
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("0xhub-token");
    if (!token && storedToken) {
      token = storedToken;
    }
    if (token && isLoggingIn) {
      verifyToken(token, true);
    } else if (token && !isLoggingIn) {
      verifyToken(storedToken, false);
    }
  }, [token]);

  const logout = () => {
    localStorage.removeItem("0xhub-token");
    setIsLoggedIn(false);
    setUserInfo(null);
    setIsTokenValid(false);
    navigate("/signin");
  };

  return {
    isLoggedIn: isLoggedIn,
    userInfo: userInfo,
    isTokenValid: isTokenValid,
    logout: logout,
  };
};

export default useLogin;
