/* eslint-disable react/prop-types */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import memeDefiBgImage from "assets/images/memedefibg.jpeg";
import memeDefiLogo from "assets/images/0hHubTransparent.png";

import useApi from "utils/useApi";
import useLogin from "utils/useLogin";
import { truncate } from "lodash";
import { toast } from "react-toastify";

function VerifyAccount() {
  const { verifyEmailAccount, resendVerificationCode } = useApi();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [memedefiToken, setMemedefiToken] = useState("");
  const [resendCode, setResendCode] = useState(false);
  const [error, setError] = useState({
    code: "",
    api: "",
  });

  const { isLoggedIn, userInfo: tokenUserInfo = {}, logout } = useLogin(memedefiToken, true);

  const { isLoading, isFetching, refetch } = useQuery(
    `resendVerificationCode`,
    () => resendVerificationCode(),
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    if (!code) {
      setError({
        ...error,
        email: "Code is required",
      });
      return;
    }
    const payload = {
      code,
    };
    try {
      const token = await verifyEmailAccount(payload);
      setMemedefiToken(token);
      // navigate("/profile");
    } catch (e) {
      setError({
        ...error,
        code: e?.message ?? "Account verification error",
      });
    }
  };

  const handleVerificationCodeResend = async () => {
    try {
      const x = await refetch();
      // setMemedefiToken(token);
      if (x.isError) {
        toast.error("Failed to send verification code! Try again.");
        setError({
          error,
          api: "Failed to send verification code! Try again.",
        });
        return;
      }
      toast.success("Verification code sent to your email.");
      setError({
        api: "",
        code: "",
      });
    } catch (e) {
      toast.error("Failed to send verification code! Try again.");
      setError({
        error,
        api: e.message ?? "Failed to send verification code! Try again.",
      });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCode(value);

    if (!value) {
      setError({
        ...error,
        code: "Field is required",
      });
    } else {
      setError({
        ...error,
        code: "",
        api: "",
      });
    }
  };

  return (
    <BasicLayout image={memeDefiBgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            Verify your email{" "}
            <MDAvatar
              src={memeDefiLogo}
              size="sm"
              sx={{ marginLeft: "4px", border: "1px solid #0A0A0A", background: "#f5f5f5" }}
            />
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Check your email and get one-time verification code.
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="One-time verification code"
                variant="standard"
                fullWidth
                error={!!error?.code}
                helperText={
                  <MDTypography
                    sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                    variant="text"
                    color="error"
                  >
                    {error?.code}
                  </MDTypography>
                }
                onChange={(e) => handleInputChange(e)}
              />
            </MDBox>
            <MDBox
              mt={4}
              mb={1}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDTypography
                sx={{
                  position: "absolute",
                  top: "40px",
                }}
                variant="text"
                color="error"
                fontSize="14px"
              >
                {truncate(error?.api, {
                  length: 50,
                })}
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleVerifyEmail}
                disabled={!code}
              >
                Verify Email
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Lost verification code?{" "}
                <MDTypography
                  component={Link}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  onClick={() => handleVerificationCodeResend()}
                  display="flex"
                  alignItems="center"
                >
                  Resend{" "}
                  {isLoading ||
                    (isFetching && (
                      <CircularProgress size={18} color="info" sx={{ marginBottom: "-4px" }} />
                    ))}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default VerifyAccount;
