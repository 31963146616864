export const getBlockchainFromParams = (params = {}) => {
  return params?.chain ?? "";
};

export const getPairAddressFromParams = (params = {}) => {
  return params?.token ?? "";
};

export const shortenHex = (hexString, length = 4) => {
  if (hexString.length <= length * 2) {
    return hexString; // No need to shorten if the string is already short enough
  }

  const prefix = hexString.slice(0, length);
  const suffix = hexString.slice(-length);

  return `${prefix}...${suffix}`;
};
