/* eslint-disable react/prop-types */

import React from "react";
import { Link } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import XLogoWhite from "assets/images/twitterLogoWhite.png";
import XLogoDark from "assets/images/twitterLogoBlack.png";
import MDAvatar from "components/MDAvatar";

import { useMaterialUIController } from "context";

const ShareInTwitter = ({ title, tweetLinkWithContent, social = "twitter" }) => {
  const [controller] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }, custom = {}) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    ...custom,
  });

  return (
    <MDBox
      display="flex"
      sx={{
        border: "0.2px solid text",
        marginTop: "0px",
        borderRadius: "16px",
        cursor: "pointer",
        marginTop: "0px",
      }}
      component={Link}
      to={tweetLinkWithContent}
      target="_blank"
    >
      <MDTypography color="text" variant="button" fontWeight="regular" mr="4px">
        {title}
      </MDTypography>
      <MDAvatar
        src={XLogoDark}
        alt="Etherscan"
        shadow="xs"
        sx={(theme) =>
          iconsStyle(theme, {
            height: 18,
            width: 18,
            padding: "2px",
            display: "inline-block",
            background: "#ffffffcc",
            border: "0.5px solid #ffffffcc",
          })
        }
      />
    </MDBox>
  );
};

export default ShareInTwitter;
