import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useQuery } from "react-query";
import Divider from "@mui/material/Divider";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import LogoutIcon from "@mui/icons-material/Logout";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

// 0xHub Dashboard React example components
import NotificationItem from "examples/Items/NotificationItem";

import ethereumImage from "assets/images/ethereum.png";
import bnbImage from "assets/images/bnb.jpeg";
import baseImage from "assets/images/base.png";
import polygonImage from "assets/images/polygon.png";

import useMobileView from "utils/useMobileView";
import SearchBar from "./searchBar";

import useLogin from "utils/useLogin";
import { avatarStyle } from "utils/avatarStyle";
import { getChainName, getChainTokenSymbol, getLogoByChain } from "utils/chain";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// 0xHub Dashboard React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setSelectedChain,
  setOpenMobileFavorite,
} from "context";
import { Typography } from "@mui/material";

import useApi from "utils/useApi";
import { getDecimalValue } from "utils/price";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { getPriceOfNativeETHToken } = useApi();
  const { mobileView } = useMobileView();
  const navigate = useNavigate();
  const { isLoggedIn, userInfo, logout } = useLogin("");

  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    openMobileFavorite,
    darkMode,
    selectedChain,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const { data = {}, refetch: refetchNativePrice } = useQuery(
    `getPriceOfNativeETHToken-${selectedChain}`,
    () => getPriceOfNativeETHToken(selectedChain),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
    }
  );

  const ethPrice = (data?.price ?? "0").toLocaleString();
  const gas = getDecimalValue(data?.gas ?? "0", 0);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleMobileFavoriteOpen = () => setOpenMobileFavorite(dispatch, !openMobileFavorite);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleSetSelectedChain = (chain) => {
    setSelectedChain(dispatch, chain);
    handleCloseMenu();
    refetchNativePrice();
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 1 }}
    >
      <NotificationItem
        icon={<MDAvatar src={ethereumImage} size="xs" sx={{ width: 14 }} />}
        title="ETH"
        onClick={() => {
          handleSetSelectedChain("ethereum");
        }}
      />
      <NotificationItem
        icon={<MDAvatar src={bnbImage} name={name} size="xs" sx={{ width: 14 }} />}
        title="BNB"
        onClick={() => {
          handleSetSelectedChain("bnb");
        }}
      />
      <NotificationItem
        icon={<MDAvatar src={baseImage} name={name} size="xs" sx={{ width: 14 }} />}
        title="BASE"
        onClick={() => {
          handleSetSelectedChain("base");
        }}
      />
      <NotificationItem
        icon={<MDAvatar src={polygonImage} name={name} size="xs" sx={{ width: 14 }} />}
        title="Polygon"
        onClick={() => {
          handleSetSelectedChain("polygon");
        }}
      />
      {/* 
      <NotificationItem
        icon={<MDAvatar src={arbitrumImage} name={name} size="xs" />}
        title="Arbitrum"
      /> */}
    </Menu>
  );

  // Render profile
  const renderProfileMenu = () => (
    <Menu
      anchorEl={openProfileMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openProfileMenu)}
      onClose={() => setOpenProfileMenu(false)}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<MDAvatar src={""} size="xs" />}
        title="Profile"
        onClick={() => {
          navigate("/profile");
        }}
      />
      <NotificationItem
        icon={
          <MDAvatar src={""} size="xs">
            <LogoutIcon fontSize="small" />
          </MDAvatar>
        }
        title="Logout"
        onClick={() => {
          logout();
        }}
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar
        sx={(theme) => navbarContainer(theme)}
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <MDBox color={"inherit"} mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <IconButton
            size="small"
            disableRipple
            color={"inherit"}
            sx={navbarIconButton}
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={handleOpenMenu}
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            <>
              <MDAvatar
                src={getLogoByChain(selectedChain)}
                size="xs"
                sx={{
                  width: 16,
                }}
              />
              <Typography
                sx={{
                  color: getCustomNavbarColor(light, darkMode, transparentNavbar),
                  display: "block !important",
                  marginLeft: "2px",
                }}
                fontSize={"16px"}
              >
                {getChainName(selectedChain, mobileView)}
              </Typography>
              <Icon sx={iconsStyle}>expand_more</Icon>
            </>
          </IconButton>
          <IconButton sx={navbarIconButton} size="small" color="inherit" disableRipple>
            <Typography
              sx={{
                color: getCustomNavbarColor(light, darkMode, transparentNavbar),
                display: "block !important",
                marginLeft: "2px",
              }}
              fontSize={"13px"}
            >
              {getChainTokenSymbol(selectedChain, true)}: ${ethPrice}
            </Typography>
          </IconButton>
          {gas > 0 && (
            <IconButton sx={navbarIconButton} size="small" color="inherit" disableRipple>
              <Icon sx={iconsStyle}>local_gas_station</Icon>
              <Typography
                sx={{
                  color: getCustomNavbarColor(light, darkMode, transparentNavbar),
                  display: "block !important",
                  marginLeft: "2px",
                }}
                fontSize={"13px"}
              >
                {gas}
              </Typography>
            </IconButton>
          )}
          <Link to={"/advertisement"}>
            <Typography
              sx={{
                display: "block !important",
                marginLeft: "2px",
                color: darkMode ? "#36BB91 !important" : "#36BB91 !important",
                opacity: darkMode ? 0.8 : 1,
              }}
              fontSize={"13px"}
            >
              {mobileView ? "Ad" : "Advertise With Us"}
            </Typography>
          </Link>
          {mobileView && (
            <IconButton
              sx={navbarIconButton}
              size="small"
              color="inherit"
              disableRipple
              onClick={handleMobileFavoriteOpen}
            >
              <Icon sx={iconsStyle}>star</Icon>
            </IconButton>
          )}
          {mobileView && (
            <>
              {" "}
              {userInfo ? (
                <IconButton
                  size="small"
                  disableRipple
                  color={"inherit"}
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={(event) => setOpenProfileMenu(event.currentTarget)}
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <MDAvatar
                    src={userInfo?.profile_image}
                    size="xs"
                    sx={{
                      ...(mobileView
                        ? {
                            ...avatarStyle(userInfo.username, darkMode),
                            height: 20,
                            width: 20,
                            marginBottom: "2px",
                            position: "relative",
                            top: 4,
                          }
                        : {}),
                      border: `1px solid ${getCustomNavbarColor(
                        light,
                        darkMode,
                        transparentNavbar
                      )}`,
                    }}
                  >
                    {userInfo?.username?.[0]?.toUpperCase() ?? "A"}
                  </MDAvatar>
                </IconButton>
              ) : (
                <Tooltip title={"Coming soon."} placement="top">
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    onClick={() => navigate("/authentication/sign-in")}
                  >
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon sx={iconsStyle} fontSize="medium">
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
          {renderMenu()}
          {renderProfileMenu()}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <SearchBar />
            </MDBox>
            <MDBox
              color={light ? "white" : "inherit"}
              display="flex"
              alignItems="center"
              flexDirection={mobileView ? "column" : "row"}
            >
              {/* Show account icon in desktop view */}
              {!mobileView && (
                <>
                  {" "}
                  {userInfo ? (
                    <IconButton
                      size="small"
                      disableRipple
                      color={"inherit"}
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={(event) => setOpenProfileMenu(event.currentTarget)}
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <MDAvatar
                        src={userInfo?.profile_image}
                        size="xs"
                        sx={{
                          ...avatarStyle(userInfo.username, darkMode),
                          ...(mobileView
                            ? {
                                height: 20,
                                width: 20,
                                marginBottom: "2px",
                                position: "relative",
                                top: 4,
                              }
                            : {}),
                          border: `1px solid ${getCustomNavbarColor(
                            light,
                            darkMode,
                            transparentNavbar
                          )}`,
                        }}
                      >
                        {userInfo?.username?.[0]?.toUpperCase() ?? "A"}
                      </MDAvatar>
                    </IconButton>
                  ) : (
                    <Tooltip title={"Coming soon."} placement="top">
                      <IconButton
                        size="small"
                        disableRipple
                        color="inherit"
                        sx={navbarIconButton}
                        onClick={() => navigate("/authentication/sign-in")}
                      >
                        <Icon sx={iconsStyle}>account_circle</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

const getCustomNavbarColor = (light, darkMode, transparentNavbar) => {
  {
    let colorValue = light || darkMode ? "#ffffff" : "#1A1F2B";

    if (transparentNavbar && !light) {
      colorValue = darkMode ? "#FFFFFF99" : "#7b809a";
    }
    return colorValue;
  }
};
