/* eslint-disable react/prop-types */

import React from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import MDBox from "components/MDBox";

function Chart({
  chartData = [],
  title = "",
  displayTitle = true,
  displayLegend = false,
  legendPosition = "bottom",
}) {
  return (
    <MDBox
      sx={{
        width: "100%",
        "& canvas": {
          height: chartData?.datasets?.length > 1 ? 200 : 150,
          width: "100% !important",
        },
      }}
    >
      <Line
        data={chartData}
        // height={chartData?.datasets?.length > 1 ? 200 : 150}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          title: {
            display: displayTitle,
            text: title,
            fontSize: 25,
          },
        }}
      />
    </MDBox>
  );
}

Chart.defaultProps = {
  displayTitle: true,
  displayLegend: false,
  legendPosition: "bottom",
};

export default Chart;
