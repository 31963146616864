/* eslint-disable react/prop-types */

import "./scoreStyles.css";

const ScoreIndicator = ({ value = 50, maxValue }) => {
  const val = (value / maxValue) * 100;
  const deg = (180 / 100) * val;
  const positive = value >= 50;

  return (
    <div className="indicator">
      <span
        className={`bar ${positive ? "positive" : "negative"}`}
        style={{ transform: `rotate(${deg}deg)` }}
      />
      <span className="result">
        <span style={{ color: positive ? "#36BB91" : "#F44335" }}>{value}</span>/
        <span style={{ color: "inherit" }}>{maxValue}</span>
      </span>
    </div>
  );
};

export default ScoreIndicator;
