import ethereumImage from "assets/images/ethereum.png";
import bnbImage from "assets/images/bnb.jpeg";
import baseImage from "assets/images/base.png";
import polygonImage from "assets/images/polygon.png";

import etherScanLogo from "assets/images/etherscanLogo.png";
import bscScanLogo from "assets/images/bscScan.png";
import polygonScanLogo from "assets/images/polygonScanLight.png";

export const availableChains = ["eth", "bnb", "base", "polygon"];

export const availableRouteChains = ["ethereum", "bnb", "base", "polygon"];

export const getChainForUrl = (chain = "") => {
  if (["bnb", "bsc"].includes(chain?.toLowerCase())) {
    return "bnb";
  }
  if (["eth", "ethereum"].includes(chain?.toLowerCase())) {
    return "ethereum";
  }
  if (["base"].includes(chain?.toLowerCase())) {
    return "base";
  }
  if (["polygon"].includes(chain?.toLowerCase())) {
    return "polygon";
  }
  return "ethereum";
};

export const chainToFullName = {
  eth: "ethereum",
  bnb: "bnb",
  base: "base",
  polygon: "polygon",
};

export const getChainName = (chain, mobileView = false) => {
  switch (chain) {
    case "ethereum":
      if (mobileView) {
        return "ETH";
      }
      return "Ethereum";
    case "bnb":
    case "bsc":
      return "BNB";
    case "base":
      return "BASE";
    case "polygon":
      return "Polygon";
    default:
      if (mobileView) {
        return "ETH";
      }
      return "Ethereum";
  }
};

export const getChainTokenSymbol = (chain) => {
  switch (chain) {
    case "ethereum":
    case "eth":
      return "ETH";
    case "bnb":
    case "bsc":
      return "BNB";
    case "polygon":
      return "MATIC";
    case "base":
      return "ETH";
    default:
      return "ETH";
  }
};

export const getLogoByChain = (chain) => {
  switch (chain) {
    case "ethereum":
    case "eth":
      return ethereumImage;
    case "bnb":
    case "bsc":
      return bnbImage;
    case "base":
      return baseImage;
    case "polygon":
      return polygonImage;
    default:
      return "";
  }
};

export const getScannerLogoByChain = (chain, mode = "dark") => {
  switch (chain) {
    case "ethereum":
      if (mode === "dark") {
        return etherScanLogo;
      }
      return etherScanLogo;
    case "bnb":
    case "bsc":
      if (mode === "dark") {
        return bscScanLogo;
      }
      return bscScanLogo;
    case "base":
      if (mode === "dark") {
        return baseImage;
      }
      return baseImage;
    case "polygon":
      if (mode === "dark") {
        return polygonScanLogo;
      }
      return polygonImage;
    default:
      return etherScanLogo;
  }
};
