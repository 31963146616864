/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/*  */

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { useMaterialUIController } from "context";
import { getTextColor } from "../utils";

export default function ProjectTable({ rows = [], columns = [], total, footer = null }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(total);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", background: "inherit" }} elevation={0}>
      <TableContainer
        sx={({ breakpoints }) => ({
          [breakpoints.down("sm")]: {
            minHeight: 350,
            maxHeight: 500,
          },
          [breakpoints.up("sm")]: {
            minHeight: 320,
            maxHeight: 700,
          },
        })}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  sx={{
                    color: darkMode ? "#ffffff" : "#1A1F2B",
                    fontSize: "14px",
                    padding: "16px 8px",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              const cellColor = getTextColor(darkMode, row?.type ?? "");
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.trx + i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={"center"}
                        sx={{
                          color: cellColor,
                          fontSize: 13,
                          fontWeight: 500,
                        }}
                      >
                        {column.format && typeof value === "number" ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {footer}
      </TableContainer>
    </Paper>
  );
}
