/* eslint-disable react/prop-types */

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { has } from "lodash";

// @mui material components
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Billing page components
import GppGoodIcon from "@mui/icons-material/GppGood";
import FlagIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import AddressCopy from "layouts/project/addressCopyWithTooltip";
import SkeletonWrapper from "./skeletonWrapper";

// Sidenav root
import ConfiguratorRoot from "./rightDrawer";
import { numPrettyFormatter, getDecimalValue } from "utils/price";
import { useMaterialUIController } from "context";
import { Divider } from "@mui/material";
import GoPlusDark from "assets/images/goPlusDark.png";
import GoPlusWhite from "assets/images/goPlusWhite.png";
import { Link } from "react-router-dom";
import useApi from "utils/useApi";
import { getAddressLinkByChain } from "./utils";

const burnAddresses = [
  "0x000000000000000000000000000000000000dead",
  "0x0000000000000000000000000000000000000000",
];

const Scanner = ({ contractAddress = "", chain }) => {
  const [controller] = useMaterialUIController();
  const { getTokenAuditDataByContract } = useApi();

  const [showIssueList, setShowIssueList] = useState(false);
  const [openConfigurator, setOpenConfigurator] = useState(false);

  const { data, refetch, isFetched } = useQuery(
    `getTokenAuditDataByContract-${contractAddress}`,
    () => getTokenAuditDataByContract(contractAddress, chain),
    {
      retry: false,
      refetchOnWindowFocus: true,
      enabled: false,
    }
  );

  useEffect(() => {
    if (contractAddress) {
      refetch();
    }
  }, [contractAddress]);

  const scannerData = data?.data ?? {};

  const { darkMode } = controller;
  const lpHolders = scannerData?.lp_holders ?? [];

  const isRonunced = burnAddresses.includes(scannerData?.owner_address);

  const totalLpBurnedPercentages = lpHolders.reduce((sum, entry) => {
    if (burnAddresses.includes(entry.address) && entry.is_locked === 1) {
      const percentValue = parseFloat(entry.percent);
      return sum + percentValue;
    }

    return sum;
  }, 0);
  const totalLockedPercentages = lpHolders.reduce((sum, entry) => {
    if (entry.is_locked === 1 && !burnAddresses.includes(entry.address)) {
      const percentValue = parseFloat(entry.percent);
      return sum + percentValue;
    }

    return sum;
  }, 0);

  const lpBurnedPercentage = Number((totalLpBurnedPercentages * 100).toFixed(2));
  const lpLockedPercentage = Number((totalLockedPercentages * 100).toFixed(2));

  const primaryItems = [
    {
      label: "Buy/Sell Tax",
      value: `${getDecimalValue((scannerData?.buy_tax ?? 0) * 100)}% / ${getDecimalValue(
        (scannerData?.sell_tax ?? 0) * 100
      )}%`,
      description: "Buy and sell tax of the token",
      isOk: (scannerData?.buy_tax ?? 0) * 100 + (scannerData?.buy_tax ?? 0) * 100 <= 10,
      isExists: has(scannerData, "buy_tax"),
    },
    {
      label: "Contract Verified",
      value: scannerData?.is_open_source === "1" ? "Yes" : "No",
      description:
        "Whether the contract source code can be read or not. Unverified contract may have mallicious functions.",
      isOk: scannerData?.is_open_source === "1",
      isExists: has(scannerData, "is_open_source"),
    },
    {
      label: "Honeypot",
      value: scannerData?.is_honeypot === "1" ? "Yes" : "No",
      description: `Whether the token is a honeypot. "HoneyPot" means that the token cannot be sold.`,
      isOk: scannerData?.is_honeypot === "0",
      isExists: has(scannerData, "is_honeypot"),
    },
    {
      label: "LP Locked",
      value: <div>{lpLockedPercentage}% 🔐</div>,
      description: "Whether liquidity pool is locked. If locked, how many percentage locked.",
      isOk: lpBurnedPercentage + lpLockedPercentage >= 90,
      isExists: true,
    },
    {
      label: "LP Burned",
      value: <div>{lpBurnedPercentage}% 🔥</div>,
      description: "Whether liquidity pool is burned. If burned, how many percentage burned.",
      isOk: lpBurnedPercentage + lpLockedPercentage >= 90,
      isExists: true,
    },
    {
      label: "Renounced",
      value: isRonunced ? "Yes" : "No",
      description:
        "Whether contract has been renounced or not. Un-renounced owner can adjust the parameters and status of the contract, such as minting, modification of slippage, suspension of trading, setting blacklist etc.",
      isOk: isRonunced,
      isExists: has(scannerData, "owner_address"),
    },
  ];
  const secondaryItems = [
    {
      label: "Holder count",
      value: scannerData?.holder_count,
      description: "Total number of token holders.",
      isOk: true,
      isExists: has(scannerData, "holder_count"),
    },
    {
      label: "LP Holder count",
      value: scannerData?.lp_holder_count,
      description: "Total number of LP token holders.",
      isOk: true,
      isExists: has(scannerData, "lp_holder_count"),
    },
    {
      label: "Can take back ownership",
      value: scannerData?.can_take_back_ownership === "1" ? "Yes" : "No",
      description:
        "Whether the creater can take back ownership of the contract. Be aware of the fake renounce.",
      isOk: scannerData?.can_take_back_ownership === "0",
      isExists: has(scannerData, "can_take_back_ownership"),
    },
    {
      label: "Creator address",
      value: (
        <AddressCopy
          address={scannerData.creator_address}
          fullUrl={getAddressLinkByChain(chain, scannerData.creator_address, "address")}
          fontSize={"12px"}
        />
      ),
      description: "Address that created the current contract.",
      isOk: true,
      isExists: has(scannerData, "creator_address"),
    },
    {
      label: "Creator balance",
      value: (
        <>
          {numPrettyFormatter(scannerData?.creator_balance)} (
          {getDecimalValue(parseFloat(scannerData?.creator_percent) * 100)}%)
        </>
      ),
      description: "The token balance of the contract creater.",
      isOk: scannerData?.creator_percent < "0.05",
      isExists: has(scannerData, "creator_percent"),
    },
    {
      label: "Honeypot with same creater",
      value: scannerData?.honeypot_with_same_creator,
      description: "Number of honeypots from the same creater.",
      isOk: scannerData?.honeypot_with_same_creator === "0",
      isExists: has(scannerData, "honeypot_with_same_creator"),
    },
    {
      label: "Owner address",
      value: (
        <AddressCopy
          address={scannerData.owner_address}
          fullUrl={getAddressLinkByChain(chain, scannerData.owner_address, "address")}
          fontSize={"12px"}
        />
      ),
      description: "The current contract's owner address.",
      isOk: true,
      isExists: has(scannerData, "owner_address"),
    },
    {
      label: "Owner balance",
      value: (
        <>
          {numPrettyFormatter(scannerData?.owner_balance ?? 0)} (
          {getDecimalValue(parseFloat(scannerData?.owner_percent ?? 0) * 100)}
          %)
        </>
      ),
      description: "The token balance of the contract owner.",
      isOk: scannerData?.owner_percent < "0.05",
      isExists: has(scannerData, "owner_percent"),
    },
    {
      label: "Hidden owner",
      value: scannerData?.hidden_owner === "1" ? "Yes" : "No",
      description: "Whether contract has a hidden owner.",
      isOk: scannerData?.hidden_owner === "0",
      isExists: has(scannerData, "hidden_owner"),
    },
    {
      label: "Tax modifiable",
      value: scannerData?.slippage_modifiable === "1" ? "Yes" : "No",
      description: "Whether the trading tax can be modifiable by token contract.",
      isOk: scannerData?.slippage_modifiable === "0",
      isExists: has(scannerData, "slippage_modifiable"),
    },
    {
      label: "Proxy contract",
      value: scannerData?.is_proxy === "1" ? "Yes" : "No",
      description: "Whether the contract is a proxy contract.",
      isOk: true,
      isExists: has(scannerData, "is_proxy"),
    },
    {
      label: "Transfer pausable",
      value: scannerData?.transfer_pausable === "1" ? "Yes" : "No",
      description: "Whether trading can be paused by token contract.",
      isOk: scannerData?.transfer_pausable === "0",
      isExists: has(scannerData, "transfer_pausable"),
    },
    {
      label: "Trading cooldown",
      value: scannerData?.trading_cooldown === "1" ? "Yes" : "No",
      description:
        "Whether the contract has a trading-cool-down mechanism that can limit the minimum time between two transactions.",
      isOk: scannerData?.trading_cooldown === "0",
      isExists: has(scannerData, "trading_cooldown"),
    },
    {
      label: "Has blacklist",
      value: scannerData?.is_blacklisted === "1" ? "Yes" : "No",
      description:
        "Whether the blacklist function is not included in the contract. If there is a blacklist, some addresses may not be able to trade normally.",
      isOk: scannerData?.is_blacklisted === "0",
      isExists: has(scannerData, "is_blacklisted"),
    },
    {
      label: "Has whitelist",
      value: scannerData?.is_whitelisted === "1" ? "Yes" : "No",
      description:
        "Whether the whitelist function is not included in the contract. If there is a whitelist, only white listed addresses may be able to trade normally.",
      isOk: scannerData?.is_whitelisted === "0",
      isExists: has(scannerData, "is_whitelisted"),
    },
    {
      label: "Mintable",
      value: scannerData?.is_mintable === "1" ? "Yes" : "No",
      description: "Whether the contract has the ability to mint tokens.",
      isOk: scannerData?.is_mintable === "0",
      isExists: has(scannerData, "is_mintable"),
    },
    {
      label: "Is anti whale",
      value: scannerData?.is_anti_whale === "1" ? "Yes" : "No",
      description:
        "Whether the contract has the function to limit the maximum amount of transactions or the maximum token position for a single address.",
      isOk: scannerData?.is_anti_whale === "0",
      isExists: has(scannerData, "is_anti_whale"),
    },
    {
      label: "Cannot sell all",
      value: scannerData?.cannot_sell_all === "1" ? "Yes" : "No",
      description:
        "Whether the contract has the function restricting the token holders from selling all the tokens. ",
      isOk: scannerData?.cannot_sell_all === "0",
      isExists: has(scannerData, "cannot_sell_all"),
    },
    {
      label: "Owner can change balance",
      value: scannerData?.owner_change_balance === "1" ? "Yes" : "No",
      description: "Whether the contract owner can change token holder balances.",
      isOk: scannerData?.owner_change_balance === "0",
      isExists: has(scannerData, "owner_change_balance"),
    },
  ];

  const isAuditPassed = primaryItems.every((item) => item.isOk === true);
  const totalIssues = [...primaryItems, ...secondaryItems].reduce((sum, each) => {
    if (!each.isOk && each.isExists) {
      sum = sum + 1;
    }
    return sum;
  }, 0);

  const scannerHeader = (
    <CardHeader
      title={
        <ListContainer
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="button" fontWeight="medium">
            Audit Report
          </MDTypography>
          <MDTypography variant="button" fontSize="14px">
            <ListContainer>
              <Link to="https://gopluslabs.io/" target="_blank">
                <img
                  src={darkMode ? GoPlusDark : GoPlusWhite}
                  height="14px"
                  width="80px"
                  style={{ marginRight: "4px" }}
                />
              </Link>
              {isAuditPassed || !isFetched ? (
                <Tooltip
                  title={
                    "Basic verification passed. Please Double check always! We do not give any safety assurance!"
                  }
                  placement="top"
                >
                  <GppGoodIcon htmlColor="rgb(76, 175, 80)" fontSize="medium" />
                </Tooltip>
              ) : (
                <Tooltip
                  title={"Basic verification with warning. Please Double check always!"}
                  placement="top"
                >
                  <FlagIcon
                    sx={{ marginLeft: "4px", marginTop: "-4px" }}
                    color="warning"
                    fontSize="small"
                  />
                </Tooltip>
              )}
            </ListContainer>
          </MDTypography>
        </ListContainer>
      }
      sx={{
        padding: "8px",
        paddingBottom: "8px",
      }}
    />
  );

  const Content = (
    <MDBox>
      <Grid container spacing={0}>
        {(openConfigurator ? [...primaryItems, ...secondaryItems] : primaryItems)
          .filter((eachItem) => {
            return openConfigurator && showIssueList ? !eachItem.isOk && eachItem.isExists : true;
          })
          .map(({ label, value, description, isOk, isExists }) => {
            const isPassed = isOk;
            const noValue = !isExists;
            return (
              <Grid item xs={12} key={label}>
                <ListContainer
                  sx={{ textAlign: "center", lineHeight: "1" }}
                  borderRadius="sm"
                  m={"2px"}
                  mx={"8px"}
                  pb={"0px"}
                  pt={"4px"}
                >
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Tooltip title={description} placement="top">
                      <InfoIcon sx={{ opacity: 0.5 }} />
                    </Tooltip>
                    &nbsp;&nbsp;{label}
                  </MDTypography>
                  <MDTypography variant="p" color="text" fontWeight="regular" fontSize="14px">
                    <ListContainer style={{ color: "#36BB91" }}>
                      {isFetched ? (
                        <>
                          {noValue ? (
                            "-"
                          ) : (
                            <>
                              {isPassed ? (
                                <>
                                  {value}
                                  <CheckIcon
                                    sx={{ marginLeft: "4px" }}
                                    fontSize="small"
                                    fontWeight={700}
                                    htmlColor="#36BB91"
                                  />
                                </>
                              ) : (
                                <>
                                  <MDTypography
                                    variant="text"
                                    color="warning"
                                    fontWeight="regular"
                                    fontSize="14px"
                                  >
                                    {value}
                                  </MDTypography>
                                  <FlagIcon
                                    sx={{ marginLeft: "4px" }}
                                    color="warning"
                                    fontSize="small"
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <div style={{ height: "23px" }} />
                      )}
                    </ListContainer>
                  </MDTypography>
                </ListContainer>
                <Divider sx={{ margin: 0, padding: 0 }} />
              </Grid>
            );
          })}
      </Grid>
      <br />
    </MDBox>
  );

  if (!openConfigurator) {
    return (
      <Card
        sx={{
          "&.MuiPaper-root": {
            padding: "0px",
            border: "none",
          },
        }}
        elevation={0}
      >
        {scannerHeader}
        {Content}
        <MDBox
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => setOpenConfigurator(true)}
        >
          <MDTypography
            variant="button"
            color="text"
            fontWeight="regular"
            fontSize="14px"
            sx={{
              border: `1px solid ${isAuditPassed ? "#36BB91" : "#fb8c00"}`,
              padding: "0px 16px",
              marginTop: "-24px",
              marginBottom: "8px",
              borderRadius: "16px",
            }}
          >
            Check Audits
          </MDTypography>
        </MDBox>
      </Card>
    );
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator: openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={1}
        sx={{ position: "relative" }}
      >
        <MDBox>
          <MDTypography variant="h5">Audit Report</MDTypography>
          <ListContainer
            style={{
              marginTop: "0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <MDTypography variant="button" fontSize="14px">
                <MDTypography
                  variant="text"
                  fontWeight="regular"
                  sx={{
                    marginRight: "8px",
                  }}
                >
                  Powered by
                </MDTypography>
                <Link to="https://gopluslabs.io/" target="_blank">
                  <img
                    src={darkMode ? GoPlusDark : GoPlusWhite}
                    height="14px"
                    width="80px"
                    style={{ marginRight: "4px" }}
                  />
                </Link>
              </MDTypography>
            </div>
            <MDBox sx={{ position: "absolute", right: "8px" }}>
              <MDButton
                color={"warning"}
                size="small"
                variant="gradient"
                onClick={() => setShowIssueList(!showIssueList)}
              >
                <GppMaybeIcon fontSize="large" sx={{ marginRight: "8px" }} />
                Issues: {totalIssues || 0}
              </MDButton>
            </MDBox>
          </ListContainer>
        </MDBox>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => setOpenConfigurator(false)}
          style={{
            position: "absolute",
            top: "24px",
            right: "8px",
          }}
        >
          close
        </Icon>
      </MDBox>
      <Divider />

      <MDBox>{Content}</MDBox>
    </ConfiguratorRoot>
  );
};

export default Scanner;

const ListContainer = ({ children, justifyContent = "space-between", style = {}, ...rest }) => (
  <MDBox
    {...rest}
    sx={{ display: "flex", alignItems: "center", justifyContent: justifyContent }}
    style={style}
  >
    {children}
  </MDBox>
);
