import { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";

// Billing page components
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { numPrettyFormatter } from "utils/price";
import { useMaterialUIController } from "context";
import AddressCopy from "./addressCopyWithTooltip";
import { getAddressLinkByChain } from "./utils";
import { Divider } from "@mui/material";

const MarketData = ({
  projectData = {},
  holdersCount = 0,
  isHolderDataLoading = true,
  isMobileView = false,
  poolCreated = "",
  deployer = "",
  style = {},
  reportedSupply = 0,
  auditData = {},
  realTimePice,
}) => {
  const params = useParams();
  const [controller] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const { stats = {} } = projectData;
  const { prices = {} } = stats;
  const reportedCircSupply = reportedSupply > 5 ? reportedSupply : null;
  const chain = params?.chain;

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const calculateMcap = () => {
    if (realTimePice) {
      const supply = reportedCircSupply || stats?.supply || auditData?.total_supply;
      return numPrettyFormatter(realTimePice * supply);
    }
    if (reportedCircSupply) {
      return numPrettyFormatter(prices?.live * reportedCircSupply);
    }
    return numPrettyFormatter(stats.fullyDilutedMarketCap);
  };

  const mcap = calculateMcap() || 0;

  const hideAndShowBasedOnScreenSize = [
    {
      label: "Total Supply",
      value: numPrettyFormatter(stats.supply || auditData?.total_supply || 0),
    },
    {
      label: "Holders",
      value:
        isHolderDataLoading && !holdersCount ? (
          <CircularProgress size={18} color="inherit" />
        ) : (
          numPrettyFormatter(holdersCount, 0, 50000)
        ),
    },
    {
      label: "Volume (24h)",
      value: `$${numPrettyFormatter(prices.day_1?.volume ?? 0)}`,
    },
    {
      label: "Total TXs",
      value: numPrettyFormatter(stats.tradeCount ?? 0),
    },
  ];
  const primaryItems = [
    {
      label: "Total MCap",
      value: mcap,
    },
    {
      label: "Liquidity",
      value: stats?.trueValue ? numPrettyFormatter((stats?.trueValue ?? 0) * 2) : "-",
    },
  ].concat(isMobileView ? [] : hideAndShowBasedOnScreenSize);

  const secondaryItems = (!isMobileView ? [] : hideAndShowBasedOnScreenSize).concat([
    {
      label: "Market Cap",
      value: mcap,
    },
    {
      label: "Stats (24h)",
      value: prices.day_1?.prettyPrint,
    },
    {
      label: "Circ Supply",
      value: `${reportedCircSupply ? numPrettyFormatter(reportedCircSupply) : "-"}`,
    },
    {
      label: "Pool Created",
      value: poolCreated ? (
        <small>{format(new Date(Number(poolCreated) * 1000), "yyyy-MM-dd HH:mm")}</small>
      ) : (
        "-"
      ),
    },
    {
      label: "Pooled Tokens",
      value: numPrettyFormatter(stats.pooledToken),
    },
    {
      label: "Pooled WETH",
      value: numPrettyFormatter(stats.pooledBase),
    },
    {
      label: "Deployer Address",
      value: deployer ? (
        <AddressCopy
          address={deployer}
          fullUrl={getAddressLinkByChain(chain, deployer, "address")}
          fontSize={"12px"}
        />
      ) : (
        "-"
      ),
    },
  ]);

  return (
    <Card
      sx={{
        "&.MuiPaper-root": {
          padding: isMobileView ? "4px" : "8px",
        },
        ...style,
      }}
      elevation={0}
    >
      <MDBox
        sx={{ marginBottom: "4px", overflow: "scroll" }}
        display="flex"
        alignItems="center"
        justifyContent={isMobileView ? "flex-start" : "center"}
      >
        <MDTypography
          variant="button"
          fontWeight="medium"
          fontSize="12px"
          style={{ textAlign: "left", display: "flex", alignItems: "center", marginLeft: "0px" }}
        >
          CA:&nbsp;
          <AddressCopy
            address={projectData?.address}
            fullUrl={`https://etherscan.io/token/${projectData?.address}`}
          />
        </MDTypography>
        <MDTypography
          variant="button"
          fontWeight="medium"
          fontSize="12px"
          style={{ textAlign: "left", display: "flex", alignItems: "center", marginLeft: "16px" }}
        >
          Pair:&nbsp;
          <AddressCopy address={projectData?.pool_address} />
        </MDTypography>
        <Divider sx={{ p: 0, paddingBottom: "8px", marginTop: 0 }} />
      </MDBox>
      <Grid container spacing={0}>
        {primaryItems.map(({ label, value }, i) => {
          return (
            <Grid item xs={6} sm={6} key={label + i}>
              <MDBox
                sx={{ textAlign: "center", lineHeight: "1" }}
                bgColor={darkMode ? "transparent" : "grey-100"}
                borderRadius="sm"
                border="0.1px solid #344767"
                m={"2px"}
                pb={"4px"}
                pt={"4px"}
              >
                <MDTypography variant="button" fontWeight="medium" sx={{ display: "block" }}>
                  {label}
                </MDTypography>
                <MDTypography
                  variant="p"
                  color="text"
                  fontWeight="regular"
                  fontSize="14px"
                  sx={{ color: "#36BB91" }}
                >
                  {value}
                </MDTypography>
              </MDBox>
            </Grid>
          );
        })}

        {showMoreInfo &&
          secondaryItems.map(({ label, value }, i) => {
            const isDeployer = label === "Deployer Address";
            const sxValue = isDeployer ? 12 : 6;
            return (
              <Grid item xs={sxValue} sm={sxValue} key={label + i}>
                <MDBox
                  sx={{ textAlign: "center", lineHeight: "1" }}
                  bgColor={darkMode ? "transparent" : "grey-100"}
                  borderRadius="sm"
                  border="0.1px solid #344767"
                  m={"2px"}
                  pb={"4px"}
                  pt={"4px"}
                >
                  <MDTypography variant="button" fontWeight="medium" sx={{ display: "block" }}>
                    {label}
                  </MDTypography>
                  <MDTypography
                    variant="p"
                    color="text"
                    fontWeight="regular"
                    fontSize="14px"
                    sx={{
                      display: "inline-block",
                      color: "#36BB91",
                    }}
                  >
                    {value}
                  </MDTypography>
                </MDBox>
              </Grid>
            );
          })}
        <MDBox
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
            cursor: "pointer",
            background: darkMode ? "#344767" : "transparent",
            width: "calc(100% + 16px)",
            position: "relative",
            marginBottom: "-8px",
            marginLeft: isMobileView ? "-4px" : "-8px",
            marginRight: isMobileView ? "-4px" : "-8px",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
          }}
          onClick={() => toggleMoreInfo()}
        >
          <MDTypography variant="button" fontWeight="regular" fontSize="14px">
            {showMoreInfo ? "Show less" : "Show more"}
          </MDTypography>
          {showMoreInfo ? <ArrowUpIcon sx={iconsStyle} /> : <ArrowDownIcon sx={iconsStyle} />}
        </MDBox>
      </Grid>
    </Card>
  );
};

MarketData.defaultProps = {
  projectData: {},
  holdersCount: 0,
  isHolderDataLoading: false,
  isMobileView: false,
  style: {},
  realTimePice: null,
  poolCreated: "",
  deployer: "",
  reportedSupply: 0,
  auditData: {},
};

// Typechecking props for the MarketData
MarketData.propTypes = {
  projectData: PropTypes.any,
  holdersCount: PropTypes.number,
  reportedSupply: PropTypes.number,
  poolCreated: PropTypes.string,
  deployer: PropTypes.string,
  isHolderDataLoading: PropTypes.bool,
  isMobileView: PropTypes.bool,
  style: PropTypes.any,
  realTimePice: PropTypes.any,
  auditData: PropTypes.any,
};

export default MarketData;
