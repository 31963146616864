import { atom } from "recoil";

const chartFilterByAddressAtom = atom({
  key: "chartFilterByAddress-atom",
  default: {
    currentAddress: "",
    trades: [],
    summary: "", // profit/loss/not-sold
    amount: 0,
  },
});

export default chartFilterByAddressAtom;
