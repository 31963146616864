// 0xHub Dashboard React layouts
import MDAvatar from "components/MDAvatar";

import Dashboard from "layouts/dashboard";
import TredingView from "layouts/trendingView";
import ActivelyTradedView from "layouts/activelyTraded";
import GainersView from "layouts/gainersView";
import LosersView from "layouts/losersView";
import RecentlyAddedView from "layouts/recentlyAddedView";
import ProfileView from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import PasswordReset from "layouts/authentication/passwordReset";
import VerifyAccount from "layouts/authentication/verifyAccount";
import Advertisement from "layouts/advertisement";

// @mui icons
import Icon from "@mui/material/Icon";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import Project from "layouts/project";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Daily Gainers",
    key: "gainers",
    icon: (
      <Icon
        fontSize="small"
        sx={{
          color: "#36BB91",
        }}
      >
        moving
      </Icon>
    ),
    route: "/gainers",
    component: <GainersView />,
  },
  {
    type: "collapse",
    name: "Daily Losers",
    key: "losers",
    icon: (
      <Icon
        fontSize="small"
        sx={{
          color: "#F44335",
        }}
      >
        trending_down
      </Icon>
    ),
    route: "/losers",
    component: <LosersView />,
  },
  {
    type: "collapse",
    name: "Recently Added",
    key: "recent",
    icon: (
      <Icon
        fontSize="small"
        sx={{
          color: "#1A73E8",
        }}
      >
        stroller
      </Icon>
    ),
    route: "/recent",
    component: <RecentlyAddedView />,
  },
  {
    type: "collapse",
    name: "Trending",
    key: "trending",
    icon: (
      <Icon
        fontSize="small"
        sx={{
          color: "#F44335",
        }}
      >
        whatshot
      </Icon>
    ),
    route: "/trending",
    component: <TredingView />,
  },
  {
    type: "collapse",
    name: "Actively Traded",
    key: "active",
    icon: (
      <Icon fontSize="small" sx={{ marginTop: "-8px" }}>
        ⚡️
      </Icon>
    ),
    route: "/active",
    component: <ActivelyTradedView />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <ProfileView />, // Need to change
    disabled: false,
    hiddenFromNav: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    disabled: false,
    hiddenFromNav: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    disabled: false,
    hiddenFromNav: true,
  },
  {
    type: "collapse",
    name: "Password-reset",
    key: "password-reset",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/password-reset",
    component: <PasswordReset />,
    disabled: false,
    hiddenFromNav: true,
  },
  {
    type: "collapse",
    name: "Verify account",
    key: "verify",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/verify",
    component: <VerifyAccount />,
    disabled: false,
    hiddenFromNav: true,
  },
  {
    type: "collapse",
    name: "Advertise With Us",
    key: "advertisement",
    icon: (
      <Icon
        fontSize="small"
        color="secondary"
        style={{
          color: "#36BB91",
          opacity: 0.8,
        }}
      >
        ad_units
      </Icon>
    ),
    route: "/advertisement",
    component: <Advertisement />,
    hiddenFromNav: false,
    disabled: false,
  },
  {
    type: "collapse",
    name: "Project",
    key: "project",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/:chain/:token",
    component: <Project />,
    hiddenFromNav: true,
    disabled: false,
  },
  {
    type: "collapse",
    name: "0xH token info",
    key: "project",
    icon: <Icon fontSize="small">💰</Icon>,
    href: "https://info.0xhub.io",
    hiddenFromNav: false,
    disabled: false,
  },
  /*
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  */
];

export default routes;
