export const avatarStyle = (username, darkMode, additionalStyles = {}) => {
  const firstLetter = username.charAt(0).toUpperCase();
  let backgroundColor;

  switch (true) {
    case ["A", "B", "C", "D", "E"].includes(firstLetter):
      backgroundColor = darkMode ? "#ff9999" : "#ff4d4d"; // Light Red (Dark) / Vivid Red (Bright)
      break;
    case ["F", "G", "H", "I", "J"].includes(firstLetter):
      backgroundColor = darkMode ? "#ffcc80" : "#ffad33"; // Light Orange (Dark) / Bright Orange (Bright)
      break;
    case ["K", "L", "M", "N", "O"].includes(firstLetter):
      backgroundColor = darkMode ? "#80bfff" : "#3399ff"; // Soft Blue (Dark) / Bright Blue (Bright)
      break;
    case ["P", "Q", "R", "S", "T"].includes(firstLetter):
      backgroundColor = darkMode ? "#d9b3ff" : "#b266ff"; // Light Purple (Dark) / Vibrant Purple (Bright)
      break;
    case ["U", "V", "W", "X", "Y", "Z"].includes(firstLetter):
      backgroundColor = darkMode ? "#99e699" : "#33cc33"; // Light Green (Dark) / Vivid Green (Bright)
      break;
    default:
      backgroundColor = darkMode ? "#bfbfbf" : "#808080"; // Neutral Gray (Dark) / Standard Gray (Bright)
      break;
  }

  return {
    backgroundColor,
    color: darkMode ? "#f0f0f0" : "#ffffff", // White text for light backgrounds in both modes
    ...additionalStyles,
  };
};
