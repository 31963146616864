import { useState } from "react";
import { truncate } from "lodash";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import useMobileView from "utils/useMobileView";
import { useMaterialUIController } from "context";

const ProjectDescription = ({ description }) => {
  const [controller] = useMaterialUIController();
  const { mobileView } = useMobileView();

  const [isReadMore, setIsReadMore] = useState(false);
  const { darkMode, transparentNavbar } = controller;

  if (!description) {
    return null;
  }

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const shortDescription = truncate(description, {
    length: 260,
  });
  const isTruncated = description.length > shortDescription.length;
  return (
    <Card>
      <CardHeader
        title={
          <MDTypography
            variant="button"
            fontWeight="medium"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            Project Description
          </MDTypography>
        }
        sx={{
          padding: "12px",
          paddingBottom: "8px",
        }}
      />
      <MDBox
        sx={{ textAlign: "center", lineHeight: "1" }}
        borderRadius="sm"
        p="8px"
        py={"0px"}
        pb={isTruncated ? "0px" : "8px"}
      >
        <MDTypography
          variant="p"
          color="text"
          fontWeight="regular"
          fontSize="14px"
          style={{ textAlign: "left", cursor: "pointer" }}
        >
          {isReadMore ? description : shortDescription}
          {isTruncated && (
            <MDBox
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                cursor: "pointer",
                background: darkMode ? "#344767" : "transparent",
                width: "calc(100% + 16px)",
                position: "relative",
                marginTop: "4px",
                marginBottom: "-48px",
                marginLeft: mobileView ? "-4px" : "-8px",
                marginRight: mobileView ? "-4px" : "-8px",
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
              }}
              onClick={() => setIsReadMore(!isReadMore)}
            >
              <MDTypography variant="button" fontWeight="regular" fontSize="14px">
                {isReadMore ? "Read less" : "Read more"}
              </MDTypography>
              {isReadMore ? <ArrowUpIcon sx={iconsStyle} /> : <ArrowDownIcon sx={iconsStyle} />}
            </MDBox>
          )}
        </MDTypography>
      </MDBox>
    </Card>
  );
};

ProjectDescription.defaultProps = {
  description: "",
};

ProjectDescription.propTypes = {
  description: PropTypes.string,
};
export default ProjectDescription;
