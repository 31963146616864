import { atom } from "recoil";

const projectAtom = atom({
  key: "project-atom",
  default: {
    allProjects: [],
    totalProjects: 0,
    gainersProjects: [],
    losersProjects: [],
    trendingProjects: [],
    recentlyAddedProjects: [],
    pageNumber: 1,
  },
});

export default projectAtom;
