/* eslint-disable react/prop-types */

import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { toLower, shuffle } from "lodash";

import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";

import { useMaterialUIController } from "context";
import useMobileView from "utils/useMobileView";
import useApi from "utils/useApi";

const BannerAd = () => {
  const [controller] = useMaterialUIController();
  const { mobileView } = useMobileView();
  const location = useLocation();
  const { getAdvertisements, createAdImpression } = useApi();
  const [showAd, setShowAd] = useState(true);

  const { data: advertisementDataRes = {} } = useQuery(
    `getAdvertisements`,
    () => getAdvertisements(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const advertisementData = advertisementDataRes?.data ?? [];
  const bannersData = advertisementData.filter((each) => each.placement === "banner");

  const bannerAd = useMemo(() => {
    if (bannersData.length > 0) {
      const shuffled = shuffle(bannersData);
      return shuffled?.[0];
    }
    return null;
  }, [bannersData.length]);

  const { darkMode, transparentNavbar } = controller;

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  if (!(showAd && bannerAd && mobileView)) {
    return null;
  }

  const trackAdImpressions = async (type = "click") => {
    await createAdImpression({
      adId: bannerAd.id,
      source: location ? location.pathname : "0xhub",
      eventType: type,
    });
  };

  return (
    <MDBox
      component={Link}
      to={bannerAd?.link_to ?? ""}
      target="_blank"
      onClick={() => trackAdImpressions("click")}
      display="block"
      sx={{
        position: "relative",
        top: 0,
        height: "45px",
        backgroundImage: `url("${bannerAd?.media}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {mobileView && (
        <div>
          <Icon
            fontSize="small"
            sx={iconsStyle}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAd(false);
              trackAdImpressions("close");
            }}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "2px",
              top: "2px",
              color: "#fff",
              background: "#0A0A0A",
              borderRadius: "50%",
              margin: 0,
            }}
          >
            cancel
          </Icon>
        </div>
      )}
    </MDBox>
  );
};

BannerAd.defaultProps = {
  // darkMode: false,
};

// Typechecking props for the BannerAd
BannerAd.propTypes = {
  // projectData: PropTypes.bool,
};
export default BannerAd;
