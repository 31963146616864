/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import BananaLogo from "assets/images/bananagunBotLogo.png";
import maestroLogo from "assets/images/maestroBotLogo.png";
import unibotLogo from "assets/images/unibotLogo.png";
import wagieLogo from "assets/images/wagieBotLogo.png";

import { useMaterialUIController } from "context";
import { NavLink } from "react-router-dom";

const SniperList = ({ contractAddress, style = {} }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const primaryItems = [
    {
      label: "Banana",
      value: `https://t.me/BananaGunSniper_bot?start=ref_helloworld123_${contractAddress}`,
      logo: BananaLogo,
    },
    {
      label: "Maestro",
      value: `https://t.me/MaestroSniperBot?start=${contractAddress}`,
      logo: maestroLogo,
    },
    {
      label: "Unibot",
      value: `https://t.me/unibotsniper_bot?start=helloworld123-${contractAddress}`,
      logo: unibotLogo,
    },
    {
      label: "Wagiebot",
      value: `https://t.me/wagiebot?start=helloworld123${contractAddress}`,
      logo: wagieLogo,
    },
  ];

  return (
    <Card
      sx={{
        "&.MuiPaper-root": {
          padding: "2px",
          background: "transparent",
        },
        ...style,
      }}
      elevation={0}
    >
      <Grid container spacing={0}>
        {primaryItems.map(({ label, value, logo }, i) => {
          return (
            <Grid
              item
              xs={6}
              sm={6}
              key={label + i}
              sx={{
                "& > :hover": {
                  border: "0.1px solid #FFFFFFCC",
                },
                padding: "2px",
              }}
            >
              <MDBox
                component={NavLink}
                to={value}
                target="_blank"
                bgColor={darkMode ? "transparent" : "grey-100"}
                borderRadius="sm"
                border="0.4px solid #344767"
                sx={{
                  width: "100%",
                  display: "inline-block",
                  padding: "4px 8px",
                }}
              >
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <MDAvatar
                    src={logo}
                    size="xs"
                    sx={{
                      marginLeft: "0px",
                      border: "1px solid #0A0A0A",
                      background: "#f5f5f5",
                      marginRight: "4px",
                    }}
                  />
                  {label}
                </MDTypography>
              </MDBox>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default SniperList;
