import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { truncate, shuffle } from "lodash";
import Marquee from "react-fast-marquee";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";

import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import Chip from "@mui/material/Chip";
import MDBox from "components/MDBox";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { useMaterialUIController } from "context";
import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import useApi from "utils/useApi";
import useMobileView from "utils/useMobileView";
import { projectAtom } from "atoms";
import { getChainForUrl } from "utils/chain";

const Trending = () => {
  const { getAdvertisements, createAdImpression } = useApi();
  const { mobileView } = useMobileView();
  const location = useLocation();
  const projectData = useRecoilValue(projectAtom);

  const { data: advertisementDataRes = {} } = useQuery(
    `getAdvertisements`,
    () => getAdvertisements(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const advertisementData = advertisementDataRes?.data ?? [];
  const trendingAdList = advertisementData.filter((each) => each.placement === "trending");

  const trendingAdData = useMemo(() => {
    if (trendingAdList.length > 0) {
      const shuffled = shuffle(trendingAdList);
      return shuffled?.[0];
    }
    return null;
  }, [trendingAdList.length]);

  const trendingProjects = projectData?.trendingProjects ?? [];

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const trackAdImpressions = async (type = "click") => {
    await createAdImpression({
      adId: trendingAdData.id,
      source: location ? location.pathname : "0xhub",
      eventType: type,
    });
  };

  const adLink =
    trendingAdData?.link_to ||
    `https://0xhub.io/ethereum/0xfddbda688514dbcb7fa391ebc7964723da4d3921`;
  const adBanner =
    trendingAdData?.media ||
    `https://0xhub.s3.eu-west-1.amazonaws.com/project/0xhub-email-logo.png`;
  const adContent = trendingAdData?.header || "0xHub.io your new home";

  return (
    <Container bgColor={darkMode ? "#1A1F2B" : "#dddddd"}>
      <div className="trending">
        <StyledMarquee
          style={{
            background: "transparent",
            borderRadius: 28,
          }}
          gradient={false}
          speed={60}
          pauseOnClick
          pauseOnHover
        >
          <Stack direction="row" spacing={1}>
            {trendingProjects.map(({ logo, symbol, pool_address, chain }, index) => {
              return (
                <Link to={`/${getChainForUrl(chain)}/${pool_address}`} key={index + symbol}>
                  <div style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
                    <Typography
                      color={darkMode ? "#ffffffCC" : "#344767"}
                      fontSize={"14px"}
                      sx={{ marginRight: "2px" }}
                    >
                      #{index + 1}
                    </Typography>
                    <Chip
                      key={symbol}
                      label={
                        <MDTypography fontSize="12px" display="flex">
                          {symbol?.toUpperCase()}
                        </MDTypography>
                      }
                      sx={{
                        background: darkMode ? "inherit" : "#f5f5f5",
                        cursor: "pointer",
                        color: darkMode ? "#ffffffCC" : "#inherit",
                        padding: "2px",
                      }}
                      size="small"
                      avatar={<MDAvatar alt={"alt"} src={logo} />}
                    />
                  </div>
                </Link>
              );
            })}
          </Stack>
        </StyledMarquee>
      </div>
      <div className="advertisement">
        <Button
          color="primary"
          variant="contained"
          sx={{
            borderRadius: 0,
          }}
          disableElevation
          fullWidth
          onClick={() => trackAdImpressions("click")}
        >
          <>
            <a href={adLink} target="_blank" rel="noreferrer">
              <MDAvatar
                alt={"0xHub"}
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: "50%",
                  position: "absolute",
                  left: 20,
                  background: "#1A1F2B",
                }}
                src={adBanner}
              >
                {/* <TelegramIcon htmlColor="rgb(29, 155, 240)" color="info" /> */}
              </MDAvatar>
              {!mobileView && (
                <MDBox sx={{ color: "inherit" }}>
                  <span style={{ marginLeft: 28, textTransform: "none", overflow: "hidden" }}>
                    {truncate(adContent, {
                      length: 30,
                    })}
                  </span>
                </MDBox>
              )}
            </a>
          </>
        </Button>
      </div>
    </Container>
  );
};

export default Trending;

const Container = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.bgColor};
  color: inherit;
  padding: 0px;
  width: 100%;
  border-top-left-radius: 16px 16px;
  border-top-right-radius: 16px 16px;

  .trending {
    width: calc(100% - 300px);
    position: relative;
    overflow: hidden;
    right: -14px;
  }
  .advertisement {
    width: 270px;
    align-items: center;

    button {
      border-top-right-radius: 16px 16px;
      background-image: radial-gradient(circle at 4px 18px, #42424a 0, #dddddd 20px, #191919 20px);
    }

    a {
      color: #ffffff;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 480px) {
    .trending {
      width: 100%;
      position: relative;
      overflow: hidden;
      right: 0px;
    }
    .advertisement {
      width: 70px;
      position: relative;
      z-index: 999;

      button {
        // border: 1px solid red;
        border-top-right-radius: 16px 16px;
        background-image: radial-gradient(
          circle at 4px 18px,
          #1a1f2b 0px,
          #1a1f2b 18px,
          #202940 0px
        );
      }
    }
  }
`;

const StyledMarquee = styled(Marquee)`
  div.marquee {
    --duration: 40.5667s !important;
  }
`;
