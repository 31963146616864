/* eslint-disable react/prop-types */

import * as React from "react";
import MDBox from "components/MDBox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";

import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import IconButton from "@mui/material/IconButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

export default function FormDialog({
  buttonText,
  title,
  description,
  label,
  name,
  defaultValue = "",
  secondaryDescription = "",
  actionCancelText,
  actionMainText,
  onSubmit,
  onCancel,
  type = "text",
  actionIcon,
  disabled = false,
  inputValidation,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValue(defaultValue);
    setOpen(false);
  };

  const handleChange = (e) => {
    const v = e.target.value;
    if (inputValidation && inputValidation(v)) {
      return;
    }
    setValue(v);
  };

  const handleSubmit = async () => {
    if (onSubmit) {
      await onSubmit(value);
    }
    handleClose();
  };

  const handleCancel = async () => {
    if (onCancel) {
      await onCancel();
    }
    handleClose();
  };

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <MDBox>
      {actionIcon ? (
        <IconButton
          size="small"
          disableRipple
          color="inherit"
          sx={navbarIconButton}
          onClick={handleClickOpen}
          disabled={disabled}
        >
          <Icon sx={iconsStyle}>{actionIcon}</Icon>
        </IconButton>
      ) : (
        <Button variant="outlined" color="info" onClick={handleClickOpen} disabled={false}>
          {buttonText}
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            m: 0,
            p: 0,
            paddingLeft: "16px",
            paddingTop: "8px",
            paddingBottom: "8px",
            background: darkMode ? "#1A1F2B" : "linear-gradient(195deg, #42424a, #191919)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MDTypography variant="h6" fontWeight="medium" sx={{ color: "#ffffffcc" }}>
            {title}
          </MDTypography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <MDTypography
              variant="button"
              fontSize="14px"
              fontWeight="regular"
              sx={{ color: "#0A0A0A" }}
            >
              {description}
            </MDTypography>
            {secondaryDescription && (
              <MDTypography
                variant="button"
                fontSize="12px"
                fontWeight="regular"
                sx={{ color: "#0A0A0A", display: "block" }}
              >
                {secondaryDescription}
              </MDTypography>
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id={name}
            label={label}
            type={type}
            value={value}
            onChange={handleChange}
            fullWidth
            variant="standard"
            inputProps={{
              style: { color: "inherit" },
            }}
            sx={{
              "& .MuiFormLabel-root": {
                color: "#0A0A0A",
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex" }}>
          <Button sx={{ m: 0, p: 0 }} onClick={handleCancel}>
            {actionCancelText}
          </Button>
          <Button sx={{ m: 0, p: 0 }} onClick={handleSubmit}>
            {actionMainText}
          </Button>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}
