/*  */

const link = {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};

export default link;
