import React from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// 0xHub Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Trending from "examples/Trending";

import useApi from "utils/useApi";
import useProjectData from "./useData";
import { CircularProgress } from "@mui/material";

import { useMaterialUIController } from "context";

const activelyTraded = ({ isLoading = false }) => {
  const { fetchAllActivelyTradedProjects, resetTokenCache } = useApi();
  const [rowsPerPage] = React.useState(50);
  const [controller] = useMaterialUIController();
  const { selectedChain } = controller;

  const {
    data: activelyTradedData = [],
    isFetched,
    refetch,
  } = useQuery(`fetchAllActivelyTradedProjects`, () => fetchAllActivelyTradedProjects(), {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const resetCacheAndRefetch = async () => {
    await resetTokenCache();
    await refetch();
  };

  useEffect(() => {
    if (selectedChain) {
      resetCacheAndRefetch();
    }
  }, [selectedChain]);

  const activeTokensData = activelyTradedData?.data ?? [];
  const { columns, rows } = useProjectData(activeTokensData);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Trending />
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={0} mt={0} py={3} px={2} bgColor="inherit">
                <MDTypography variant="h6" color="text" style={displayFlexCenter}>
                  <MDTypography fontWeight="bold">Actively Traded Tokens</MDTypography>
                  <MDTypography
                    component="div"
                    variant="button"
                    fontWeight="bold"
                    color={"success"}
                    sx={{ paddingTop: "6px", marginLeft: "8px", marginTop: "-4px" }}
                  >
                    ⚡️
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <hr style={{ border: "1px solid inherit" }} />
              <MDBox pt={3}>
                {activeTokensData?.length === 0 && !isFetched && (
                  <MDBox
                    spacing={2}
                    position="relative"
                    width="100%"
                    minHeight="40vh"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={60} color="info" />
                  </MDBox>
                )}
                {activeTokensData?.length === 0 && isFetched && (
                  <MDBox
                    position="relative"
                    width="100%"
                    minHeight="20vh"
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    Data not available right now.
                  </MDBox>
                )}
                {activeTokensData?.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isLoading={isLoading}
                    rowsPerPageActiveTrade={rowsPerPage}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default activelyTraded;

const getTitle = () => ({
  gainers: "Gainers",
  losers: "Losers",
  recent: "Recently Added",
  trending: "Trending",
});

const displayFlexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};
