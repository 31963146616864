/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
// @mui material components
import Card from "@mui/material/Card";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import EditIcon from "@mui/icons-material/Edit";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import FormDialog from "./formDialog";

function ProfileForm({ userInfo = {}, darkMode = true }) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    signup: "",
  });

  useEffect(() => {
    if (userInfo) {
      setName(userInfo?.username ?? "");
      setEmail(userInfo?.email ?? "");
    }
  }, [userInfo]);

  return (
    <Card
      elevation={0}
      sx={{
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        minHeight: 250,
        border: "2px solid #344767",
      }}
    >
      <MDBox
        bgColor={darkMode ? "#344767" : "linear-gradient(0deg, #52525a, #2b2b2b)"}
        mx={0}
        mt={0}
        p={0}
        mb={0}
        textAlign="center"
        elevation={0}
      >
        <MDTypography
          variant="h4"
          fontWeight="medium"
          color="white"
          my={1}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          User profile
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <MDBox>
          {/* User name will be available to change only for username/password loggedIn users */}
          <MDBox>
            <MDTypography variant="button">Username:</MDTypography>
            <MDBox mb={1} display="flex" sx={{ border: "0.1px solid #aaa", padding: "4px 8px" }}>
              <MDBox mr={"16px"}>
                <MDTypography variant="button">{userInfo?.username ?? "-"}</MDTypography>
              </MDBox>
              {userInfo?.password && false && (
                <FormDialog
                  actionIcon={<EditIcon />}
                  buttonText="Update username"
                  title="Update username"
                  description="Change the username and click submit to update your username info."
                  label="Username"
                  name="name"
                  defaultValue={name}
                  actionCancelText="Cancel"
                  actionMainText="Submit"
                  onSubmit={() => null}
                  onCancel={() => null}
                  type="text"
                  disabled={true}
                  darkMode={darkMode}
                />
              )}
            </MDBox>
          </MDBox>
          <MDBox>
            <MDTypography variant="button">Email:</MDTypography>
            <MDBox mb={0} display="flex" sx={{ border: "0.1px solid #aaa", padding: "4px 8px" }}>
              <MDBox mr={"16px"}>
                <MDTypography variant="button">{userInfo?.email ?? "-"}</MDTypography>
              </MDBox>
              {userInfo?.password && false && (
                <FormDialog
                  actionIcon={<EditIcon />}
                  buttonText="Update username"
                  title="Update username"
                  description="Change the username and click submit to update your username info."
                  label="Username"
                  name="name"
                  defaultValue={name}
                  actionCancelText="Cancel"
                  actionMainText="Submit"
                  onSubmit={() => null}
                  onCancel={() => null}
                  type="text"
                  disabled={true}
                />
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ProfileForm;
