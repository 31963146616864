import React, { useState } from "react";
import PropTypes from "prop-types";
import useApi from "utils/useApi";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ReplyIcon from "@mui/icons-material/Reply";
import Menu from "@mui/material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuItem from "@mui/material/MenuItem";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { colors, InputAdornment } from "@mui/material";
import useLogin from "utils/useLogin";

const buttonStyle = (darkMode, additionalStyles = {}) => ({
  minWidth: "auto",
  color: darkMode ? "#ffffffcc" : "inherit",
  textTransform: "none",
  padding: "9px 9px",
  ...additionalStyles,
});

export const CommentButtons = ({
  darkMode,
  comment,
  bullishCount,
  bearishCount,
  commentId,
  expandedComments,
  toggleThreadVisibility,
  handleClickReply,
  handleOpenMenu,
  handleCloseMenu,
  isParentComment,
  hasThreads,
  handleDeleteClick,
  handleDeleteComment,
  handleEditClick,
  disabled = false,
  createdByUserId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bullishCounting, setBullishCounting] = useState(parseInt(bullishCount || "0", 10));
  const [bearishCounting, setBearishCounting] = useState(parseInt(bearishCount || "0", 10));
  const [isBullishClicked, setIsBullishClicked] = useState(false);
  const [isBearishClicked, setIsBearishClicked] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { editComments } = useApi();
  const { userInfo } = useLogin("");

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
    handleOpenMenu();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setAnchorEl(null);
  };

  const handleBullishClick = async (commentId) => {
    let newBullishCount = bullishCounting;
    let newBearishCount = bearishCounting;
    if (isBullishClicked) {
      newBullishCount -= 1;
      setIsBullishClicked(false);
    } else {
      newBullishCount += 1;
      if (isBearishClicked) {
        newBearishCount -= 1;
        setIsBearishClicked(false);
      }
      setIsBullishClicked(true);
    }

    setBullishCounting(newBullishCount);
    setBearishCounting(newBearishCount);

    const updatedData = {
      comment: comment,
      bearishCount: newBearishCount,
      bullishCount: newBullishCount,
    };

    await editComments(commentId, updatedData);
  };

  const handleBearishClick = async (commentId) => {
    let newBearishCount = bearishCounting;
    let newBullishCount = bullishCounting;

    if (isBearishClicked) {
      newBearishCount -= 1;
      setIsBearishClicked(false);
    } else {
      newBearishCount += 1;
      if (isBullishClicked) {
        newBullishCount -= 1;
        setIsBullishClicked(false);
      }
      setIsBearishClicked(true);
    }

    setBullishCounting(newBullishCount);
    setBearishCounting(newBearishCount);

    const updatedData = {
      comment: comment,
      bearishCount: newBearishCount,
      bullishCount: newBullishCount,
    };

    await editComments(commentId, updatedData);
  };

  return (
    <>
      <MDButton
        variant="button"
        disabled={disabled || createdByUserId === userInfo?.id}
        sx={buttonStyle(darkMode, {
          fontSize: "12px",
          color: "#36BB91 !important",
        })}
        onClick={() => handleBullishClick(commentId)}
      >
        {isBullishClicked ? (
          <ThumbUpAltIcon sx={{ mr: 1 }} />
        ) : (
          <ThumbUpOffAltIcon sx={{ mr: 1 }} />
        )}

        {bullishCounting}
      </MDButton>
      <MDButton
        disabled={disabled || createdByUserId === userInfo?.id}
        variant="button"
        sx={buttonStyle(darkMode, { fontSize: "12px", color: "#F44335 !important" })}
        onClick={() => handleBearishClick(commentId)}
      >
        {isBearishClicked ? (
          <ThumbDownAltIcon sx={{ mr: 1 }} />
        ) : (
          <ThumbDownOffAltIcon sx={{ mr: 1 }} />
        )}

        {bearishCounting}
      </MDButton>
      {isParentComment && (
        <MDButton
          disabled={disabled}
          variant="button"
          sx={buttonStyle(darkMode, {
            fontSize: "12px",
            color: "#1A73E8 !important",
            opacity: 0.8,
          })}
          onClick={() => handleClickReply(commentId)}
        >
          <ReplyIcon sx={{ mr: 1 }} />
          Reply
        </MDButton>
      )}
      {hasThreads && (
        <MDButton
          disabled={disabled}
          variant="button"
          onClick={() => toggleThreadVisibility(commentId)}
          sx={buttonStyle(darkMode, {
            fontSize: "12px",
            color: "#1A73E8 !important",
            opacity: 0.8,
          })}
        >
          {expandedComments[commentId] ? "Hide Replies" : `View Replies`}
        </MDButton>
      )}
      {disabled || createdByUserId !== userInfo?.id ? null : (
        <MDButton
          disabled={disabled}
          variant="button"
          sx={buttonStyle(darkMode, { color: "inherit !important", marginLeft: "-8px" })}
          onClick={handleClickMenu}
        >
          <MoreHorizIcon fontSize="small" />
        </MDButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          handleCloseMenu();
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleEditClick(commentId);
          }}
        >
          <EditOutlinedIcon sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenDialog}>
          <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
      <MDBox>
        <Dialog fullScreen={fullScreen} open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle
            sx={{
              m: 0,
              p: 0,
              paddingLeft: "16px",
              paddingTop: "8px",
              paddingBottom: "8px",
              background: darkMode ? "#1A1F2B" : "linear-gradient(195deg, #42424a, #191919)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h5" fontWeight="medium" sx={{ color: "#ffffffcc" }}>
              Delete Comment?
            </MDTypography>
          </DialogTitle>
          <DialogContent
            sx={{
              color: "inherit",
              borderTop: "1px solid gray",
              background: darkMode ? "#1A1F2B" : "inherit",
            }}
          >
            <DialogContentText>
              <MDTypography
                variant="button"
                fontSize="14px"
                fontWeight="regular"
                sx={{
                  display: "block",
                  pt: "8px",
                }}
              >
                Are you sure you want to delete your comment? You cannot undo it once it has been
                deleted!
              </MDTypography>
            </DialogContentText>
            <DialogActions sx={{ display: "flex", m: 0, p: 0 }}>
              <MDButton sx={{ m: 0, p: 0 }} variant="text" onClick={handleCloseDialog} color="info">
                Cancel
              </MDButton>
              <MDButton
                sx={{ m: 0, p: 0 }}
                variant="text"
                color="error"
                onClick={() => {
                  handleDeleteClick(commentId);
                  handleDeleteComment(commentId);
                  handleCloseDialog();
                }}
              >
                Delete
              </MDButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </MDBox>
    </>
  );
};

export const CommentInputBar = ({
  autofocus = false,
  darkMode,
  handleCancelReply,
  commentId,
  handleSubmit,
  additionalStyles,
  isEditing,
  currentComment,
  onChange,
  rows = 2,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  React.useEffect(() => {
    if (isEditing && currentComment) {
      setInputValue(currentComment);
    }
  }, [isEditing, currentComment]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <MDInput
      multiline
      minrows={1}
      maxRows={10}
      rows={rows}
      placeholder={disabled ? "Login to write a comment" : "Write a comment..."}
      fullWidth
      value={inputValue}
      onChange={handleChange}
      autoFocus={autofocus}
      disabled={disabled}
      sx={{
        mt: 1,
        backgroundColor: darkMode ? "#131722" : "light",
        "& fieldset": {
          border: "1.3px solid #5D697C !important",
        },
        ...additionalStyles,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginBottom: 3 }}>
            <MDButton
              variant="button"
              sx={{
                color: darkMode ? "gray" : "inherit",
                background: "transparent",
                textTransform: "none",
                display: inputValue ? "block" : "none",
                position: "absolute",
                bottom: 8,
                right: 70,
                padding: "9px 0px",
              }}
              onClick={() => handleCancelReply(commentId, setInputValue)}
              className="submit-button"
            >
              Cancel
            </MDButton>
            <MDButton
              variant="button"
              sx={{
                color: darkMode ? "#FFFFFF" : "inherit",
                backgroundColor: darkMode ? "#1A1F2B !important" : "light",
                textTransform: "none",
                display: inputValue ? "block" : "none",
                position: "absolute",
                padding: "9px 0px",
                bottom: 8,
                right: 8,
              }}
              className="submit-button"
              onClick={() => handleSubmit(commentId, inputValue, setInputValue)}
            >
              {isEditing ? "Save" : "Submit"}
            </MDButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

CommentButtons.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  comment: PropTypes.string,
  bullishCount: PropTypes.number.isRequired,
  bearishCount: PropTypes.number.isRequired,
  commentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  expandedComments: PropTypes.object.isRequired,
  toggleThreadVisibility: PropTypes.func.isRequired,
  handleClickReply: PropTypes.func.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  hasThreads: PropTypes.bool.isRequired,
  isParentComment: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  createdByUserId: PropTypes.bool,
};
CommentInputBar.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  handleCancelReply: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  commentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  additionalStyles: PropTypes.object,
  autofocus: PropTypes.bool,
  rows: PropTypes.number,
  isEditing: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  currentComment: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
