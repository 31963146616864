/* eslint-disable */

import React, { useEffect, useState, useRef } from "react";
import { socket } from "socket";
import Draggable from "react-draggable";
import { useRecoilState } from "recoil";

// import Datafeed from "../../tradingView/datafeed";
import { widget } from "../../charting_library";
import MDBox from "components/MDBox";
import HeightIcon from "@mui/icons-material/Height";

import { parseFullSymbol } from "tradingView/helpers.js";
// import { subscribeOnStream, unsubscribeFromStream } from "tradingView/streaming.js";
import {
  getFrontAndBackOfDecimal,
  formatPriceWithDecimals,
  separateLeadingZeros,
} from "utils/price";
import { chartFilterByAddressAtom } from "atoms";
import "./index.css";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const channelToSubscription = new Map();
const initialHeight = 500;
const minHeight = 400;

const TradingViewChart = ({
  pairAddress,
  symbol,
  price = 0,
  darkMode = true,
  isOnline = true,
  lastTradeTime,
}) => {
  let volumeAccumulator = 0;

  const [chartFilterByAddress] = useRecoilState(chartFilterByAddressAtom);
  const [dragHeight, setDragHeight] = useState(10);
  const [latestPrice, setLatestPrice] = useState(price);
  const [isDragging, setIsDragging] = useState(false);
  const [iframeHeight, setIframeHeight] = useState(initialHeight);
  const [tvWidget, setTvWidget] = useState(null);
  const [interval, setInterval] = useState("30");
  const lastBarsCache = new Map();
  const filteredTrades = chartFilterByAddress?.trades?.map((t) => {
    const isSell = t.type === "sell";
    return {
      id: t.blockNumber,
      price: t.price,
      time: t.timestamp,
      color: isSell ? "red" : "green",
      text: `${isSell ? "Sell" : "Buy"} at $${formatPriceWithDecimals(t.price)}`,
      label: isSell ? "S" : "B",
      labelFontColor: "#f5f5f5",
      minSize: 5,
    };
  });

  useEffect(() => {
    if (price !== 0 && latestPrice === 0) {
      setLatestPrice(price);
    }
  }, [price]);

  const shapesRef = useRef("");

  function loadResolutionFromLocalStorage() {
    return localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution") || "30";
  }

  const handleDrag = (_, { deltaY }) => {
    setIframeHeight(iframeHeight + deltaY);
    setIsDragging(true);
  };

  const handleStop = () => {
    setIsDragging(false);
  };

  function getAllSymbols() {
    return [
      {
        symbol: symbol,
        full_name: `${pairAddress}:${symbol}`,
        description: symbol,
        exchange: "0xHub.io",
        type: "crypto",
      },
    ];
  }

  async function makeApiRequest(query = "") {
    try {
      const response = await fetch(`${API_ENDPOINT}/api/token/${pairAddress}/chart?${query}`);
      const data = await response.json(); // Wait for the JSON parsing
      return {
        Data: data,
      };
    } catch (error) {
      throw new Error(`Chart request error: ${error.status}`);
    }
  }

  const dataFeed = {
    onReady: (callback) => {
      setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
      const symbols = await getAllSymbols();
      const newSymbols = symbols.filter((symbol) => {
        const isExchangeValid = exchange === "" || symbol.exchange === exchange;
        const isFullSymbolContainsInput =
          symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
        return isExchangeValid && isFullSymbolContainsInput;
      });
      onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
      const symbols = await getAllSymbols();
      const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
      if (!symbolItem) {
        onResolveErrorCallback("cannot resolve symbol");
        return;
      }

      const tokenPrice = price || latestPrice || 0;
      const priceScaleValues = calculatePriceScale(tokenPrice) || {};

      const priceScale = priceScaleValues?.precision ?? 1000000000;
      const minmov = priceScaleValues?.minmov ?? 10;

      const symbolInfo = {
        ticker: symbolItem.full_name,
        name: symbolItem.symbol,
        description: symbolItem.description,
        type: symbolItem.type,
        session: "24x7",
        timezone: "Etc/UTC",
        exchange: symbolItem.exchange,
        pricescale: priceScale,
        minmov: minmov,
        has_intraday: true,
        has_no_volume: false,
        has_weekly_and_monthly: false,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: "streaming",
      };
      onSymbolResolvedCallback(symbolInfo);
    },

    getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
      // Filter data based on the date range
      const filteredMarks = filteredTrades;

      // Convert filtered marks to the format expected by TradingView
      const formattedMarks = filteredMarks.map((mark) => ({
        id: mark.id.toString(),
        time: mark.time,
        color: mark.color,
        text: mark.text,
        label: mark.label,
        labelFontColor: mark.labelFontColor,
        minSize: mark.minSize,
      }));
      // Send the marks to TradingView using onDataCallback
      onDataCallback(formattedMarks);
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      const { from, to, firstDataRequest } = periodParams;
      let defaultBarsCount = 20000;
      if (resolution === "1") {
        defaultBarsCount = 100000;
      } else if (resolution === "3") {
        defaultBarsCount = 80000;
      } else if (resolution === "5") {
        defaultBarsCount = 75000;
      } else if (resolution === "15") {
        defaultBarsCount = 55000;
      } else if (resolution === "30") {
        defaultBarsCount = 45000;
      }

      let originalFrom = from;
      let originalTo = to;

      if (!firstDataRequest) {
        // Adjust the default range if it's not the first data request
        originalFrom = originalTo - defaultBarsCount * resolution;
      } else {
        originalFrom = lastTradeTime - defaultBarsCount * resolution || from;
        originalTo = lastTradeTime || to;
      }

      // Convert timestamps to seconds
      const fromTs = Math.floor(new Date(originalFrom).getTime());
      const toTs = Math.floor(new Date(originalTo).getTime());

      const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

      const getFormattedResolution = (resolution) => {
        if (["1", "3", "5", "15", "30"].includes(resolution)) {
          return `${resolution}m`;
        }
        if (["60", "120", "240", "720"].includes(resolution)) {
          return `${Number(resolution) / 60}h`;
        }
        if (["60", "120", "240", "720"].includes(resolution)) {
          return `${Number(resolution) / 60}h`;
        }
        return resolution?.toLowerCase();
      };
      const urlParameters = {
        pairAddress: pairAddress,
        fsym: parsedSymbol.fromSymbol,
        tsym: parsedSymbol.toSymbol,
        fromTs: fromTs,
        toTs: toTs,
        limit: defaultBarsCount,
        interval: getFormattedResolution(resolution),
      };

      const query = Object.keys(urlParameters)
        .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
        .join("&");

      try {
        const data = await makeApiRequest(`${query}`);
        if ((data.Response && data.Response === "Error") || data.Data.length === 0) {
          onHistoryCallback([], { noData: true });
          return;
        }

        let maxPrice = localStorage.getItem(`${pairAddress}-maxprice`) || data.Data[0].high;
        let minPrice = localStorage.getItem(`${pairAddress}-minprice`) || data.Data[0].low;

        let bars = [];

        data.Data.forEach((bar) => {
          if (bar.high > maxPrice) {
            maxPrice = bar.high;
          }
          if (bar.low < minPrice) {
            minPrice = bar.low;
          }
          bars.push({
            time: bar.time * 1000, // Convert time to milliseconds
            low: bar.low,
            high: bar.high,
            open: bar.open,
            close: bar.close,
            volume: bar.volume,
          });
        });

        try {
          // Set max and min prices in local storage
          localStorage.setItem(`${pairAddress}-maxprice`, formatPriceWithDecimals(maxPrice));
          localStorage.setItem(`${pairAddress}-minprice`, formatPriceWithDecimals(minPrice));
        } catch (e) {
          //
        }
        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, {
            ...bars[bars.length - 1],
          });
        }

        onHistoryCallback(bars, { noData: false });
      } catch (error) {
        onErrorCallback(error);
      }
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
      subscribeOnStream(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback,
        lastBarsCache.get(symbolInfo.full_name)
      );
    },

    unsubscribeBars: (subscriberUID) => {
      unsubscribeFromStream(subscriberUID);
    },
  };

  const handleSubscription = async (data) => {
    const { pair, price, tokensTransferred, tradeTime } = data;
    const channelString = `0xhub-${pair}`;
    const tradePrice = price;
    // Calculate volume for the trade
    const tradeVolume = tokensTransferred || 0;

    // Accumulate volume
    volumeAccumulator += tradeVolume;

    const subscriptionItem = channelToSubscription.get(channelString);
    if (subscriptionItem === undefined) {
      return;
    }

    const lastDailyBar = subscriptionItem.lastDailyBar;
    const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

    let bar;
    if (tradeTime >= nextDailyBarTime) {
      bar = {
        time: nextDailyBarTime,
        open: tradePrice,
        high: tradePrice,
        low: tradePrice,
        close: tradePrice,
        volume: volumeAccumulator,
      };
      // Reset volume accumulator for the new day
      volumeAccumulator = 0;
    } else {
      bar = {
        ...lastDailyBar,
        high: Math.max(lastDailyBar.high, tradePrice),
        low: Math.min(lastDailyBar.low, tradePrice),
        close: tradePrice,
        volume: volumeAccumulator,
      };
    }
    subscriptionItem.lastDailyBar = bar;

    // send data to every subscriber of that symbol
    subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
  };

  useEffect(() => {
    socket.on(`0xhub-${pairAddress}`, handleSubscription);
    // Remove max and min prices from local storage
    localStorage.removeItem(`${pairAddress}-maxprice`);
    localStorage.removeItem(`${pairAddress}-minprice`);
  }, []);

  useEffect(() => {
    let tvWidget;

    const initializeChart = async () => {
      // Create a new TradingView widget
      tvWidget = new TradingView.widget({
        symbol: `${pairAddress}:${symbol}`,
        interval: loadResolutionFromLocalStorage(),
        width: "100%",
        theme: !darkMode ? "Light" : "Dark",
        container: "tv_chart_container",
        datafeed: dataFeed,
        library_path: process.env.PUBLIC_URL + "/charting_library/",
        disabled_features: ["header_symbol_search", "symbol_search_hot_key"],
        enabled_features: ["hide_left_toolbar_by_default"],
      });
      tvWidget.onChartReady(() => {
        tvWidget.changeTheme(!darkMode ? "Light" : "Dark");
        tvWidget.headerReady().then(() => {
          const chart = tvWidget.chart();
          // Set resolution last used value read from localStorage
          const savedResolution = loadResolutionFromLocalStorage();
          if (savedResolution) {
            chart.setResolution(savedResolution);
          }

          // Function to append a custom header element to the chart container
          const maxPrice = localStorage.getItem(`${pairAddress}-maxprice`);
          const minPrice = localStorage.getItem(`${pairAddress}-minprice`);

          function appendCustomHeader(container, textColor) {
            // Clear existing custom header if it exists
            const existingHeader = document.getElementById("custom_header");
            if (existingHeader) {
              existingHeader.remove();
            }

            // Create a custom header element
            const customHeader = document.createElement("div");
            customHeader.id = "custom_header";
            customHeader.style = "background: transparent;";
            customHeader.innerHTML = `
            <div style="display: flex; align-items: center; justify-content: space-between;">
              ${
                maxPrice.length > 12
                  ? ""
                  : `<div style="padding: 0px 4px; display: inline-block;"><b>${symbol}</b></div>`
              }
              <div style="display: inline-block">
                <div style="padding: 0px 4px; display: inline-block; color: #36BB91"><b>Max</b>: $${maxPrice} </div>
                <div style="padding: 0px 4px; display: inline-block">|</div>
                <div style="padding: 0px 4px; display: inline-block; color: #F44335"><b>Min</b>: $${minPrice} </div>
              </div>
            </div>`;
            customHeader.style.color = textColor; // Customize the text color
            customHeader.style.padding = "5px"; // Add padding for styling
            customHeader.style.backgroundColor = darkMode ? "#1a1f2b" : "#ffffffcc";
            customHeader.style.fontWeight = "normal";
            customHeader.style.fontSize = "12px";

            container.parentElement.insertBefore(customHeader, container);
          }

          const chartElement = document.querySelector("#tv_chart_container");
          if (chartElement && maxPrice && minPrice) {
            appendCustomHeader(chartElement, darkMode ? "#ffffffcc" : "#6c757d");
          }
        });
      });

      // Save the widget instance to the state
      setTvWidget(tvWidget);
    };

    initializeChart();

    // Do not forget to remove the script on unmounting the component!
    return () => {
      const script = document.createElement("script");
      script.type = "text/jsx";
      script.src = "%PUBLIC_URL%/charting_library/charting_library.js";
      document.head.appendChild(script);
    };
  }, [pairAddress, symbol, interval, darkMode, isOnline, latestPrice]); // eslint-disable-line

  const getShape = () => {
    return shapesRef.current;
  };

  const saveShape = (shape) => {
    shapesRef.current = shapesRef.current ? `${shapesRef.current},${shape}` : shape;
  };

  const removeAllShapes = (chart) => {
    if (chart) {
      const prevShapes = getShape() || "";
      const shapes = prevShapes.split(",");

      try {
        for (let each of shapes) {
          if (each) {
            chart.removeEntity(each);
          }
        }
      } catch (e) {
        //
      }
      shapesRef.current = "";
    }
  };

  useEffect(() => {
    // Define a variable to store the created shapes
    if (filteredTrades.length > 0 && tvWidget) {
      // Subscribe to the "onVisibleRangeChanged" event
      const updateMarks = () => {
        dataFeed.getMarks(null, null, null, (marks) => {
          try {
            // Access the TradingView chart instance
            const chart = tvWidget.chart();

            if (chart) {
              removeAllShapes(chart);
              // Add marks to the TradingView chart
              marks.forEach((mark) => {
                const shape = chart.createShape(
                  { time: mark.time, price: mark.price },
                  {
                    shape: mark.label === "S" ? "anchored_note" : "anchored_note",
                    tooltip: mark.text,
                    text: mark.text,
                    overrides:
                      mark.label === "B"
                        ? {
                            markerColor: "#36BB91",
                            textColor: "#ffffff",
                            backgroundColor: "#36BB91",
                            backgroundTransparency: 0,
                            borderColor: "#36BB91",
                            fontSize: 13,
                            fixedSize: true,
                          }
                        : {
                            markerColor: "#F44335",
                            textColor: "#ffffff",
                            backgroundColor: "#F44335",
                            backgroundTransparency: 0,
                            borderColor: "#F44335",
                            fontSize: 13,
                            fixedSize: true,
                          },
                  }
                );
                saveShape(shape);
              });
            }
          } catch (e) {
            //
          }
        });
      };
      try {
        tvWidget
          .activeChart()
          .onVisibleRangeChanged()
          .subscribe(null, ({ from, to }) => {
            // Update on chart move
            updateMarks();
          });
        // Load initial
        if (typeof dataFeed.getMarks === "function") {
          updateMarks();
        }
      } catch (e) {
        //
      }
    } else if (tvWidget && filteredTrades.length === 0) {
      if (typeof dataFeed.getMarks === "function") {
        try {
          const chart = tvWidget.chart();
          if (chart) {
            removeAllShapes(chart);
          }

          visibleRangeSubscription = tvWidget
            .activeChart()
            .onVisibleRangeChanged()
            .subscribe(null, ({ from, to }) => {
              // Access the TradingView chart instance
              if (chart) {
                removeAllShapes(chart);
              }
            });
        } catch (_e) {
          //
        }
      }
    }
  }, [filteredTrades, tvWidget]);

  return (
    <div
      className={`chart-container ${isDragging ? "dragging" : ""}`}
      style={{
        position: "relative",
      }}
    >
      <MDBox
        id="tv_chart_container"
        sx={{
          width: "100%",
          "& iframe": {
            height: `${iframeHeight}px`,
            minHeight: `${minHeight}px`,
            maxHeight: `${800}px`,
          },
          position: "relative",
        }}
      ></MDBox>
      <Draggable axis="y" onDrag={handleDrag} onStop={handleStop}>
        <div
          className="resizable-div"
          style={{
            position: "absolute",
            textAlign: "center",
            bottom: iframeHeight - initialHeight,
            // bottom: `${iframeHeight - dragHeight}px !important`,
          }}
        >
          <HeightIcon
            sx={{
              position: "absolute",
              top: -8,
              color: "#6c757d",
            }}
          />
        </div>
      </Draggable>
    </div>
  );
};

export default TradingViewChart;

const configurationData = {
  supported_resolutions: [
    "1",
    "3",
    "5",
    "15",
    "30",
    "60",
    "120",
    "240",
    "720",
    "1D",
    "3D",
    "1W",
    "1M",
  ],
  exchanges: [],
  symbols_types: [
    {
      name: "crypto",
      value: "crypto",
    },
  ],
  supports_marks: true,
  supports_timescale_marks: true,
};

/* const formatPriceWithDecimalsCustom = (price) => {
  return parseFloat(price).toFixed(10);
}; */

const calculatePriceScale = (price) => {
  try {
    const { back } = getFrontAndBackOfDecimal(price);
    const { leadingZeros } = separateLeadingZeros(back);

    // Count the number of decimal places
    const decimalCount = Math.max(0, 4 + leadingZeros?.length);

    const precision = Math.pow(10, decimalCount);
    const minmov = 10;

    return {
      precision,
      minmov,
    };
  } catch (e) {
    return {
      precision: 1000000000,
      minmov: 10,
    };
  }
};

function getNextDailyBarTime(barTime) {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
}

const subscribeOnStream = (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar
) => {
  const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
  const parts = parsedSymbol.exchange.split(":");
  const pair = parts[0];
  const channelString = `0xhub-${pair}`;
  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };

  localStorage.setItem(channelString, resolution);

  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // Already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
  } else {
    // Create a new subscription item
    subscriptionItem = {
      subscribeUID,
      resolution,
      lastDailyBar,
      handlers: [handler],
    };
    channelToSubscription.set(channelString, subscriptionItem);
  }
};

const unsubscribeFromStream = (subscriberUID) => {
  // Find a subscription with id === subscriberUID
  for (const [channelString, subscriptionItem] of channelToSubscription) {
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // Unsubscribe from the channel if it was the last handler
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
};
