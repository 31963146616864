/* eslint-disable  */

const UniswapLogo = ({ height = 20, width = 20 }) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2450_35919)">
        <rect width={`${width}`} height={`${height}`} rx="4.60595" fill="#FFFBFF"></rect>
        <rect
          width={`${width}`}
          height={`${height}`}
          rx="4.60595"
          fill="url(#paint0_linear_2450_35919)"
        ></rect>
        <g filter="url(#filter0_f_2450_35919)">
          <ellipse
            cx="9.99967"
            cy="12.0001"
            rx="9.66667"
            ry="7.66667"
            fill="url(#paint1_radial_2450_35919)"
            fill-opacity="0.8"
          ></ellipse>
        </g>
        <g filter="url(#filter1_i_2450_35919)">
          <path
            d="M14.4974 4.34199C14.5183 3.97475 14.5683 3.73252 14.6689 3.51131C14.7087 3.42374 14.746 3.35208 14.7518 3.35208C14.7575 3.35208 14.7402 3.41671 14.7133 3.49569C14.6402 3.71038 14.6282 4.00402 14.6786 4.34566C14.7425 4.77915 14.7788 4.84169 15.239 5.30995C15.4549 5.52958 15.706 5.80659 15.797 5.92551L15.9625 6.14176L15.797 5.98722C15.5946 5.79823 15.1292 5.42966 15.0263 5.37698C14.9574 5.34164 14.9472 5.34225 14.9046 5.38439C14.8655 5.42322 14.8572 5.48156 14.8518 5.75737C14.8433 6.18723 14.7844 6.46314 14.6424 6.73903C14.5656 6.88824 14.5535 6.8564 14.623 6.68797C14.6749 6.56222 14.6802 6.50694 14.6798 6.0908C14.679 5.25469 14.5793 5.05368 13.9946 4.70934C13.8465 4.62211 13.6024 4.4963 13.4522 4.42976C13.3021 4.36322 13.1828 4.30526 13.1871 4.30093C13.2037 4.28452 13.7739 4.45022 14.0034 4.53811C14.3448 4.66886 14.4012 4.6858 14.4426 4.67003C14.4704 4.65946 14.4839 4.57887 14.4974 4.34199Z"
            fill="url(#paint2_linear_2450_35919)"
          ></path>
          <path
            d="M7.28422 3.12561C7.0817 3.09439 7.07316 3.09073 7.16846 3.07618C7.3511 3.04826 7.78237 3.0863 8.07956 3.15653C8.77336 3.32041 9.40468 3.74022 10.0786 4.48582L10.2576 4.68389L10.5137 4.64298C11.5927 4.47068 12.6903 4.60761 13.6084 5.02906C13.8609 5.14501 14.2592 5.3758 14.3089 5.43511C14.3248 5.45402 14.3539 5.57569 14.3736 5.70552C14.4418 6.1547 14.4076 6.499 14.2694 6.75616C14.1941 6.8961 14.19 6.94045 14.2405 7.06021C14.2809 7.15577 14.3935 7.22651 14.5049 7.22636C14.7331 7.22605 14.9787 6.85956 15.0924 6.34963L15.1376 6.14707L15.2272 6.24785C15.7183 6.80076 16.104 7.5548 16.1703 8.09153L16.1875 8.23147L16.105 8.10426C15.9629 7.88534 15.8202 7.73632 15.6374 7.61612C15.3079 7.39947 14.9595 7.32574 14.0368 7.27742C13.2034 7.23379 12.7318 7.16305 12.2641 7.01151C11.4684 6.75372 11.0673 6.41038 10.1221 5.17811C9.70233 4.63077 9.44285 4.32794 9.18474 4.08407C8.59823 3.52993 8.02193 3.23932 7.28422 3.12561Z"
            fill="url(#paint3_linear_2450_35919)"
          ></path>
          <path
            d="M7.67762 5.77618C7.26647 5.2137 7.01209 4.3513 7.06716 3.70661L7.08419 3.50711L7.17778 3.52408C7.35352 3.55594 7.65655 3.66801 7.79844 3.75366C8.18783 3.98865 8.3564 4.29805 8.5279 5.09252C8.57814 5.32522 8.64404 5.58856 8.67437 5.67772C8.7232 5.82122 8.90769 6.15641 9.05769 6.3741C9.16573 6.53088 9.09396 6.60518 8.85514 6.58376C8.49039 6.55104 7.99634 6.21221 7.67762 5.77618Z"
            fill="url(#paint4_linear_2450_35919)"
          ></path>
          <path
            d="M13.9982 9.96242C12.0768 9.19367 11.4001 8.52639 11.4001 7.40049C11.4001 7.2348 11.4058 7.09924 11.4128 7.09924C11.4198 7.09924 11.4941 7.15392 11.578 7.22076C11.9676 7.5313 12.4039 7.66393 13.6118 7.83903C14.3225 7.94208 14.7225 8.0253 15.0915 8.1469C16.2642 8.53338 16.9898 9.3177 17.1628 10.386C17.2131 10.6964 17.1836 11.2785 17.1021 11.5853C17.0377 11.8276 16.8413 12.2644 16.7892 12.2812C16.7748 12.2858 16.7606 12.2308 16.7569 12.156C16.7372 11.7549 16.5332 11.3643 16.1906 11.0718C15.8011 10.7392 15.2777 10.4744 13.9982 9.96242Z"
            fill="url(#paint5_linear_2450_35919)"
          ></path>
          <path
            d="M12.6493 10.2818C12.6252 10.1394 12.5835 9.95763 12.5565 9.87779L12.5075 9.73264L12.5985 9.83421C12.7246 9.97475 12.8241 10.1546 12.9085 10.3942C12.973 10.577 12.9802 10.6314 12.9797 10.9285C12.9792 11.2202 12.9712 11.2813 12.9117 11.4459C12.8179 11.7054 12.7015 11.8894 12.5062 12.0869C12.1552 12.4419 11.704 12.6384 11.0528 12.7199C10.9396 12.734 10.6097 12.7579 10.3197 12.7729C9.58873 12.8107 9.10767 12.8887 8.6754 13.0395C8.61325 13.0612 8.55776 13.0744 8.55213 13.0688C8.53464 13.0515 8.82895 12.8772 9.07204 12.7608C9.41481 12.5968 9.75602 12.5072 10.5205 12.3807C10.8982 12.3182 11.2882 12.2424 11.3872 12.2122C12.3224 11.9273 12.8031 11.1919 12.6493 10.2818Z"
            fill="url(#paint6_linear_2450_35919)"
          ></path>
          <path
            d="M13.5301 11.8362C13.2748 11.2909 13.2162 10.7643 13.356 10.2733C13.371 10.2208 13.3951 10.1779 13.4095 10.1779C13.424 10.1779 13.4842 10.2103 13.5434 10.2498C13.661 10.3285 13.897 10.461 14.5255 10.8016C15.3099 11.2265 15.7571 11.5556 16.0612 11.9316C16.3276 12.2608 16.4924 12.6358 16.5717 13.0931C16.6166 13.3521 16.5903 13.9753 16.5234 14.2361C16.3125 15.0585 15.8224 15.7045 15.1233 16.0814C15.0209 16.1366 14.9289 16.182 14.919 16.1822C14.909 16.1824 14.9464 16.0881 15.0019 15.9726C15.2371 15.4841 15.2639 15.0089 15.0861 14.48C14.9772 14.1562 14.7552 13.761 14.3071 13.0932C13.786 12.3167 13.6583 12.11 13.5301 11.8362Z"
            fill="url(#paint7_linear_2450_35919)"
          ></path>
          <path
            d="M6.31312 14.7786C7.02613 14.1803 7.9133 13.7553 8.72142 13.6248C9.0697 13.5686 9.64988 13.5909 9.97238 13.673C10.4893 13.8044 10.9518 14.0989 11.1923 14.4497C11.4273 14.7926 11.5281 15.0914 11.6331 15.7561C11.6745 16.0184 11.7196 16.2817 11.7332 16.3413C11.8121 16.6859 11.9655 16.9613 12.1556 17.0996C12.4576 17.3192 12.9776 17.3329 13.4891 17.1346C13.5759 17.1009 13.6513 17.0777 13.6566 17.0829C13.6751 17.1012 13.4175 17.2726 13.2358 17.3628C12.9913 17.4841 12.7969 17.531 12.5386 17.531C12.0701 17.531 11.6811 17.2942 11.3566 16.8113C11.2927 16.7163 11.1491 16.4316 11.0376 16.1788C10.6949 15.4021 10.5257 15.1655 10.1278 14.9066C9.78156 14.6813 9.33501 14.6409 8.99908 14.8046C8.55782 15.0196 8.4347 15.58 8.75075 15.9351C8.87636 16.0762 9.1106 16.198 9.30214 16.2216C9.66047 16.2659 9.96842 15.995 9.96842 15.6355C9.96842 15.4021 9.87813 15.2689 9.65083 15.1669C9.34039 15.0277 9.0067 15.1905 9.0083 15.4803C9.00899 15.6038 9.06314 15.6814 9.1878 15.7375C9.26777 15.7734 9.26963 15.7763 9.20442 15.7628C8.91957 15.7041 8.85285 15.3629 9.08187 15.1364C9.35683 14.8644 9.92541 14.9844 10.1207 15.3556C10.2027 15.5116 10.2122 15.8221 10.1407 16.0096C9.98064 16.4293 9.51395 16.65 9.04053 16.5299C8.71821 16.4481 8.58696 16.3596 8.19835 15.9618C7.52307 15.2706 7.26092 15.1366 6.28739 14.9856L6.10084 14.9567L6.31312 14.7786Z"
            fill="url(#paint8_linear_2450_35919)"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.14462 2.40696C5.39974 5.12681 6.95295 6.24897 7.12556 6.48605C7.26807 6.68181 7.21443 6.85781 6.97029 6.99574C6.83452 7.07242 6.55538 7.15013 6.41563 7.15013C6.25755 7.15013 6.20328 7.08953 6.20328 7.08953C6.11163 7.00315 6.06001 7.01826 5.58934 6.18804C4.9359 5.18048 4.38905 4.34467 4.37413 4.33067C4.33964 4.29831 4.34023 4.2994 5.52271 6.40187C5.71377 6.84008 5.56071 7.00093 5.56071 7.06334C5.56071 7.1903 5.52586 7.25704 5.36825 7.43173C5.1055 7.72301 4.98805 8.05029 4.90326 8.72762C4.80821 9.4869 4.54094 10.0233 3.80024 10.9412C3.36666 11.4785 3.29572 11.577 3.18631 11.7936C3.04851 12.0663 3.01062 12.2191 2.99526 12.5635C2.97903 12.9276 3.01064 13.1628 3.12259 13.511C3.2206 13.8158 3.32291 14.017 3.58444 14.4196C3.81014 14.767 3.9401 15.0251 3.9401 15.1261C3.9401 15.2064 3.95554 15.2065 4.30528 15.1281C5.14226 14.9402 5.82189 14.6099 6.20412 14.2051C6.44068 13.9545 6.49622 13.8162 6.49802 13.4728C6.4992 13.2482 6.49125 13.2012 6.43016 13.072C6.33071 12.8617 6.14967 12.6869 5.75064 12.4158C5.2278 12.0606 5.00448 11.7747 4.9428 11.3815C4.8922 11.0588 4.9509 10.8312 5.24014 10.2288C5.53952 9.60524 5.61371 9.33953 5.66389 8.71103C5.69631 8.30498 5.74119 8.14484 5.8586 8.0163C5.98104 7.88226 6.09127 7.83687 6.3943 7.79573C6.88833 7.72866 7.20291 7.60165 7.46149 7.36487C7.6858 7.15946 7.77966 6.96154 7.79407 6.6636L7.805 6.43776L7.67965 6.29253C7.2257 5.76656 2.84061 1.9935 2.81267 1.9935C2.8067 1.9935 2.95609 2.17957 3.14462 2.40696ZM4.19493 12.9755C4.29756 12.7949 4.24303 12.5627 4.07135 12.4492C3.90913 12.342 3.65714 12.3925 3.65714 12.5322C3.65714 12.5748 3.68086 12.6058 3.73431 12.6332C3.82432 12.6792 3.83085 12.731 3.76003 12.8367C3.68832 12.9439 3.6941 13.038 3.77637 13.102C3.90895 13.2052 4.09663 13.1485 4.19493 12.9755Z"
            fill="url(#paint9_linear_2450_35919)"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.11678 7.91419C7.88485 7.98498 7.6594 8.22923 7.58962 8.48535C7.54704 8.6416 7.5712 8.91567 7.63497 9.00032C7.73799 9.13703 7.83762 9.17306 8.10739 9.17117C8.63556 9.16751 9.0947 8.94241 9.14808 8.66101C9.19184 8.43034 8.99019 8.11067 8.71242 7.97032C8.56908 7.89793 8.26425 7.86921 8.11678 7.91419ZM8.7342 8.39395C8.81565 8.27895 8.78002 8.15466 8.64149 8.07059C8.3777 7.91053 7.97877 8.04299 7.97877 8.29063C7.97877 8.4139 8.1868 8.5484 8.37749 8.5484C8.50441 8.5484 8.6781 8.47319 8.7342 8.39395Z"
            fill="url(#paint10_linear_2450_35919)"
          ></path>
        </g>
      </g>
      <rect
        x="0.0205078"
        y="0.0205078"
        width="19.959"
        height="19.959"
        rx="4.58544"
        stroke="black"
        stroke-opacity="0.1"
        stroke-width="0.0410156"
      ></rect>
      <defs>
        <filter
          id="filter0_f_2450_35919"
          x="-3.16699"
          y="0.833389"
          width="26.333"
          height="22.3333"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            stdDeviation="1.75"
            result="effect1_foregroundBlur_2450_35919"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_i_2450_35919"
          x="2.8125"
          y="1.9935"
          width="14.375"
          height="15.5376"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="0.47168"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.864916 0 0 0 0 0.401042 0 0 0 0 0.875 0 0 0 1 0"
          ></feColorMatrix>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2450_35919"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_2450_35919"
          x1="7.66667"
          y1="20"
          x2="7.66667"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF1FF"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint1_radial_2450_35919"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.0123 11.9901) rotate(35.9783) scale(9.71138 9.26994)"
        >
          <stop stop-color="white"></stop>
          <stop offset="1" stop-color="#FFE3FF"></stop>
        </radialGradient>
        <linearGradient
          id="paint2_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_2450_35919"
          x1="16.4648"
          y1="15.099"
          x2="7.33333"
          y2="7.33334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC72FF"></stop>
          <stop offset="1" stop-color="#FF41F4"></stop>
        </linearGradient>
        <clipPath id="clip0_2450_35919">
          <rect width={`${width}`} height={`${height}`} rx="4.60595" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default UniswapLogo;
