/* eslint-disable react/prop-types */

import { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import MDAvatar from "components/MDAvatar";
import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutline";
import TrashIcon from "@mui/icons-material/DeleteOutline";

// @mui material components
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import useApi from "utils/useApi";
import useLogin from "utils/useLogin";
import useTrackEvent from "utils/useTrackEvent";
import { numPrettyFormatter } from "utils/price";
import { useMaterialUIController, setOpenMobileFavorite } from "context";
import { truncate } from "lodash";
import { getChainForUrl } from "utils/chain";

const favStorageKey = "0xhub-favorites";
const maxBookmarkSize = 20;

const FavoriteList = ({
  showHeader = true,
  currentProjectId,
  currentProjectName,
  currentPair,
  currentProject = {},
  logo = "",
  showOnlyStar = false,
  realTimePrice,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { trackEvent } = useTrackEvent();
  const { userInfo } = useLogin("");
  const { fetchMemeDefiDBFavoriteProjectsByPairList } = useApi();
  const [hovered, setHovered] = useState("");
  const [favoriteList, setFavoriteList] = useState([]);
  const { openMobileFavorite } = controller;

  const getAllFavorites = () => {
    const favData = localStorage.getItem(favStorageKey);
    return JSON.parse(favData);
  };

  const getLogoFromStorage = (pairAddress) => {
    const currentFav = getAllFavorites() || [];
    if (currentFav.length === 0) {
      return "";
    }
    return currentFav.find((each) => each.pair === pairAddress)?.logo ?? "";
  };

  const { data: favoriteDataRes = [], refetch } = useQuery(
    `fetchMemeDefiDBFavoriteProjectsByPairList`,
    () => fetchMemeDefiDBFavoriteProjectsByPairList(getAllFavorites()?.map((each) => each.pair)),
    {
      retry: false,
      enabled: false,
    }
  );

  useEffect(() => {
    refetch()
      .then((res) => {
        const favData = res.data?.data ?? [];
        if (favData.length) {
          setFavoriteList(favData);
        }
      })
      .catch((e) => {
        //
      });
  }, []);

  const currentProjectInfo = {
    chain: currentProject?.chain,
    contract_address: currentProject?.address,
    logo: logo,
    marketcap: currentProject?.stats?.fullyDilutedMarketCap ?? 0,
    name: currentProject?.name,
    oneDayChange: currentProject?.stats?.prices?.day_1?.prettyPrint,
    pairSymbol: currentProject?.pairSymbol,
    pool_address: currentProject?.pool_address,
    price: realTimePrice,
    symbol: currentProject?.symbol,
    totalSupply: currentProject?.stats?.supply,
  };

  const addFavorites = (pairToAdd, logo) => {
    if (favoriteList.length >= maxBookmarkSize || !pairToAdd) {
      return;
    }
    trackEvent({
      projectId: currentProjectId,
      eventName: "favorite",
      userId: userInfo?.id,
    });
    // Get the current favorites from localStorage
    const currentFav = getAllFavorites() || [];
    // Check if the pairToAdd already exists in the favorites
    const isPairAlreadyAdded = currentFav.some((pairObj) => pairObj.pair === pairToAdd);
    // If the pairToAdd doesn't exist, add it to the favorites
    if (!isPairAlreadyAdded) {
      const newList = JSON.stringify([{ pair: pairToAdd, logo: logo }, ...currentFav]);
      localStorage.setItem(favStorageKey, newList);
      setFavoriteList([currentProjectInfo, ...favoriteList]);
    }
  };
  const removeFavorites = (pairToRemove) => {
    const currentFav = getAllFavorites() || [];
    const removedList = currentFav.filter((pairObj) => pairObj.pair !== pairToRemove);
    const newList = JSON.stringify(removedList);
    localStorage.setItem(favStorageKey, newList);
    setFavoriteList(favoriteList.filter((each) => each.pool_address !== currentPair));
  };

  const handleMouseEnter = (address) => {
    setHovered(address);
  };

  const handleMouseLeave = () => {
    setHovered("");
  };

  const handleDeleteClick = (pairToDelete) => {
    // Implement your logic for deleting the item
    removeFavorites(pairToDelete);
  };

  const isCurrentProjectInFavList = favoriteList.some((each) => each.pool_address == currentPair);

  if (showOnlyStar) {
    return (
      <CardHeader
        title={
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MDTypography
              variant="button"
              fontWeight="small"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {isCurrentProjectInFavList ? (
                <Tooltip
                  title={`Click to remove ${currentProjectName} from your watchlist`}
                  placement="top"
                >
                  <StarIcon
                    fontSize="small"
                    color="info"
                    onClick={() => removeFavorites(currentPair)}
                    sx={{ cursor: "pointer" }}
                  />
                </Tooltip>
              ) : (
                <>
                  {favoriteList?.length < maxBookmarkSize ? (
                    <Tooltip
                      title={`Click to add ${currentProjectName} to your watchlist`}
                      placement="top"
                    >
                      <StarOutlinedIcon
                        fontSize="small"
                        onClick={() => addFavorites(currentPair, logo)}
                        color="default"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={`You have reached the limit of ${maxBookmarkSize} projects in watchlist!`}
                      placement="top"
                    >
                      <StarOutlinedIcon fontSize="medium" htmlColor="#aaa" />
                    </Tooltip>
                  )}
                </>
              )}
            </MDTypography>
          </MDBox>
        }
        sx={{
          padding: "0px",
          paddingBottom: "0px",
        }}
      />
    );
  }

  return (
    <Card
      sx={{
        "&.MuiPaper-root": {
          padding: "2px",
        },
      }}
      elevation={0}
    >
      {showHeader && (
        <CardHeader
          title={
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <MDTypography
                variant="button"
                fontWeight="medium"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Watchlist&nbsp; ({favoriteList.length})
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="medium"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {isCurrentProjectInFavList ? (
                  <Tooltip
                    title={`Click to remove ${currentProjectName} from your watchlist`}
                    placement="top"
                  >
                    <StarIcon
                      fontSize="medium"
                      color="info"
                      onClick={() => removeFavorites(currentPair)}
                      sx={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                ) : (
                  <>
                    {favoriteList?.length < maxBookmarkSize ? (
                      <Tooltip
                        title={`Click to add ${currentProjectName} to your watchlist`}
                        placement="top"
                      >
                        <StarOutlinedIcon
                          fontSize="medium"
                          onClick={() => addFavorites(currentPair, logo)}
                          color="default"
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={`You have reached the limit of ${maxBookmarkSize} projects in watchlist!`}
                        placement="top"
                      >
                        <StarOutlinedIcon fontSize="medium" htmlColor="#aaa" />
                      </Tooltip>
                    )}
                  </>
                )}
              </MDTypography>
            </MDBox>
          }
          sx={{
            padding: "8px",
            paddingBottom: "8px",
          }}
        />
      )}
      <Grid container spacing={0}>
        {favoriteList.map(
          ({ logo, symbol, chain, oneDayChange, pool_address, marketcap, totalSupply }) => {
            return (
              <Grid item xs={12} key={pool_address}>
                <Card
                  onMouseEnter={() => handleMouseEnter(pool_address)}
                  onMouseLeave={handleMouseLeave}
                  elevation={0}
                  sx={{
                    marginBottom: "6px",
                    marginLeft: "6px",
                    marginRight: "6px",
                    border: "1px solid #344767",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                  >
                    <Grid xs={5}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        component={NavLink}
                        onClick={() => dispatch && setOpenMobileFavorite(dispatch, false)}
                        to={`/${getChainForUrl(chain)}/${pool_address}`}
                      >
                        <MDAvatar
                          src={logo || getLogoFromStorage(pool_address)}
                          size="sm"
                          sx={{
                            marginLeft: "0px",
                            border: `1px solid ${
                              logo || getLogoFromStorage(pool_address) ? "#0A0A0A" : "#aaa"
                            }`,
                          }}
                        >
                          {symbol?.[0]}
                        </MDAvatar>
                        <MDTypography
                          component="text"
                          variant="button"
                          fontWeight="medium"
                          fontSize="12px"
                          ml={"8px"}
                        >
                          {truncate(symbol, {
                            length: 14,
                          })}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid xs={4} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                      <div>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color={oneDayChange?.[0] === "+" ? "success" : "error"}
                          >
                            {oneDayChange}
                          </MDTypography>
                        </MDTypography>
                      </div>
                    </Grid>
                    <Grid xs={3} display={"flex"} justifyContent={"flex-end"}>
                      <MDBox
                        sx={{
                          position: "relative",
                          right: "4px",
                        }}
                      >
                        {hovered === pool_address ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(pool_address)}
                          >
                            <TrashIcon
                              color="error"
                              fontSize="small"
                              sx={{
                                padding: "2px",
                                marginRight: "8px",
                                position: "relative",
                                top: "4px",
                              }}
                            />
                          </div>
                        ) : (
                          <MDTypography variant="caption" color="text" fontWeight="medium">
                            <MDTypography
                              component="text"
                              variant="button"
                              fontWeight="regular"
                              fontSize="13px"
                            >
                              {calculateMcap(
                                pool_address === currentPair ? realTimePrice : 0,
                                marketcap,
                                totalSupply
                              )}
                            </MDTypography>
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            );
          }
        )}
      </Grid>
    </Card>
  );
};

FavoriteList.defaultProps = {
  projectData: {},
  holders: [],
  logo: "",
  showOnlyStar: false,
};

// Typechecking props for the FavoriteList
FavoriteList.propTypes = {
  projectData: PropTypes.any,
  logo: PropTypes.any,
  holders: PropTypes.array,
  showOnlyStar: PropTypes.boolean,
};

export default FavoriteList;

const ListContainer = ({ children, ...rest }) => (
  <MDBox {...rest} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    {children}
  </MDBox>
);

const calculateMcap = (realTimePrice, mcap, supply) => {
  if (realTimePrice) {
    return numPrettyFormatter(realTimePrice * supply);
  }
  return numPrettyFormatter(mcap);
};
