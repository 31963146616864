/* eslint-disable react/prop-types */

import React, { useMemo, useState } from "react";
import FileUpload from "react-material-file-upload";
import { toast } from "react-toastify";
import ImageGallery from "react-image-gallery";

import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Trending from "examples/Trending";
import "react-image-gallery/styles/css/image-gallery.css";

import useApi from "utils/useApi";
import { useMaterialUIController } from "context";

const discountRaw = process.env.REACT_APP_AD_DISCOUNT || "0";

const placements = [
  {
    label: "Mobile header banner",
    value: "banner",
    weight: 300,
  },
  {
    label: "Trending",
    value: "trending",
    weight: 300,
  },
  {
    label: "Project chart page",
    value: "project_chart_page",
    weight: 250,
  },
  {
    label: "Profile page",
    value: "profile",
    weight: 200,
  },
];
const durationOptions = [
  {
    label: "1 week",
    value: "1 week",
    weight: 1,
  },
  {
    label: "2 weeks",
    value: "2 weeks",
    weight: 2,
  },
  {
    label: "3 weeks",
    value: "3 weeks",
    weight: 3,
  },
  {
    label: "1 month",
    value: "1 month",
    weight: 4,
  },
];
const requiredFields = [
  "header",
  "media",
  "contact",
  "placement",
  "link",
  "duration",
  "transaction",
];

const initials = {
  header: "",
  subheader: "",
  media: "",
  description: "",
  contact: "",
  placement: [],
  link: "",
  duration: "1 week",
  transaction: "",
};

const Advertisement = () => {
  const [controller] = useMaterialUIController();
  const { createAdvertisement } = useApi();
  const [adPayload, setAdPayload] = useState(initials);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [files, setFiles] = useState([]);
  const [banners, setBanners] = useState([]);
  const [error, setError] = useState({
    header: "",
    subheader: "",
    media: "",
    contact: "",
    placement: "",
    link: "",
    duration: "",
    transaction: "",
  });
  const formData = new FormData();

  const { darkMode } = controller;
  const discountPercent = parseInt(discountRaw || "0", 10);

  const placementWeight = useMemo(() => {
    if (adPayload.placement) {
      return placements.reduce((sum, each) => {
        if (adPayload.placement.includes(each.label)) {
          return sum + each.weight;
        }
        return sum;
      }, 0);
    } else {
      return 0;
    }
  }, [adPayload.placement]);

  const durationMultiplyer =
    durationOptions.find((each) => each.label === adPayload.duration)?.weight ?? 1;

  const originalCost = placementWeight * durationMultiplyer;
  const totalDiscounts = (discountPercent / 100) * originalCost;
  const totalCost = originalCost - totalDiscounts;
  const isDiscount = originalCost > totalCost;

  const handleInputChange = (e, field) => {
    const value = e.target.value;

    if (field === "placement") {
      const placementValue = typeof value === "string" ? value.split(",") : value;
      setAdPayload({
        ...adPayload,
        placement: placementValue,
      });
      setError({
        ...error,
        placement: placementValue.length === 0 ? "Field is required" : "",
      });
      return;
    }

    setAdPayload({
      ...adPayload,
      [field]: value,
    });
    setError({
      ...error,
      [field]: requiredFields.includes(field) && !value ? "Field is required" : "",
    });
  };

  const isOk = () => {
    for (let each of requiredFields) {
      if (each === "media") {
        if (files.length === 0) {
          return false;
        }
      } else if (!adPayload[each]) {
        return false;
      }
    }
    for (const key in error) {
      if (key === "media") {
        if (files.length === 0) {
          return false;
        }
        return true;
      }
      if (error.hasOwnProperty(key) && error[key] !== "") {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    formData.append("file", files[0]);
    formData.append("file", banners[0]);
    const payload = {
      ...adPayload,
      placement: placements
        .filter((each) => {
          return adPayload.placement?.includes(each.label);
        })
        .map((each) => each.value),
      totalCost,
    };
    // Append other properties to the FormData object
    formData.append("header", payload.header);
    formData.append("subheader", payload.subheader);
    formData.append("description", payload.description);
    formData.append("contact", payload.contact);
    formData.append("placement", payload.placement.join(","));
    formData.append("link", payload.link);
    formData.append("duration", payload.duration);
    formData.append("transaction", payload.transaction);
    formData.append("totalCost", payload.totalCost);
    try {
      await createAdvertisement(formData);
      toast.success(
        "Your Ad placement request and transaction has been received successfully. We will contact you when Ads are live.",
        { autoClose: 10000 }
      );
      setAdPayload(initials);
      setError({
        ...initials,
        duration: "",
        placement: "",
      });
      setFiles([]);
      setBanners([]);
    } catch (e) {
      toast.error(
        "Your Ad placement request failed. Please try again or contact our support for further asistance.",
        { autoClose: 20000 }
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Trending />
      <MDBox py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Card>
              <MDBox mx={0} p={2} mb={0} textAlign="center">
                {discountPercent > 0 && (
                  <MDAlert
                    fontSize="14px"
                    dismissible
                    sx={{
                      margin: "0px 8px",
                      marginBottom: "8px",
                      height: "40px !important",
                      minHeight: "30px !important",
                      maxHeight: "40px !important",
                    }}
                  >
                    Limited time offer: -{discountPercent}%
                  </MDAlert>
                )}
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color={darkMode ? "white" : "#0A0A0A"}
                  mt={0}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  Advertisement Details
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  color={darkMode ? "white" : "#0A0A0A"}
                  my={1}
                >
                  Fill in the details for your advertisement. Costs in USDC is calculated
                  automatically based on the selections.
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  color={darkMode ? "white" : "#0A0A0A"}
                  my={1}
                >
                  Ads are automatically run in the next available slot. Contact{" "}
                  <a
                    href="https://t.me/OxHub_io"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#00AEC6",
                    }}
                  >
                    0xHub support
                  </a>{" "}
                  for more info.
                </MDTypography>
              </MDBox>
              <MDBox mx={0} p={0} mb={0} textAlign="center">
                {isDiscount ? (
                  <>
                    <MDTypography
                      display="block"
                      variant="h6"
                      color={darkMode ? "white" : "#0A0A0A"}
                      my={0}
                      sx={{
                        opacity: 0.9,
                      }}
                    >
                      Normal cost:{" "}
                      <span
                        style={{
                          textDecoration: "line-through",
                          textDecorationColor: "red",
                          textDecorationThickness: "2px",
                          opacity: 0.8,
                        }}
                      >
                        ${originalCost}
                      </span>
                    </MDTypography>
                    <MDTypography
                      display="block"
                      variant="h5"
                      color={darkMode ? "white" : "#0A0A0A"}
                      my={0}
                    >
                      Total cost: ${totalCost}{" "}
                      <small
                        style={{
                          fontWeight: 400,
                          opacity: 0.9,
                        }}
                      >
                        (After {discountPercent}% discounts)
                      </small>
                    </MDTypography>
                  </>
                ) : (
                  <MDTypography
                    display="block"
                    variant="h5"
                    color={darkMode ? "white" : "#0A0A0A"}
                    my={0}
                  >
                    Total cost: ${totalCost}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox pt={2} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <TextField
                      label={adPayload?.header ? "" : "Header"}
                      placeholder="Ad header / title"
                      fullWidth
                      value={adPayload.header}
                      onChange={(e) => handleInputChange(e, "header")}
                      error={!!error.header}
                      helperText={error.header}
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <TextField
                      label={adPayload?.subheader ? "" : "Subheader"}
                      placeholder="Ad description here."
                      value={adPayload.subheader}
                      fullWidth
                      onChange={(e) => handleInputChange(e, "subheader")}
                      error={!!error.subheader}
                      helperText={error.subheader}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <TextField
                      label={adPayload?.contact ? "" : "Contact person"}
                      placeholder="Contact person (eg. TG username)"
                      value={adPayload.contact}
                      fullWidth
                      onChange={(e) => handleInputChange(e, "contact")}
                      error={!!error.contact}
                      helperText={error.contact}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <TextField
                      label={adPayload?.link ? "" : "Call to action link"}
                      placeholder="Link where user goes after clicking"
                      value={adPayload.link}
                      fullWidth
                      onChange={(e) => handleInputChange(e, "link")}
                      error={!!error.link}
                      helperText={error.link}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControl sx={{ m: 0, width: "100%" }}>
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        sx={{ display: adPayload?.placement?.length ? "none" : "block" }}
                      >
                        Placement
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        sx={{
                          height: 48,
                          "&>div": {
                            height: "48px !important",
                          },
                        }}
                        value={adPayload?.placement ?? []}
                        onChange={(e) => handleInputChange(e, "placement")}
                        input={<OutlinedInput label="" />}
                        renderValue={(selected) =>
                          selected.length === 4 ? "All selected" : selected.join(", ")
                        }
                        error={!!error.placement}
                        helperText={error.placement}
                        MenuProps={MenuProps}
                      >
                        {placements.map(({ label }) => (
                          <MenuItem key={label} value={label}>
                            <Checkbox checked={adPayload?.placement?.indexOf(label) > -1} />
                            <ListItemText primary={label} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox mb={2}>
                    <TextField
                      select
                      label="Duration"
                      fullWidth
                      value={adPayload.duration}
                      onChange={(e) => handleInputChange(e, "duration")}
                      sx={{
                        ".MuiSelect-outlined": {
                          height: "44px !important",
                        },
                      }}
                      error={!!error.duration}
                      helperText={error.duration}
                    >
                      {durationOptions.map(({ label }) => (
                        <MenuItem key={label} value={label}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </MDBox>
                  <Grid container spacing={0}>
                    <Grid xs={12} sm={6} sx={{ paddingRight: "4px" }}>
                      <MDTypography
                        variant="text"
                        fontWeight="regular"
                        mb={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        Logo upload (<div style={{ fontSize: 12 }}>Required</div>)
                      </MDTypography>
                      <MDTypography
                        variant="text"
                        fontWeight="regular"
                        mt={-1}
                        mb={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ fontSize: 12 }}>Recommended size 400 x 400</div>
                      </MDTypography>
                      <MDBox
                        sx={{
                          color: "inherit",
                          "& > div": {
                            border: "1px solid gray !important",
                            padding: "8px",
                          },
                          "& svg": {
                            color: "gray !important",
                          },
                        }}
                        mb={2}
                      >
                        <FileUpload maxFiles={1} value={files} onChange={setFiles} />
                      </MDBox>
                    </Grid>
                    <Grid xs={12} sm={6} sx={{ paddingLeft: "4px" }}>
                      <MDTypography
                        variant="text"
                        fontWeight="regular"
                        mb={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        Banner upload (<div style={{ fontSize: 12 }}>Only for banner Ad</div>)
                      </MDTypography>
                      <MDTypography
                        variant="text"
                        fontWeight="regular"
                        mt={-1}
                        mb={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ fontSize: 12 }}>Recommended size 45 x 400</div>
                      </MDTypography>
                      <MDBox
                        sx={{
                          color: "inherit",
                          "& > div": {
                            border: "1px solid gray !important",
                            padding: "8px",
                          },
                          "& svg": {
                            color: "gray !important",
                          },
                        }}
                        mb={2}
                      >
                        <FileUpload
                          sx={{
                            ".MuiFormHelperText-root": {
                              color: "#344767 !important",
                            },
                            "li>div": {
                              color: "#344767 !important",
                            },
                          }}
                          maxFiles={1}
                          value={banners}
                          onChange={setBanners}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox mb={2}>
                    <TextField
                      label={adPayload?.transaction ? "" : "Payment transaction link"}
                      placeholder="Etherscan transaction link / hash"
                      value={adPayload.transaction}
                      fullWidth
                      onChange={(e) => handleInputChange(e, "transaction")}
                      error={!!error.transaction}
                      helperText={error.transaction}
                    />
                  </MDBox>

                  <MDBox
                    mt={4}
                    mb={1}
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSubmit}
                      disabled={!isOk()}
                    >
                      Submit Advertisement Form
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          {/* Ad placement info */}
          <Grid item xs={12} sm={4}>
            <MDTypography
              variant="h4"
              fontWeight="medium"
              color={darkMode ? "white" : "#0A0A0A"}
              mt={0}
              sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              Ad placement info
            </MDTypography>
            <Divider />
            <MDTypography
              variant="text"
              fontWeight="regular"
              mt={0}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              <div>
                Market your projects in 0xHub. <br />
                The ad placement bought will be run in 0xHub telegram bots as well.
              </div>
            </MDTypography>
            <MDBox>
              <ImageGallery
                items={images}
                showPlayButton={false}
                onScreenChange={(fullScreenElement) => {
                  setIsFullscreen(fullScreenElement);
                }}
                renderItem={(item) => (
                  <div className={`image-gallery-image`}>
                    <img
                      src={item.original}
                      alt=""
                      style={{
                        height: isFullscreen ? "80vh" : item.originalHeight,
                      }}
                    />
                  </div>
                )}
              />
              <Divider />
              <Grid container spacing={0}>
                {advertisementObj().map(
                  (
                    { title, description, url, height, width, available, dimensions, priceRate },
                    i
                  ) => {
                    return (
                      <Grid item xs={12} key={title} mt={1} mb={1}>
                        <MDBox
                          sx={{
                            border: "1px solid rgba(210, 214, 218, 0.4)",
                            borderRadius: "8px",
                            padding: "8px",
                          }}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <MDTypography variant="h6" fontWeight="medium" mb={1}>
                              {i + 1}. &nbsp;
                            </MDTypography>
                            <MDTypography variant="h6" fontWeight="medium" mb={1}>
                              {title}
                            </MDTypography>
                            <MDTypography
                              variant="text"
                              fontWeight="regular"
                              mb={1}
                              ml={1}
                              sx={{ fontSize: 12 }}
                            >
                              [ {available} ]
                            </MDTypography>
                          </MDTypography>
                          <MDBox
                            sx={{
                              backgroundImage: `url("${url}")`,
                              height: height,
                              backgroundSize: "cover",
                              width: width,
                              margin: "0px 0px",
                              display: "block",
                            }}
                          ></MDBox>
                          <Divider sx={{ marginBottom: 0 }} />
                          <MDTypography variant="button" fontWeight="regular" sx={{ fontSize: 13 }}>
                            <b>Description:</b>&nbsp;
                            {description}
                          </MDTypography>
                          <br />
                          <MDTypography variant="button" fontWeight="regular" sx={{ fontSize: 13 }}>
                            <b>Dimensions:</b> {dimensions}
                          </MDTypography>
                          <br />
                          <MDTypography variant="button" fontWeight="regular" sx={{ fontSize: 13 }}>
                            <b>
                              <u>Price rate:</u>
                            </b>{" "}
                            ${priceRate} per week
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    );
                  }
                )}
              </Grid>
              <br />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Advertisement;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 300,
    },
  },
};

const advertisementObj = () => {
  return [
    {
      title: "Mobile banner Ad placement",
      description: "Graphical banner displayed on mobile device.",
      url: "https://0xhub.s3.eu-west-1.amazonaws.com/mobile-screen.png",
      width: "100%",
      height: "100px",
      count: 1,
      available: "Mobile",
      dimensions: "45px height, 400px width",
      priceRate: placements.find((each) => each.label === "Mobile header banner").weight ?? "-",
    },
    {
      title: "Trending Ad placement",
      description:
        "Displayed on the right side of the trending bar. Visible on mobile and desktop devices.",
      url: "https://0xhub.s3.eu-west-1.amazonaws.com/trending-card.png",
      width: "100%",
      height: "150px",
      count: 1,
      available: "Mobile & Desktop",
      dimensions: "400px height, 400px width",
      priceRate: placements.find((each) => each.label === "Trending").weight ?? "-",
    },
    {
      title: "Text with logo Ad placement",
      description:
        "2 ad placements are available on project charting page on left and right side. On mobile, the text ad is placed below the Trading view chart.",
      url: "https://0xhub.s3.eu-west-1.amazonaws.com/token-screen.png",
      width: "100%",
      height: "100px",
      count: 3,
      available: "Mobile & Desktop",
      dimensions: "400px height, 400px width",
      priceRate: placements.find((each) => each.label === "Project chart page").weight ?? "-",
    },
  ];
};

const images = [
  {
    original: "https://0xhub.s3.eu-west-1.amazonaws.com/full-app-screen.png",
    originalHeight: 200,
  },
  {
    original: "https://0xhub.s3.eu-west-1.amazonaws.com/mobile-screen.png",
    originalHeight: 200,
  },
  {
    original: "https://0xhub.s3.eu-west-1.amazonaws.com/below-chart-screen.png",
    originalHeight: 200,
  },
  {
    original: "https://0xhub.s3.eu-west-1.amazonaws.com/token-screen.png",
    originalHeight: 200,
  },
];
