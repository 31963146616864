import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import IconStyle from "./iconStyle";
import FormDialog from "../formDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function WalletSelect() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleWalletSubmit = (walletAddress) => {
    if (!walletAddress || walletAddress.length < 10) {
      window.alert("Looks like invalid wallet. Please check again.");
      return;
    }
  };

  return (
    <div>
      <Grid container spacing={0} display="flex" justifyContent="flex-start" alignItems="center">
        <Grid item xs={6} sm={5}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="wallet-multiple-chip-label">Select wallet</InputLabel>
            <Select
              labelId="wallet-multiple-chip-label"
              id="wallet-multiple-chip"
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Select wallet" />}
              IconComponent={() => <IconStyle icon={ArrowDropDownIcon} fontSize="medium" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} color="secondary" size="small" />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
              sx={{
                height: 42,
                "&>div": {
                  height: "42px !important",
                },
              }}
            >
              {names.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ marginLeft: "8px" }}>
          <FormDialog
            buttonText={"Add Wallet"}
            title="Add new wallet"
            description="Add the new wallet address to track your assets. You do not need to connect your wallet anywhere."
            label="wallet address"
            name="wallet_address"
            actionCancelText="Cancel"
            actionMainText="Submit"
            onSubmit={(walletAddress) => handleWalletSubmit(walletAddress)}
            type="text"
            disabled={false}
            inputValidation={(wallet = "") => wallet.length > 5}
          />
        </Grid>
      </Grid>
    </div>
  );
}
