import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useMaterialUIController } from "context";

function DataTableHeadCell({ width, children, align, sortable, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          userSelect: "none",
        })}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

DataTableHeadCell.defaultProps = {
  width: "auto",
  align: "left",
};

DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(["left", "right", "center"]),
  sortable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default DataTableHeadCell;
