/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/*  */

import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import { format } from "date-fns";
import { useMaterialUIController } from "context";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";

import { chartFilterByAddressAtom } from "atoms";
import EtherscanIcon from "assets/images/etherscanLogo.png";
import { formatPriceWithDecimals, getDecimalValue } from "utils/price";
import useApi from "utils/useApi";
import { getAddressLinkByChain, getTokenTransactionLink } from "../utils";
import AddressCopy from "../addressCopyWithTooltip";
import { getScannerLogoByChain } from "utils/chain";

export default function projectData(symbol = "", currencySymbol = "", trxData = []) {
  const params = useParams();
  const [controller] = useMaterialUIController();
  const [addressFilter, setAddressFilter] = useState("");
  const setFilterByAddress = useSetRecoilState(chartFilterByAddressAtom);
  const { getPriceOfNativeETHToken } = useApi();

  const chain = params?.chain;
  const { darkMode, transparentNavbar } = controller;

  const { data = {} } = useQuery("getPriceOfNativeETHToken", () => getPriceOfNativeETHToken(), {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const ethPrice = data?.price ?? 0;
  const loweredCurrencySymbol = currencySymbol?.toLowerCase();
  const getCurrencySymbolByChain = () => {
    if (chain === "bnb") {
      if (loweredCurrencySymbol?.includes?.("bnb")) {
        return "BNB";
      } else {
        return "CURRENCY";
      }
    }
    if (chain === "polygon") {
      if (loweredCurrencySymbol?.includes?.("matic")) {
        return "WMATIC";
      } else {
        return "ETH";
      }
    }
    if (loweredCurrencySymbol?.includes?.("eth")) {
      return "ETH";
    } else {
      return "ETH";
    }
  };
  const lableName = getCurrencySymbolByChain();

  const getAmountInEth = (transaction) => {
    if (!transaction) {
      return 0;
    }
    const trxCurrencyType = transaction.backingCurrencyType || "";
    const usdValue = transaction.usd;
    if (trxCurrencyType?.toLowerCase() !== loweredCurrencySymbol) {
      return usdValue / ethPrice;
    }
    const ethAmount =
      (trxCurrencyType.toLowerCase()?.includes("eth") ||
        trxCurrencyType.toLowerCase()?.includes("bnb") ||
        trxCurrencyType.toLowerCase()?.includes("matic")) &&
      ["eth", "weth", "bnb", "wbnb", "matic", "wmatic"]?.includes(loweredCurrencySymbol)
        ? transaction.backingCurrencyValue || 0
        : usdValue / ethPrice;
    return ethAmount;
  };

  const filterByAddress = (address) => {
    if (address === addressFilter) {
      setAddressFilter("");
      setFilterByAddress({
        currentAddress: "",
        trades: [],
      });
    } else {
      setAddressFilter(address);
      const filteredTrades = trxData.filter((each) => {
        return each.origin === address;
      });
      const getTradeSummary = () => {
        let totalEth = 0;
        let hasSold = false;
        filteredTrades.forEach((transaction) => {
          const ethAmount = getAmountInEth(transaction);
          if (transaction.type === "buy") {
            totalEth -= ethAmount;
          } else if (transaction.type === "sell") {
            totalEth += ethAmount;
            hasSold = true;
          }
        });
        return {
          amount: hasSold ? totalEth : 0,
          summary: hasSold ? (totalEth > 0 ? "profit" : "loss") : "not-sold",
        };
      };
      const { amount, summary } = getTradeSummary();

      setFilterByAddress({
        currentAddress: address,
        trades: filteredTrades,
        summary,
        amount,
      });
    }
  };

  // Styles for the icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return {
    columns: [
      { label: "Date", id: "date", align: "left" },
      { label: "Type", id: "type", align: "left" },
      { label: "Price USD", id: "price_usd", align: "left" },
      { label: "Total", id: "total", align: "left" },
      { label: `Amount ${symbol}`, id: "amount", align: "left" },
      { label: lableName, id: "total_eth", align: "left" },
      { label: "Maker", id: "maker", align: "left" },
      { label: "TX", id: "trx", align: "left" },
    ],
    rows: trxData
      .filter((each) => {
        return addressFilter ? each.origin === addressFilter : true;
      })
      .map((each) => {
        return {
          date: format(new Date(each.timestamp * 1000), "MMM dd HH:mm:ss"),
          type: each.type,
          price_usd: `$${formatPriceWithDecimals(each.price)}`,
          total: `$${getDecimalValue(each.usd)}`,
          amount: getDecimalValue(each.tokensTransferred),
          total_eth: getDecimalValue(getAmountInEth(each), 4),
          maker: (
            <AddressCopy
              address={each.origin}
              fontWeight={"medium"}
              fullUrl={getAddressLinkByChain(chain, each.token, "token", each.origin)}
            />
          ),
          trx: (
            <MDBox style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
              <MDAvatar
                src={getScannerLogoByChain(chain, darkMode ? "dark" : "bright") || EtherscanIcon}
                alt="Etherscan"
                shadow="xs"
                sx={{
                  height: 18,
                  width: 18,
                  display: "inline-block",
                  cursor: "pointer",
                  background: "#FFF",
                  marginRight: "2px",
                }}
                onClick={() => window.open(getTokenTransactionLink(chain, each.tx), "_blank")}
              />
              {addressFilter ? (
                <CloseIcon
                  fontSize="small"
                  onClick={() => filterByAddress(each.origin)}
                  sx={iconsStyle}
                />
              ) : (
                <FilterAltIcon
                  fontSize="small"
                  onClick={() => filterByAddress(each.origin)}
                  sx={iconsStyle}
                />
              )}
            </MDBox>
          ),
        };
      }),
  };
}
