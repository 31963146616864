import useApi from "utils/useApi";

// Define the allowed event names
export const allowedEventNames = [
  "vote_positive",
  "vote_negative",
  "etherscan",
  "social",
  "page_visit",
  "favorite",
];

function useTrackEvent() {
  const { createEventForProject } = useApi();

  const trackEvent = async ({
    projectId,
    eventName,
    eventType = "click",
    eventValue = 1,
    userId = null,
  }) => {
    const payload = {
      projectId,
      eventType: eventType,
      eventName: eventName,
      eventValue: eventValue,
      userId: userId,
    };

    if (!projectId) {
      return;
    }

    if (!allowedEventNames.includes(eventName)) {
      return;
    }

    try {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(1);
        }, 200);
      });
      await createEventForProject(projectId, payload);
    } catch (e) {
      //
    }
  };

  return {
    trackEvent,
  };
}

export default useTrackEvent;
