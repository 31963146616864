/* eslint-disable react/prop-types */

import Tooltip from "@mui/material/Tooltip";

const TooltipWrapper = ({ title, showTooltip = false, children }) => {
  if (!showTooltip) {
    return children;
  }
  return (
    <Tooltip title={title} placement="top">
      {children}
    </Tooltip>
  );
};

export default TooltipWrapper;
