import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// 0xHub Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Trending from "examples/Trending";

import useProjectData from "layouts/dashboard/components/Projects/data";
import { projectAtom } from "atoms";

const LosersView = () => {
  const projectData = useRecoilValue(projectAtom);
  const losersProjects = projectData?.losersProjects ?? [];

  const { columns, rows } = useProjectData(losersProjects);

  const { pathname } = useLocation();
  const titleKey = pathname.replace("/", "");
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Trending />
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                // coloredShadow="info"
                bgColor="inherit"
              >
                <MDTypography variant="h6" color="text" style={displayFlexCenter}>
                  <MDTypography fontWeight="bold">{getTitle()[titleKey]}</MDTypography>
                  <MDTypography
                    component="div"
                    variant="button"
                    fontWeight="bold"
                    color={"success"}
                    sx={{ paddingTop: "6px", marginLeft: "4px" }}
                  >
                    {getIcon()[titleKey]}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <hr style={{ border: "1px solid inherit" }} />
              <MDBox pt={3}>
                {losersProjects?.length === 0 && (
                  <MDBox
                    position="relative"
                    width="100%"
                    minHeight="20vh"
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    Data not available right now.
                  </MDBox>
                )}
                {losersProjects?.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default LosersView;

const getTitle = () => ({
  gainers: "Gainers",
  losers: "Losers",
  recent: "Recently Added",
  trending: "Trending",
});

const getIcon = () => ({
  gainers: (
    <Icon fontSize="small" color="success">
      moving
    </Icon>
  ),
  losers: (
    <Icon fontSize="small" color="error">
      trending_down
    </Icon>
  ),
  recent: (
    <Icon fontSize="small" color="info">
      stroller
    </Icon>
  ),
  trending: (
    <Icon fontSize="small" color="error">
      whatshot
    </Icon>
  ),
});

const displayFlexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};
