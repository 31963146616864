import { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";

const useMobileView = () => {
  const [mobileView, setMobileView] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the display state for the mobileview.
    function adjustMobileView() {
      if (window.innerWidth <= breakpoints.values.sm) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.xs
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    const runResize = () => {
      handleTabsOrientation();
      adjustMobileView();
    };

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", runResize);

    runResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", runResize);
  }, [tabsOrientation]);

  return {
    mobileView,
    tabsOrientation,
  };
};

export default useMobileView;
