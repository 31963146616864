/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/*  */

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import useDashboardData from "layouts/dashboard/components/Projects/data";

function Projects({ projects = [], isLoading = false }) {
  const { columns, rows } = useDashboardData(projects);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Popular Tokens
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable table={{ columns, rows }} isLoading={isLoading} />
      </MDBox>
    </Card>
  );
}

export default Projects;
