/* eslint-disable react/prop-types */

import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ConfiguratorRoot from "layouts/project/rightDrawer";
import FavoriteList from "layouts/project/FavoriteList";
import { useMaterialUIController, setOpenMobileFavorite } from "context";

const FavoriteSidebar = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, openMobileFavorite } = controller;

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator: openMobileFavorite }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={1}
        sx={{ position: "relative" }}
      >
        <MDTypography variant="h5">Watchlist</MDTypography>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={() => setOpenMobileFavorite(dispatch, !openMobileFavorite)}
          style={{
            position: "absolute",
            top: "24px",
            right: "8px",
          }}
        >
          close
        </Icon>
      </MDBox>
      <MDBox>
        <FavoriteList showHeader={false} />
      </MDBox>
    </ConfiguratorRoot>
  );
};

export default FavoriteSidebar;
