/* eslint-disable react/prop-types */

import { useState, useEffect, useRef } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useSearchParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import memeDefiBgImage from "assets/images/memedefibg.jpeg";
import memeDefiLogo from "assets/images/0hHubTransparent.png";
import useApi from "utils/useApi";
import useLogin from "utils/useLogin";
import { truncate } from "lodash";

function Basic() {
  const { authenticateUserByGoogle, loginUserByUsernamePassword } = useApi();
  const search = useLocation().search;
  const signUpSuccessStatus = new URLSearchParams(search)?.get("signup_success");
  // const [captchaToken, setCaptchaToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [memedefiToken, setMemedefiToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState({
    email: "",
    password: "",
    login: "",
    code: "",
  });
  const captchaRef = useRef(null);

  useLogin(memedefiToken, true);

  const onLogin = async (codeResponse) => {
    const tokenRes = await authenticateUserByGoogle(codeResponse.credential);
    setMemedefiToken(tokenRes);
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const isEmailValid = emailRegex.test(email);

    if (!isEmailValid) {
      setError({
        ...error,
        email: "Email is not valid",
      });
      return;
    }
    if (signUpSuccessStatus && !code) {
      setError({
        ...error,
        login: "Login code must be entered. Check your email!",
      });
      return;
    }
    const payload = {
      email,
      password,
      code,
    };
    try {
      const tokenRes = await loginUserByUsernamePassword(payload);
      setMemedefiToken(tokenRes);
    } catch (e) {
      setError({
        ...error,
        login: e?.message ?? "Login Error",
      });
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    if (field === "email") {
      setEmail(value);
    } else if (field === "code") {
      setCode(value);
    } else {
      setPassword(value);
    }

    if (!value) {
      setError({
        ...error,
        [field]: "Field is required",
        login: "",
      });
    } else {
      setError({
        ...error,
        [field]: "",
        login: "",
      });
    }
  };

  return (
    <BasicLayout image={memeDefiBgImage}>
      {signUpSuccessStatus && (
        <MDBox sx={{ marginTop: "16px" }}>
          <MDAlert
            color="default"
            dismissible
            sx={{ padding: "0px 8px", margin: 0, marginBottom: "8px" }}
          >
            <MDTypography display="block" variant="button" color="white">
              Sign up completed! Please check your email for one time <b>Login Code</b>
            </MDTypography>
          </MDAlert>
          <br />
        </MDBox>
      )}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={0}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            Sign in{" "}
            <MDAvatar
              src={memeDefiLogo}
              size="sm"
              sx={{ marginLeft: "4px", border: "1px solid #0A0A0A", background: "#f5f5f5" }}
            />
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <GoogleLogin
                clientId="530769203598-5pq0fsc7ss2he83udm849kdc0hm3bbaf.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={onLogin}
                onFailure={(e) => console.log("Google authentication error ", e.message)}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <MDTypography display="block" variant="button" color="white" mb={2}>
              Or, Use email and password to log in
            </MDTypography>
          </MDBox>
          <MDBox component="form" role="form">
            <MDBox mb={3}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                error={!!error?.email}
                helperText={
                  <MDTypography
                    sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                    variant="text"
                    color="error"
                  >
                    {error?.email}
                  </MDTypography>
                }
                fullWidth
                onChange={(e) => handleInputChange(e, "email")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                error={!!error?.password}
                helperText={
                  <MDTypography
                    sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                    variant="text"
                    color="error"
                  >
                    {error?.password}
                  </MDTypography>
                }
                fullWidth
                onChange={(e) => handleInputChange(e, "password")}
              />
            </MDBox>
            {signUpSuccessStatus ? (
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="One-time login code"
                  error={!!error?.code && signUpSuccessStatus}
                  helperText={
                    <MDTypography
                      sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                      variant="text"
                      color="error"
                    >
                      {error?.code}
                    </MDTypography>
                  }
                  fullWidth
                  onChange={(e) => handleInputChange(e, "code")}
                />
              </MDBox>
            ) : (
              <></>
            )}
            <MDBox
              mt={0}
              mb={1}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDTypography
                sx={{
                  position: "absolute",
                  top: "40px",
                }}
                variant="text"
                color="error"
                fontSize="14px"
              >
                {truncate(error?.login, {
                  length: 50,
                })}
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleEmailSignIn}
                disabled={!(email && password && (signUpSuccessStatus ? code : true))}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Sign up
                </MDTypography>
              </MDTypography>
              <MDTypography variant="button" color="text" sx={{ display: "block", fontSize: 12 }}>
                Forgot password?&nbsp;
                <MDTypography
                  component={Link}
                  to="/authentication/password-reset"
                  variant="button"
                  color="info"
                  fontWeight="regular"
                  fontSize="12px"
                >
                  Reset password
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
