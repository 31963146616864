import * as React from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import noDataHereBg from "assets/images/noDataHereBg.png";
import noDataHereBgWhite from "assets/images/noDataHereBgWhite.png";

const SkeletonWrapper = ({
  variant,
  height,
  width,
  fontSize,
  children,
  hideSkeleton = false,
  darkMode = true,
  isChartImage = false,
  isLoader = false,
  style = {},
}) => {
  if (hideSkeleton) {
    return children;
  }
  const bgImage = darkMode ? noDataHereBg : noDataHereBgWhite;
  if (isChartImage) {
    return (
      <MDBox
        spacing={2}
        position="relative"
        width="100%"
        minHeight="50vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, darkMode ? 0.6 : 0.1),
              rgba(gradients.dark.state, darkMode ? 0.6 : 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          ...style,
        }}
      />
    );
  }
  if (isLoader) {
    return (
      <MDBox
        spacing={2}
        position="relative"
        width="100%"
        minHeight="50vh"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, darkMode ? 0.6 : 0.1),
              rgba(gradients.dark.state, darkMode ? 0.6 : 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          ...style,
        }}
      >
        {/* <CircularProgress size={60} color="info" /> */}
      </MDBox>
    );
  }
  return (
    <Stack spacing={2} sx={style}>
      {variant === "text" ? (
        <Skeleton variant="text" sx={{ fontSize: fontSize }} />
      ) : (
        <Skeleton variant={variant} width={width} height={height} />
      )}
    </Stack>
  );
};

SkeletonWrapper.defaultProps = {
  variant: "rectangular",
  fontSize: "",
  height: 1,
  width: 1,
  hideSkeleton: false,
  darkMode: true,
  isLoader: false,
  isChartImage: false,
  style: {},
  children: null,
};
SkeletonWrapper.propTypes = {
  variant: PropTypes.oneOf(["text", "circular", "rectangular", "rounded"]),
  fontSize: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  hideSkeleton: PropTypes.bool,
  darkMode: PropTypes.bool,
  isLoader: PropTypes.bool,
  isChartImage: PropTypes.bool,
  style: PropTypes.any,
  children: PropTypes.any,
};
export default SkeletonWrapper;
