/* eslint-disable react/prop-types */

import React, { useMemo } from "react";
import { useQuery } from "react-query";

import MDBox from "components/MDBox";
import EtherscanIcon from "assets/images/etherscanLogo.png";
import XIcon from "assets/images/xLogo.png";
import CmcIcon from "assets/images/cmcLogo.png";
import CGIcon from "assets/images/coingeckoLogo.png";
import mediumIcon from "assets/images/mediumLogo.png";
import MDAvatar from "components/MDAvatar";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhitepaperIcon from "@mui/icons-material/MenuBook";
import GitHubIcon from "@mui/icons-material/GitHub";
import TelegramIcon from "@mui/icons-material/Telegram";

import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";
// import ShareIcon from "@mui/icons-material/Share";

import { useMaterialUIController } from "context";
import useApi from "utils/useApi";
import WebIcon from "@mui/icons-material/Dvr";
import UpdateSocialButton from "./updateSocialButton";
import ShareInTwitter from "layouts/common/shareInTwitter";
import { createTweetIntentLink, getAddressLinkByChain } from "./utils";
import { getScannerLogoByChain, getChainForUrl } from "utils/chain";

const socialOrder = [
  "etherscan",
  "telegram",
  "website",
  "twitter",
  "whitepaper",
  "medium",
  "github",
  "facebook",
  "instragram",
  "cg",
  "cmc",
];

const ProjectSocial = ({
  pairAddress,
  contractAddress,
  projectId = "",
  tokenData = {},
  tokenMetadata = {},
  onSocialClick = undefined,
}) => {
  const [controller] = useMaterialUIController();

  const { getAllSocialsForProject } = useApi();

  const {
    data = [],
    refetch: refetchProjectByPair,
    isFetched: isTokenDataFetched,
  } = useQuery(`getAllSocialsForProject-${projectId}`, () => getAllSocialsForProject(projectId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: projectId.length > 1,
  });
  const allSocials = data?.data ?? [];
  const { darkMode, transparentNavbar } = controller;
  const { size } = typography;
  const { socialMediaColors } = colors;

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }, custom = {}) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    ...custom,
  });

  const defaultSocial = [
    {
      name: "etherscan",
      value: getAddressLinkByChain(tokenData?.chain, contractAddress, "token"),
      logo: getScannerLogoByChain(tokenData?.chain, darkMode ? "dark" : "bright"),
    },
  ];

  const cmcSocials = [
    {
      name: "telegram",
      value: tokenMetadata?.chat,
    },
    {
      name: "twitter",
      value: tokenMetadata?.twitter,
    },
    {
      name: "website",
      value: tokenMetadata?.website,
    },
    {
      name: "facebook",
      value: tokenMetadata?.facebook,
    },
    {
      name: "whitepaper",
      value: tokenMetadata?.technical_doc,
    },
    {
      name: "cg",
      value: tokenMetadata?.cg_slug ? getSocialMetadataServiceUrl("cg", tokenMetadata.cg_slug) : "",
    },
    {
      name: "cmc",
      value: tokenMetadata?.slug
        ? getSocialMetadataServiceUrl(tokenMetadata.service, tokenMetadata.slug)
        : "",
    },
  ];

  const socialsList = [...defaultSocial, ...cmcSocials, ...allSocials];

  const sortedSocials = useMemo(() => {
    return sortSocialArray([...defaultSocial, ...cmcSocials, ...allSocials], socialOrder);
  }, [data?.data, tokenMetadata]);

  const projectSocials = sortedSocials
    .reduce((acc, social) => {
      const existingSocial = acc.find((item) => item.name === social.name);
      if (existingSocial) {
        // If social with the same name already exists, keep it
        return acc;
      } else {
        if (social.value && social.value.length > 2) {
          acc.push({
            name: social.name,
            value: social.value?.includes("http") ? social.value : `https://${social.value}`,
            logo: social.logo,
          });
        }
        return acc;
      }
    }, [])
    .filter((social) => social.value && social.value.length > 2);

  const tweetIntentLink = getTweetShareLink(pairAddress, tokenData);

  return (
    <MDBox display="flex" justifyContent="space-between" alignItems="center">
      <MDBox>
        {projectSocials.map(({ name, value, logo }) => {
          const { link, icon, color } = getSocialByName(name, value, iconsStyle, logo);
          return (
            <MDBox
              key={color}
              component="a"
              href={link}
              target="_blank"
              rel="noreferrer"
              color={socialMediaColors[color]?.main ?? "#42424a"}
              pr={0.5}
              pl={0.5}
              lineHeight={1}
              sx={iconsStyle}
              onClick={() => {
                if (onSocialClick) {
                  onSocialClick(name);
                }
              }}
            >
              {icon}
            </MDBox>
          );
        })}
        <UpdateSocialButton
          projectId={projectId}
          pairAddress={pairAddress}
          contractAddress={contractAddress}
          chain={tokenData?.chain}
        />
      </MDBox>
      {/* <ShareInTwitter title={"Tweet"} tweetLinkWithContent={tweetIntentLink} /> */}
    </MDBox>
  );
};

export default ProjectSocial;

const getSocialByName = (socialName = "", value = "", iconsStyle = null, logo = null) => {
  const name = socialName.toLowerCase();

  if (!name || !value) {
    return {};
  }

  if (name === "etherscan") {
    return {
      link: value,
      icon: (
        <MDAvatar
          src={logo || EtherscanIcon}
          alt="Etherscan"
          shadow="xs"
          sx={(theme) =>
            iconsStyle(theme, {
              height: 18,
              width: 18,
              display: "inline-block",
              background: "#ffffffcc",
              border: "0.5px solid #ffffffcc",
            })
          }
        />
      ),
    };
  }
  if (name === "twitter") {
    return {
      link: value,
      icon: (
        <MDAvatar
          src={XIcon}
          alt="X"
          shadow="xs"
          sx={(theme) =>
            iconsStyle(theme, {
              height: 17,
              width: 17,
              display: "inline-block",
              position: "relative",
              top: "0px",
              background: "#ffffffcc",
              border: `0.5px solid #ffffffcc`,
              "& img": {
                position: "absolute",
                top: "2.5px",
              },
            })
          }
        />
      ),
    };
  }
  if (name === "telegram") {
    return {
      link: value,
      icon: <TelegramIcon sx={{ position: "relative", top: "1px" }} />,
      color: "Telegram",
    };
  }
  if (name === "github") {
    return {
      link: value,
      icon: <GitHubIcon sx={{ position: "relative", top: "1px" }} />,
      color: "Github",
    };
  }

  if (name === "whitepaper") {
    return {
      link: value,
      icon: <WhitepaperIcon sx={{ position: "relative", top: "1px" }} />,
      color: "Whitepaper",
    };
  }

  if (name === "medium") {
    return {
      link: value,
      icon: (
        <MDAvatar
          src={mediumIcon}
          alt="Medium"
          shadow="xs"
          sx={(theme) =>
            iconsStyle(theme, {
              height: 17,
              width: 17,
              display: "inline-block",
              position: "relative",
              background: "#ffffffcc",
              border: `0.5px solid #ffffffcc`,
              "& img": {
                height: 17,
              },
            })
          }
        />
      ),
    };
  }
  if (name === "cmc") {
    return {
      link: value,
      icon: (
        <MDAvatar
          src={CmcIcon}
          alt="CMC"
          shadow="xs"
          sx={{
            height: 18,
            width: 18,
            display: "inline-block",
            position: "relative",
          }}
        />
      ),
    };
  }
  if (name === "cg") {
    return {
      link: value,
      icon: (
        <MDAvatar
          src={CGIcon}
          alt="Coingecko"
          shadow="xs"
          sx={(theme) =>
            iconsStyle(theme, {
              height: 18,
              width: 18,
              display: "inline-block",
              position: "relative",
            })
          }
        />
      ),
    };
  }
  if (name === "facebook") {
    return {
      link: value,
      icon: <FacebookIcon sx={{ position: "relative", top: "1px" }} />,
      color: "facebook",
    };
  }
  if (name === "website") {
    return {
      link: value,
      icon: <WebIcon sx={{ position: "relative", top: "1px" }} />,
      color: "website",
    };
  }
  if (name === "instagram") {
    return {
      link: value,
      icon: <InstagramIcon sx={{ position: "relative", top: "1px" }} />,
      color: "instagram",
    };
  }
  return {};
};

const getTweetShareLink = (pairAddress, data = {}, logoUrl = "") => {
  const url = `https://0xhub.io/${getChainForUrl(data?.chain)}/${pairAddress}`;
  const text = `Check out $${data.symbol} live on 0xhub!

${
  data
    ? `Market cap: ${data?.marketcap}
Liquidity: ${data.liquidity}
Volume (24h) : ${data.volume} (${data.volumeChange})${
        data.holders
          ? `
Holders: ${data.holders}`
          : ""
      }`
    : ""
}

Live chart:`;

  return createTweetIntentLink(url, text, logoUrl);
};

const getSocialMetadataServiceUrl = (service, value) => {
  switch (service) {
    case "cmc":
      return `https://coinmarketcap.com/currencies/${value}`;
    case "cg":
      return `https://www.coingecko.com/en/coins/${value}`;
    default:
      return "";
  }
};

const sortSocialArray = (socialArray, order) => {
  const orderMap = {};
  order.forEach((name, index) => {
    orderMap[name] = index;
  });
  return socialArray.sort((a, b) => {
    const aIndex = orderMap[a.name] !== undefined ? orderMap[a.name] : order.length;
    const bIndex = orderMap[b.name] !== undefined ? orderMap[b.name] : order.length;
    return aIndex - bIndex;
  });
};
