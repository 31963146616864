import React, { useState, useEffect } from "react";
import { truncate } from "lodash";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";

import { useMaterialUIController } from "context";
import { projectAtom } from "atoms";

// @mui material components
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDAlert from "components/MDAlert";

// 0xHub Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

import useLogin from "utils/useLogin";
import useTrackEvent from "utils/useTrackEvent";
import Social from "./social";
import TooltipWrapper from "./tooltipWrapper";
import FavoriteFeatureIcon from "./FavoriteList";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import { formatPriceWithDecimals, numPrettyFormatter } from "utils/price";
import { getChainForUrl } from "utils/chain";

function Header({
  projectData = {},
  tokenMetadata = {},
  realtimeProjectData = {},
  realTimePrice = null,
  nativeProjectData = {},
  isOnline = true,
  isTokenDataFetched = false,
  holdersCount = 0,
  style = {},
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [chartCopyTooltipText, setChartCopyTooltipText] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const { isLoggedIn, userInfo } = useLogin("");
  const [controller] = useMaterialUIController();
  const atomProjectData = useRecoilValue(projectAtom);

  const { trackEvent } = useTrackEvent();

  const { darkMode } = controller;

  const currentlyTrendingList = atomProjectData?.trendingProjects ?? [];
  const trendingNumberIndex = currentlyTrendingList?.findIndex(
    (t) => t.id === nativeProjectData?.id
  );
  const trendingNumber = trendingNumberIndex + 1;

  useEffect(() => {
    // A function that sets the display state for the mobileview.
    function adjustMobileView() {
      if (window.innerWidth <= breakpoints.values.sm) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.xs
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    const runResize = () => {
      handleTabsOrientation();
      adjustMobileView();
    };

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", runResize);

    runResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", runResize);
  }, [tabsOrientation]);

  if (!isOnline || (!projectData?.address && isTokenDataFetched)) {
    return (
      <MDBox position="relative" sx={{ width: "100%", background: "gray" }}>
        <MDAlert color="info" dismissible sx={{ margin: 0 }}>
          <MDTypography variant="button" color="regular">
            {!isOnline ? (
              <>Internet connection lost! We are unable to refresh the data in real-time.</>
            ) : (
              <>We could not fetch market data due to technical reason. Please try again later.</>
            )}
          </MDTypography>
        </MDAlert>
      </MDBox>
    );
  }

  if (!projectData?.address) {
    return null;
  }

  const percentChange = projectData?.stats?.prices?.day_1.prettyPrint;
  const projectName = truncate(projectData?.name, {
    length: mobileView ? 14 : 22,
    separator: ".",
  });
  const projectLogo =
    tokenMetadata?.logo || (nativeProjectData?.is_reviewed ? nativeProjectData?.logo : "") || "";

  const memeDefiReferralCode = localStorage.getItem("0xhub-referral");
  const memeDefiChartLink = `https://0xhub.io/${getChainForUrl(projectData?.chain)}/${
    projectData?.pool_address
  }${isLoggedIn && memeDefiReferralCode ? `?s=${memeDefiReferralCode}` : ""}`;
  const price = formatPriceWithDecimals(
    realtimeProjectData?.price || realTimePrice || (projectData?.stats?.prices?.live ?? 0)
  );

  useEffect(() => {
    if (price) {
      document.title = `${projectData?.symbol} $${price}`;
    }
  }, [price]);

  useEffect(() => {
    trackEvent({
      projectId: nativeProjectData?.id,
      eventName: "page_visit",
      userId: userInfo?.id,
    });
  }, []);

  const handleSocialClick = (name) => {
    let eventName = "";
    switch (name) {
      case "etherscan":
        eventName = "etherscan";
        break;
      default:
        eventName = "social";
        break;
    }
    trackEvent({
      projectId: nativeProjectData?.id,
      eventName: eventName,
      userId: userInfo?.id,
    });
  };

  return (
    <MDBox position="relative" mt={0} sx={{ width: "100%" }}>
      {mobileView && (
        <MDTypography
          variant="button"
          fontWeight="medium"
          fontSize="12px"
          style={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
            color: "#FF7700",
            marginTop: "-12px",
            border: "none",
            background: `${darkMode ? "#1a1f2b" : "#ffffff"}`,
          }}
        >
          {trendingNumber > 0 && mobileView && (
            <>
              <WhatshotIcon htmlColor="#FF7700" />
              &nbsp;Trending&nbsp;#{trendingNumber}
            </>
          )}
        </MDTypography>
      )}
      <Card
        sx={{
          position: "relative",
          mt: 0,
          mx: 0,
          py: 1,
          px: 1,
          width: "100%",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              title={<small>{chartCopyTooltipText}</small>}
              open={!!chartCopyTooltipText}
              onClose={() => setChartCopyTooltipText("")}
              placement="top"
              TransitionProps={{ timeout: 0 }}
            >
              <MDAvatar
                src={projectLogo}
                alt={projectData?.symbol}
                size="sm"
                shadow="sm"
                onClick={async () => {
                  await navigator.clipboard.writeText(memeDefiChartLink);
                  setChartCopyTooltipText("Chart link copied");
                }}
                sx={{
                  marginRight: "8px",
                  color: darkMode ? "#ffffffCC" : "#0A0A0A",
                  fontWeight: "700",
                  border: darkMode ? "0.5px solid #f0f2f5" : "none",
                  boxShadow: 1,
                  background: darkMode ? "transparent" : "#f0f2f5",
                  cursor: "pointer",
                  "& > img": {
                    userDrag: "none !important",
                    userSelect: "none !important",
                  },
                }}
              >
                {projectData?.symbol?.[0]}
              </MDAvatar>
            </Tooltip>
          </div>
          <div style={{ width: "100%" }}>
            <MDBox
              height="100%"
              mt={0}
              lineHeight={0.6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                }}
              >
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MDTypography
                      variant="h5"
                      fontWeight="medium"
                      display="flex"
                      alignItems="center"
                    >
                      <TooltipWrapper
                        showTooltip={projectData?.name?.length > projectName?.length}
                        title={projectData?.name}
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          display="flex"
                          alignItems="center"
                        >
                          {projectName}
                        </MDTypography>
                      </TooltipWrapper>
                      {mobileView && (
                        <>
                          &nbsp;
                          <FavoriteFeatureIcon
                            currentPair={projectData?.pool_address}
                            logo={projectLogo}
                            currentProject={projectData}
                            realTimePrice={realTimePrice}
                            currentProjectName={projectData?.symbol}
                            currentProjectId={nativeProjectData?.id}
                            showOnlyStar={true}
                          />
                        </>
                      )}
                    </MDTypography>
                    {!mobileView && (
                      <div style={{ paddingTop: 4, marginLeft: "8px" }}>
                        <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                          {trendingNumber > 0 && (
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              fontSize="12px"
                              style={{
                                textAlign: "left",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "8px",
                                padding: "0px 8px",
                                color: "#FF7700",
                                border: "0.1px solid #FF7700",
                                borderRadius: "16px",
                              }}
                            >
                              <WhatshotIcon htmlColor="#FF7700" />
                              &nbsp;#{trendingNumber}
                            </MDTypography>
                          )}
                        </MDBox>
                      </div>
                    )}
                  </div>
                  <MDTypography variant="p" color="text" fontWeight="regular" fontSize="12px">
                    {`${projectData?.symbol}/${projectData?.base?.symbol}`}
                  </MDTypography>
                </div>
              </MDBox>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label={
                    <MDBox>
                      <MDTypography variant="button" color="text" fontWeight="bold">
                        $ {price}
                      </MDTypography>
                      <div style={{ height: "2px" }} />
                      <span
                        style={{
                          display: "block",
                          alignItems: "center",
                          fontSize: "11px",
                          paddingBottom: "4px",
                          textAlign: "center",
                          color: percentChange?.includes("+") ? "#36BB91" : "#F44335",
                        }}
                      >
                        {projectData?.stats?.prices?.day_1.prettyPrint}&nbsp;24h
                      </span>
                    </MDBox>
                  }
                  sx={({ breakpoints }) => ({
                    cursor: "pointer",
                    cursor: "inherit",
                    fontWeight: "bold",
                    height: "40px",
                    position: "relative",
                    WebkitBackdropFilter: "blur(0px)",
                    backdropFilter: "blur(0px)",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      inset: 0,
                      borderRadius: "12px",
                      border: "2px solid transparent",
                      opacity: 0.6,
                      background:
                        "linear-gradient(0deg, #0062ff, #29e49d 70%) border-box !important",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
                      mask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
                      maskComposite: "exclude",
                    },
                    [breakpoints.down("sm")]: {
                      padding: "2px",
                    },
                    [breakpoints.up("sm")]: {
                      padding: "8px",
                    },
                  })}
                  size="medium"
                />
              </div>
            </MDBox>
          </div>
        </div>
        <MDBox sx={{ marginTop: "12px", marginLeft: "-4px" }} display="block">
          <Social
            social={socials}
            tokenMetadata={tokenMetadata}
            contractAddress={projectData?.address}
            pairAddress={projectData?.pool_address}
            projectId={nativeProjectData?.id}
            tokenData={{
              marketcap: numPrettyFormatter(projectData?.stats?.fullyDilutedMarketCap ?? 0),
              volume: numPrettyFormatter(projectData?.stats?.prices?.day_1.volume ?? 0),
              volumeChange: projectData?.stats?.prices?.day_1.prettyPrint,
              liquidity: numPrettyFormatter(projectData?.stats?.trueValue * 2 ?? 0),
              holders: holdersCount,
              symbol: projectData?.symbol,
              chain: projectData?.chain,
            }}
            onSocialClick={handleSocialClick}
          />
        </MDBox>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  projectData: {},
  realtimeProjectData: {},
  nativeProjectData: {},
  isOnline: true,
  realTimePrice: null,
  isTokenDataFetched: false,
  holdersCount: 0,
  style: {},
};

// Typechecking props for the Header
Header.propTypes = {
  projectData: PropTypes.any,
  tokenMetadata: PropTypes.any,
  realtimeProjectData: PropTypes.any,
  nativeProjectData: PropTypes.any,
  realTimePrice: PropTypes.any,
  holdersCount: PropTypes.any,
  isOnline: PropTypes.bool,
  isTokenDataFetched: PropTypes.bool,
  style: PropTypes.any,
};

export default Header;

const socials = [];
