export const getTextColor = (isDarkMode, type) => {
  const isSell = type === "sell";
  const isBuy = type === "buy";

  if (isDarkMode) {
    if (isSell) {
      return "#FFACB1";
    }
    if (isBuy) {
      return "#CDFFE7";
    }
    return "#F5F5F5";
  } else {
    if (isSell) {
      return "#FF0000";
    }
    if (isBuy) {
      return "#008000";
    }
    return "inherit";
  }
};

export const createTweetIntentLink = (url, text, logo) => {
  const encodedURL = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);
  const encodedImageUrl = encodeURIComponent(logo);

  // Construct the tweet intent link
  const tweetIntentLink = `https://twitter.com/intent/tweet?url=${encodedURL}&text=${encodedText}${
    logo ? `&media=${encodedImageUrl}` : ""
  }`;

  return tweetIntentLink;
};

export const getAddressLinkByChain = (chain, address, type = "address", queryAddress = "") => {
  switch (chain) {
    case "ethereum":
    case "eth":
      return `https://etherscan.io/${type}/${address}${queryAddress ? `?a=${queryAddress}` : ""}`;
    case "bnb":
    case "bsc":
      return `https://bscscan.com/${type}/${address}${queryAddress ? `?a=${queryAddress}` : ""}`;
    case "base":
      return `https://basescan.org/${type}/${address}${queryAddress ? `?a=${queryAddress}` : ""}`;
    case "polygon":
      return `https://polygonscan.com/${type}/${address}${
        queryAddress ? `?a=${queryAddress}` : ""
      }`;
    default:
      return "";
  }
};

export const getTokenTransactionLink = (chain, transaction) => {
  switch (chain) {
    case "ethereum":
    case "eth":
      return `https://etherscan.io/tx/${transaction}`;
    case "bnb":
    case "bsc":
      return `https://bscscan.com/tx/${transaction}`;
    case "base":
      return `https://basescan.org/tx/${transaction}`;
    case "polygon":
      return `https://polygonscan.com/tx/${transaction}`;
    default:
      return "";
  }
};
