/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import memeDefiBgImage from "assets/images/memedefibg.jpeg";
import memeDefiLogo from "assets/images/0hHubTransparent.png";

import useApi from "utils/useApi";
import useLogin from "utils/useLogin";
import { truncate } from "lodash";
import { toast } from "react-toastify";

function Cover() {
  const { initiateResetPassword, resetPassword } = useApi();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [error, setError] = useState({
    email: "",
    passwordOne: "",
    passwordTwo: "",
  });

  const token = new URLSearchParams(search)?.get("token");

  useEffect(() => {
    if (token) {
      const decodedToken = getDecodedTokenValue();
      if (decodedToken) {
        setEmail(decodedToken.email);
      }
    }
  }, [token]);

  const getDecodedTokenValue = () => {
    try {
      const decodedToken = jwtDecode(token);
      const ifTokenValid = decodedToken.exp >= Date.now() / 1000;
      if (!ifTokenValid) {
        return "";
      }
      return decodedToken;
    } catch (error) {
      return "";
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    let errorMessage = "";

    if (field === "email") {
      setEmail(value);
    } else if (field === "passwordOne") {
      setPasswordOne(value);
      // Validate password for passwordOne field
      const isValidPassword = validatePassword(value);
      errorMessage = !isValidPassword ? "Password must be 8 digits." : "";
    } else {
      setPasswordTwo(value);
      errorMessage = value !== passwordOne ? "Password didn't match." : "";
    }

    if (!value) {
      setError({
        ...error,
        [field]: "Field is required",
      });
    } else {
      setError({
        ...error,
        [field]: errorMessage,
      });
    }
  };

  const sendPasswordResetLink = async () => {
    try {
      const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
      const isEmailValid = emailRegex.test(email);

      if (!isEmailValid) {
        setError({
          ...error,
          email: "Email is not valid",
        });
        return;
      }
      await initiateResetPassword(email);
      toast.success("Email has been sent for resetting your password.");
      setEmail("");
    } catch (e) {
      toast.error(e?.message ?? `Couldn't send an email for resetting password. Try again!`);
    }
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const handlePasswordReset = async () => {
    try {
      const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
      const isEmailValid = emailRegex.test(email);

      if (!isEmailValid) {
        setError({
          ...error,
          email: "Email is not valid",
        });
        return;
      }
      if (passwordOne !== passwordTwo) {
        setError({
          ...error,
          passwordTwo: "Password didn't match.",
        });
        return;
      }
      const payload = {
        email,
        passwordOne,
        passwordTwo,
      };
      await resetPassword(payload);
      toast.success("Password has been reset successfully!");
      setEmail("");
      navigate(`/authentication/sign-in`);
    } catch (e) {
      toast.error(e?.message ?? `Password reset failed. Try again!`);
    }
  };

  const isPasswordReset = !!token;

  return (
    <BasicLayout image={memeDefiBgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            Reset your password
            <MDAvatar
              src={memeDefiLogo}
              size="sm"
              sx={{ marginLeft: "4px", border: "1px solid #0A0A0A", background: "#f5f5f5" }}
            />
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {isPasswordReset
              ? "Provide the new password with the confirmation."
              : "Provide the email for getting a link to reset your password."}
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                error={!!error?.email}
                value={email}
                sx={{
                  background: "inherit !important",
                  "& label": isPasswordReset
                    ? {
                        color: "#aaa !important",
                      }
                    : {},
                  "& input": isPasswordReset
                    ? {
                        color: "#aaa !important",
                      }
                    : {},
                }}
                disabled={isPasswordReset}
                helperText={
                  <MDTypography
                    sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                    variant="text"
                    color="error"
                  >
                    {error?.email}
                  </MDTypography>
                }
                onChange={(e) => handleInputChange(e, "email")}
              />
            </MDBox>
            {isPasswordReset && (
              <>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    variant="standard"
                    fullWidth
                    error={!!error?.passwordOne}
                    helperText={
                      <MDTypography
                        sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                        variant="text"
                        color="error"
                      >
                        {error?.passwordOne}
                      </MDTypography>
                    }
                    onChange={(e) => handleInputChange(e, "passwordOne")}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Confirm password"
                    variant="standard"
                    fullWidth
                    error={!!error?.passwordTwo}
                    helperText={
                      <MDTypography
                        sx={{ position: "absolute", bottom: "-14px", left: 0 }}
                        variant="text"
                        color="error"
                      >
                        {error?.passwordTwo}
                      </MDTypography>
                    }
                    onChange={(e) => handleInputChange(e, "passwordTwo")}
                  />
                </MDBox>
              </>
            )}
            <MDBox
              mt={4}
              mb={1}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDTypography
                sx={{
                  position: "absolute",
                  top: "40px",
                }}
                variant="text"
                color="error"
                fontSize="14px"
              >
                {truncate(error?.signup, {
                  length: 50,
                })}
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={isPasswordReset ? handlePasswordReset : sendPasswordResetLink}
                disabled={!(email || (passwordOne && passwordTwo))}
              >
                {isPasswordReset ? "Reset password" : "Request password change"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
