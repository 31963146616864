export const formatPriceWithDecimals = (price, priceLastNumber = 4) => {
  if (!price) {
    return "";
  }
  const realPrice = convertScientificToReal(price);

  const { front, back } = getFrontAndBackOfDecimal(realPrice);

  const { leadingZeros, numberPart } = separateLeadingZeros(back);

  // Take up to 4 digits from the number part
  const trimmedNumberPart = numberPart ? numberPart.slice(0, priceLastNumber) : "0000";

  // Combine the integer and formatted decimal parts
  return `${front}${leadingZeros}${trimmedNumberPart}`;
};

export const getFrontAndBackOfDecimal = (number) => {
  const numberString = Number(number).toFixed(30).toString();
  const [, front, back] = /^([0-9]*[.])?([0-9]+)$/.exec(numberString) || ["", "", numberString];
  return { front, back };
};

export const separateLeadingZeros = (number) => {
  const numberString = number.toString();
  const [, leadingZeros, numberPart] = /^([0]+)([1-9]\d*)$/.exec(numberString) || [
    ,
    "",
    numberString,
  ];
  return { leadingZeros, numberPart };
};

export const getDecimalValue = (input, decimal = 2) => {
  if (!input) {
    return input;
  }
  // Convert the input to a number if it's a string
  const numValue = typeof input === "string" ? parseFloat(input) : input;

  // Check if the conversion resulted in a valid number
  if (isNaN(numValue)) {
    throw new Error(
      "Invalid input. Please provide a valid number or a string that can be converted to a number."
    );
  }

  // Round the number to the specified decimal places
  const roundedValue = parseFloat(numValue.toFixed(decimal));

  return roundedValue.toLocaleString(undefined, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
};

export const convertScientificToReal = (priceInScientific) => {
  const realValue = priceInScientific.toFixed(30);
  return realValue;
};

export const numPrettyFormatter = (num, digits = 2, minValue = 1000, digitsAfterMinValue = 2) => {
  if (!num) {
    return num;
  }
  if (num <= minValue) {
    return (Math.round(num * 100) / 100).toFixed(digits);
  }
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digitsAfterMinValue).replace(rx, "$1") + item.symbol
    : "0";
};
