import * as React from "react";
import TextField from "@mui/material/TextField";
import SearchModal from "./SearchModal";
import MDInput from "components/MDInput";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMaterialUIController } from "context";

export default function SearchBar() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MDInput
        placeholder="Search by name, symbol or contract"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                color={darkMode ? "white" : "text"}
                sx={({ typography: { size } }) => ({
                  fontSize: "25px !important",
                })}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          width: {
            xs: "85vw",
            sm: 200,
            md: 250,
            lg: 450,
          },
          "& fieldset": {
            borderRadius: "50px",
            border: "1.3px solid transparent !important",
            background: "linear-gradient(70deg, #0062ff, #29e49d 70%) border-box !important",
            WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            background: "#FFFFFF1A !important",
          },
        }}
        inputProps={{
          readOnly: true,
        }}
        onClick={handleOpen}
      />
      <SearchModal open={open} onClose={handleClose} />
    </>
  );
}
