import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDButton from "components/MDButton";
import Chip from "@mui/material/Chip";

// 0xHub Dashboard React components
import MDAvatar from "components/MDAvatar";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CommentIcon from "@mui/icons-material/Comment";
import WalletIcon from "@mui/icons-material/Wallet";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

// 0xHub Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Trending from "examples/Trending";
import Header from "./header";
import ProjectNotification from "./projectNotification";
import MarketData from "./MarketData";
import FavoriteListOnlyLogo from "./FavoriteListOnlyLogo";
import FavoriteList from "./FavoriteList";
import Scanner from "./Scanner";
import VotingContainer from "../voting";
import ProjectDescription from "./descriptionContainer";
import SniperList from "./sniperList";
import SkeletonWrapper from "./skeletonWrapper";
import UniswapLogo from "./svg/uniswapLogo";
import { getTextColor } from "./utils";

import pancakeSwapLogo from "assets/images/pancakeswap.png";

import { useParams, useLocation } from "react-router-dom";
import TVChartContainer from "../../components/TVChartContainer/index";
import TextAd from "../advertisement/textAd";
import EgSwapPopup from "./egswapPopup";

import { getPairAddressFromParams } from "utils/token";
import useApi from "utils/useApi";
import useMobileView from "utils/useMobileView";
import { formatPriceWithDecimals } from "utils/price";
import useOnlineStatus from "utils/useOnlineStatus";

import useProjectData from "./tableData/data";
import useHolderData from "./tableData/holderData";
import ProjectTable from "./tableData/table";
import { UserComments } from "./tableData/userComments";

import { useMaterialUIController, setSelectedChain } from "context";
import { socket } from "socket";
import { chartFilterByAddressAtom } from "atoms";
import { availableRouteChains } from "utils/chain";

const Project = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;
  const [currentTab, setCurrentTab] = useState("trx");
  const [socketData, setSocketData] = useState({});
  const [rightBarOptions, setRightBarOptions] = useState({
    audit: localStorage.getItem("rightbar-audit") || "1",
    favorite: localStorage.getItem("rightbar-favorite") || "1",
    state: localStorage.getItem("rightbar-state") || "1",
  });
  const [controller, dispatch] = useMaterialUIController();
  const isOnline = useOnlineStatus();
  const [chartFilterByAddress] = useRecoilState(chartFilterByAddressAtom);
  const setFilterByAddress = useSetRecoilState(chartFilterByAddressAtom);
  const { mobileView } = useMobileView();

  const {
    fetchProjectByPair,
    fetchMemeDefiDBProjectByPair,
    fetchProjectHoldersByContract,
    fetchProjectTransactionsByContract,
    getPriceOfTokenByPoolAddress,
    getTokenMetadata,
    getAllCommentsByProjectId,
  } = useApi();

  const { darkMode, selectedChain } = controller;
  const pairAddress = getPairAddressFromParams(params);
  const chain = params?.chain;

  const referralCode = new URLSearchParams(search)?.get("s");

  const {
    data = [],
    refetch: refetchProjectByPair,
    isFetched: isTokenDataFetched,
  } = useQuery(
    `fetchProjectByPair-${pairAddress}`,
    () => fetchProjectByPair(pairAddress, referralCode, chain),
    {
      retry: false,
      refetchOnWindowFocus: true,
    }
  );

  const { data: memedefiProjectData = [], refetch: refetchMemeDefiDBProjectByPair } = useQuery(
    `fetchMemeDefiDBProjectByPair-${pairAddress}`,
    () => fetchMemeDefiDBProjectByPair(pairAddress, chain),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  const nativeProjectData = memedefiProjectData?.data?.[0] ?? {};

  const {
    data: comments = [],
    refetch: refetchProjectComments,
    isLoading: isCommentsLoading,
    isError: isCommentsError,
  } = useQuery(
    [`getAllCommentsByProjectId`, nativeProjectData.id],
    () => getAllCommentsByProjectId(nativeProjectData.id),
    {
      enabled: false,
    }
  );

  const { data: realtimePriceData = {}, refetch: refetchPriceOfTokenByPoolAddress } = useQuery(
    `getPriceOfTokenByPoolAddress-${pairAddress}`,
    () => getPriceOfTokenByPoolAddress(pairAddress),
    {
      retry: false,
      refetchOnWindowFocus: true,
    }
  );

  const { data: tokenMetadata = {} } = useQuery(
    `getTokenMetadata-${pairAddress}`,
    () => getTokenMetadata(data?.[0]?.address, chain, pairAddress),
    {
      retry: false,
      refetchOnWindowFocus: true,
      enabled: !!data?.[0]?.address,
    }
  );

  const contractAddress = data?.[0]?.address ?? "";

  const {
    data: holdersData = [],
    isLoading: isHolderLoading,
    isFetched: isHolderFetched,
  } = useQuery(
    `fetchProjectHoldersByContract-${contractAddress}`,
    () => fetchProjectHoldersByContract(contractAddress),
    {
      retry: false,
      enabled: contractAddress.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: transactionsData = [], refetch: refetchProjectTransactionsByContract } = useQuery(
    `fetchProjectTransactionsByContract-${contractAddress}`,
    () => fetchProjectTransactionsByContract(contractAddress),
    {
      retry: false,
      enabled: contractAddress.length > 0,
    }
  );

  const { data: auditDataRes } = useQuery(
    `getTokenAuditDataByContract-${contractAddress}`,
    () => getTokenAuditDataByContract(contractAddress, chain),
    {
      retry: false,
      refetchOnWindowFocus: true,
      enabled: false,
    }
  );

  const getRightSideBarWidth = () => {
    const setValue = localStorage.getItem("0xhub-rightbar-width");
    return setValue ? Number(setValue) : 2.5;
  };

  const toggleRightBarOptions = (value) => {
    const auditValue = rightBarOptions.audit === "1" ? "0" : "1";
    const favoriteValue = rightBarOptions.favorite === "1" ? "0" : "1";
    const stateValue = rightBarOptions.state === "1" ? "0" : "1";

    if (value === "audit") {
      localStorage.setItem("rightbar-audit", auditValue);
      setRightBarOptions({
        ...rightBarOptions,
        audit: auditValue,
      });
      return;
    }
    if (value === "favorite") {
      localStorage.setItem("rightbar-favorite", favoriteValue);
      setRightBarOptions({
        ...rightBarOptions,
        favorite: favoriteValue,
      });
      return;
    }
    if (value === "state") {
      localStorage.setItem("rightbar-audit", stateValue === "0" ? "0" : "1");
      localStorage.setItem("rightbar-favorite", stateValue === "0" ? "0" : "1");
      localStorage.setItem("rightbar-state", stateValue);
      setRightBarOptions({
        ...rightBarOptions,
        state: stateValue,
        audit: stateValue === "0" ? "0" : "1",
        favorite: stateValue === "0" ? "0" : "1",
      });
      return;
    }
  };

  // Styles for the icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      return colorValue;
    },
  });

  useEffect(() => {
    if (searchParams.has("s")) {
      searchParams.delete("s");
      setSearchParams(searchParams);
    }

    if (chain !== selectedChain) {
      if (availableRouteChains.includes(chain)) {
        setSelectedChain(dispatch, chain);
      } else {
        setSelectedChain(dispatch, availableRouteChains[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (isOnline) {
      // refetch all
      refetchProjectByPair();
    }
  }, [isOnline]);

  useEffect(() => {
    function onProjectDataEvent(value) {
      setSocketData({
        [contractAddress]: value,
      });
    }
    if (contractAddress) {
      if (isOnline) {
        socket.connect();
        localStorage.setItem("currentPairAddress", pairAddress);
        if (contractAddress) {
          socket.on(contractAddress, onProjectDataEvent);
        }
      }
      return () => {
        socket.off(contractAddress, onProjectDataEvent);
        socket.disconnect();
        localStorage.removeItem("currentPairAddress");
        setFilterByAddress({
          currentAddress: "",
          trades: [],
        });
        setCurrentTab("trx");
      };
    }
  }, [contractAddress, isOnline]);

  useEffect(() => {
    if (nativeProjectData.id && comments.length === 0) {
      refetchProjectComments();
    }
  }, [nativeProjectData.id]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const auditData = auditDataRes?.data ?? {};
  const poolVersion = auditData?.dex
    ?.find((each) => each.pair?.toLowerCase() === data?.[0]?.pool_address?.toLowerCase())
    ?.name?.toLowerCase();

  const auditHoldersData = useMemo(() => {
    if (auditData?.holders?.length && contractAddress) {
      const pricePerToken = data?.[0]?.stats?.prices?.live ?? 0;
      return auditData?.holders?.map((holder) => {
        return {
          address: holder.address,
          balance: holder.balance,
          block_height: null,
          contract_address: contractAddress,
          total_supply: auditData?.total_supply,
          value: parseFloat(holder.balance) * pricePerToken,
        };
      });
    }
    return [];
  }, [contractAddress, auditData]);

  const { columns: holderDatacolumns, rows: holderDataRows } = useHolderData(
    holdersData?.length > 0 ? holdersData : auditHoldersData
  );

  const realTimeData = socketData?.[contractAddress] ?? {};
  const { columns: projectDatacolumns, rows: projectDataRows } = useProjectData(
    data?.[0]?.symbol ?? "",
    data?.[0]?.base?.symbol ?? "",
    (realTimeData?.token && transactionsData?.trades?.[0]?.tx !== realTimeData.tx
      ? [realTimeData]
      : []
    ).concat(transactionsData?.trades ?? [])
  );

  const memeDefiScore = Number(nativeProjectData?.score ?? "0") || 50;
  const realtimePriceByPair = realtimePriceData?.data?.price;
  const projectDescription = nativeProjectData.description || tokenMetadata?.description;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Trending />
      <MDBox mt={mobileView ? 2 : 1} sx={{ position: "relative" }}>
        <MDBox mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={2.5}>
              <MDBox
                sx={{
                  width: "100%",
                  position: "sticky",
                  top: 0,
                }}
              >
                {contractAddress ? (
                  <MDBox
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("sm")]: {
                        marginTop: "-10px",
                        marginBottom: "8px",
                      },
                      [breakpoints.up("sm")]: {
                        marginTop: "0px",
                        marginBottom: "8px",
                      },
                    })}
                  >
                    {chain === "base" ? (
                      <MDButton
                        component={Link}
                        to={`https://app.uniswap.org/swap?inputCurrency=base&outputCurrency=${contractAddress}&use=v2&chain=base`}
                        variant="outlined"
                        target="_blank"
                        size="small"
                        fullWidth
                        sx={{
                          border: "1px solid rgb(252, 114, 255)",
                          transition: "all 250ms ease 0s",
                          "&:hover": {
                            background:
                              "linear-gradient(93.06deg, rgb(255, 0, 199) 2.66%, rgb(255, 159, 251) 98.99%)",
                          },
                          marginBottom: "4px",
                        }}
                      >
                        <UniswapLogo />
                        &nbsp;&nbsp;
                        <MDTypography variant="button" fontSize="14px" fontWeight="regular">
                          Buy in Uniswap {/* ({poolVersion?.includes("v3") ? "V3" : "V2"}) */}
                        </MDTypography>
                      </MDButton>
                    ) : (
                      <EgSwapPopup
                        contractAddress={contractAddress}
                        darkMode={darkMode}
                        chain={chain}
                      />
                    )}
                    <Accordion
                      elevation={0}
                      sx={({ typography: { size }, palette: { dark, white } }) => ({
                        color: darkMode ? white.main : dark.main,
                        background: "inherit",
                        border: `${
                          darkMode ? "0.2px solid rgba(210, 214, 218, 0.6)" : "0.2px solid #0A0A0A"
                        }`,
                        borderRadius: "8px !important",
                        fontSize: `${size.md} !important`,
                      })}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          minHeight: "34px !important",
                          maxHeight: "34px !important",
                          "& .MuiAccordionSummary-content": {
                            margin: 0,
                            display: "flex",
                            justifyContent: "center",
                          },
                        }}
                      >
                        <MDTypography variant="button" fontSize="14px" fontWeight="regular">
                          Snipe ⚡️
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: 0,
                          margin: "4px",
                        }}
                      >
                        <SniperList contractAddress={contractAddress} />
                      </AccordionDetails>
                    </Accordion>
                  </MDBox>
                ) : (
                  <MDBox sx={{ marginTop: "0px", marginBottom: "0px" }} />
                )}
                <MDBox
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("sm")]: {
                      display: "none",
                    },
                    [breakpoints.up("sm")]: {
                      display: "block",
                    },
                  })}
                >
                  <MarketData
                    projectData={data?.[0]}
                    holdersCount={
                      holdersData?.length > 0 ? holdersData.length : auditData?.holder_count || 0
                    }
                    realTimePice={
                      realTimeData?.price || data?.[0]?.price_live || realtimePriceByPair
                    }
                    reportedSupply={nativeProjectData?.reported_supply}
                    poolCreated={
                      nativeProjectData?.contract_created || tokenMetadata?.date_launched
                        ? Math.floor(new Date(tokenMetadata?.date_launched).getTime() / 1000)
                        : ""
                    }
                    deployer={nativeProjectData?.deployer || auditData?.creator_address}
                    auditData={auditData}
                    isHolderDataLoading={!isHolderFetched}
                  />
                </MDBox>
                <MDBox
                  sx={{
                    marginTop: "8px",
                    marginBottom: "8px",
                    display: mobileView ? "none" : "block",
                  }}
                >
                  <TextAd placement={"project_left_bar"} />
                </MDBox>
                <MDBox
                  sx={{
                    display: mobileView ? "none" : "block",
                  }}
                >
                  <MDBox>
                    <VotingContainer
                      projectId={nativeProjectData.id}
                      memeDefiScore={nativeProjectData?.id ? memeDefiScore || 50 : 50}
                    />
                  </MDBox>

                  <MDBox sx={{ marginTop: "8px", marginBottom: "8px" }}>
                    {projectDescription && <ProjectDescription description={projectDescription} />}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={rightBarOptions.state === "1" ? 7 : 9}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={12} sx={{ marginBottom: "8px" }}>
                  <SkeletonWrapper
                    variant={"rounded"}
                    height={90}
                    width={"100%"}
                    hideSkeleton={!!data?.[0]}
                    darkMode={darkMode}
                    style={{ background: darkMode ? "#1A1F2B" : "#DDDDDD" }}
                  >
                    <MDBox
                      sx={{
                        marginBottom: "0px",
                        display: "block",
                      }}
                    >
                      <ProjectNotification contractAddress={contractAddress} />
                    </MDBox>
                    <Header
                      projectData={data?.[0]}
                      tokenMetadata={tokenMetadata}
                      realtimeProjectData={realTimeData}
                      nativeProjectData={nativeProjectData}
                      realTimePrice={
                        realTimeData?.price || data?.[0]?.price_live || realtimePriceByPair
                      }
                      holdersCount={
                        holdersData?.length > 0 ? holdersData.length : auditData?.holder_count || 0
                      }
                      isOnline={isOnline}
                      style={{
                        borderRadius: {
                          xs: "0px",
                          sm: "0.75rem",
                        },
                      }}
                      isTokenDataFetched={isTokenDataFetched}
                    />
                    <MDBox
                      sx={({ breakpoints }) => ({
                        [breakpoints.down("sm")]: {
                          display: "block",
                        },
                        [breakpoints.up("sm")]: {
                          display: "none",
                        },
                      })}
                    >
                      <MarketData
                        projectData={data?.[0]}
                        holdersCount={
                          holdersData?.length > 0
                            ? holdersData.length
                            : auditData?.holder_count || 0
                        }
                        realTimePice={
                          realTimeData?.price || data?.[0]?.price_live || realtimePriceByPair
                        }
                        reportedSupply={nativeProjectData?.reported_supply}
                        poolCreated={
                          nativeProjectData?.contract_created || tokenMetadata?.date_launched
                            ? Math.floor(new Date(tokenMetadata?.date_launched).getTime() / 1000)
                            : ""
                        }
                        deployer={nativeProjectData?.deployer || auditData?.creator_address}
                        isHolderDataLoading={!isHolderFetched}
                        isMobileView={true}
                        auditData={auditData}
                        style={{
                          borderRadius: "0px",
                          borderBottomLeftRadius: 12,
                          borderBottomRightRadius: 12,
                        }}
                      />
                    </MDBox>
                  </SkeletonWrapper>
                </Grid>
                <Grid item xs={12} md={12}>
                  <SkeletonWrapper
                    variant={"rounded"}
                    height={500}
                    width={"100%"}
                    hideSkeleton={!!data?.[0]}
                    darkMode={darkMode}
                    isLoader={true}
                  >
                    <MDBox sx={{ boxShadow: 1 }}>
                      {pairAddress && data?.[0] && (
                        <TVChartContainer
                          pairAddress={data?.[0]?.symbol ? data?.[0]?.pool_address : pairAddress}
                          symbol={
                            data?.[0]?.symbol
                              ? `${data?.[0]?.symbol}/${data?.[0]?.base?.symbol}`
                              : "0xH/WETH"
                          }
                          price={
                            realTimeData?.price ||
                            data?.[0]?.stats?.prices?.live ||
                            data?.[0]?.price_live
                          }
                          lastTradeTime={realTimeData?.tradeTime || data?.[0]?.lastTradeTime}
                          darkMode={darkMode}
                          isOnline={isOnline}
                        />
                      )}
                    </MDBox>
                  </SkeletonWrapper>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MDBox
                    sx={{
                      marginTop: "12px",
                      marginBottom: "-6px",
                      display: mobileView ? "block" : "none",
                    }}
                  >
                    <TextAd placement={"below_chart"} />
                  </MDBox>
                  {data?.[0] && (
                    <MDBox
                      sx={({ breakpoints }) => ({
                        [breakpoints.down("sm")]: {
                          minHeight: 490,
                          maxHeight: 580,
                        },
                        [breakpoints.up("sm")]: {
                          minHeight: 320,
                          maxHeight: 750,
                        },
                        height: "auto",
                        marginTop: "16px",
                        boxShadow: 1,
                        position: "relative",
                      })}
                    >
                      <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        aria-label="wrapped label tabs example"
                        sx={{
                          borderRadius: "0",
                          padding: "8px",
                        }}
                      >
                        <Tab
                          value="trx"
                          label={
                            <>
                              <ReceiptLongIcon htmlColor="#008000" /> Transactions
                            </>
                          }
                        />
                        <Tab
                          value="holder"
                          label={
                            <>
                              <WalletIcon htmlColor="#00AEC6" /> Holders
                            </>
                          }
                        />
                        <Tab
                          value="comment"
                          label={
                            <>
                              <CommentIcon htmlColor="#1A73E8" /> Comments (
                              {comments.length > 10 ? `10+` : comments.length})
                            </>
                          }
                        />
                      </Tabs>
                      {currentTab === "trx" && (
                        <ProjectTable
                          columns={projectDatacolumns}
                          rows={projectDataRows}
                          total={200}
                          footer={
                            chartFilterByAddress?.trades?.length && (
                              <MDBox>
                                <MDTypography
                                  variant="button"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    padding: "16px 0px",
                                    position: "absolute",
                                    right: "16px",
                                  }}
                                >
                                  <b>Trade Summary:&nbsp;</b>
                                  <b
                                    style={{
                                      color: getTextColor(
                                        darkMode,
                                        chartFilterByAddress.summary === "profit"
                                          ? "buy"
                                          : chartFilterByAddress.summary === "loss"
                                          ? "sell"
                                          : "not-sold"
                                      ),
                                    }}
                                  >
                                    {(chartFilterByAddress.amount || 0).toFixed(2)} ETH
                                  </b>
                                </MDTypography>
                              </MDBox>
                            )
                          }
                        />
                      )}
                      {currentTab === "holder" && (
                        <ProjectTable
                          columns={holderDatacolumns}
                          rows={holderDataRows}
                          total={200}
                          footer={
                            holderDataRows?.length === 0 && (
                              <MDBox>
                                <MDTypography
                                  variant="button"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "16px 0px",
                                  }}
                                >
                                  Token holder data is coming soon...
                                </MDTypography>
                              </MDBox>
                            )
                          }
                        />
                      )}
                      {currentTab === "comment" && (
                        <UserComments
                          projectId={nativeProjectData.id}
                          refetch={refetchProjectComments}
                          isLoading={isCommentsLoading}
                          isError={isCommentsError}
                          comments={comments}
                        />
                      )}
                    </MDBox>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={rightBarOptions.state === "1" ? 2.5 : 0.5}>
              <MDBox
                sx={({ breakpoints }) => ({
                  marginTop: "0px",
                  padding: "4px",
                  paddingTop: "2px",
                  marginBottom: "8px",
                  alignItems: "center",
                  justifyContent: rightBarOptions.state === "1" ? "space-between" : "center",
                  border: "1px solid gray",
                  borderRadius: "8px",
                  [breakpoints.down("lg")]: {
                    display: "none",
                  },
                  [breakpoints.up("lg")]: {
                    display: "flex",
                  },
                })}
              >
                {rightBarOptions.state === "1" && (
                  <div>
                    <Chip
                      size="small"
                      variant={rightBarOptions.audit === "0" ? "outlined" : "filled"}
                      label="Audit"
                      onClick={() => toggleRightBarOptions("audit")}
                      color="secondary"
                    />
                    <Chip
                      size="small"
                      label="Favorite"
                      variant={rightBarOptions.favorite === "0" ? "outlined" : "filled"}
                      onClick={() => toggleRightBarOptions("favorite")}
                      color="secondary"
                      sx={{ marginLeft: "8px" }}
                    />
                  </div>
                )}
                <MenuOpenIcon
                  fontSize="medium"
                  onClick={() => toggleRightBarOptions("state")}
                  sx={iconsStyle}
                  style={{ cursor: "pointer" }}
                />
              </MDBox>
              {/* Show only in big screens with right side bar logic */}
              <MDBox
                sx={({ breakpoints }) => ({
                  [breakpoints.down("lg")]: {
                    display: "none",
                  },
                  [breakpoints.up("lg")]: {
                    display: "block",
                  },
                })}
              >
                {rightBarOptions.audit === "1" && (
                  <Scanner contractAddress={contractAddress} chain={chain} />
                )}
                {rightBarOptions.state === "1" && (
                  <MDBox sx={{ marginTop: "8px", marginBottom: "8px" }}>
                    <TextAd placement={"project_right_bar"} />
                  </MDBox>
                )}
                {rightBarOptions.favorite === "1" && (
                  <MDBox>
                    <FavoriteList
                      currentPair={data?.[0]?.pool_address}
                      currentProjectId={nativeProjectData.id}
                      currentProjectName={data?.[0]?.symbol}
                      currentProject={data?.[0]}
                      logo={nativeProjectData?.logo}
                      realTimePrice={
                        realTimeData?.price || data?.[0]?.price_live || realtimePriceByPair
                      }
                    />
                  </MDBox>
                )}
                {rightBarOptions.state === "0" && (
                  <MDBox>
                    <FavoriteListOnlyLogo
                      currentPair={data?.[0]?.pool_address}
                      currentProjectId={nativeProjectData.id}
                      currentProjectName={data?.[0]?.symbol}
                      currentProject={data?.[0]}
                      logo={nativeProjectData?.logo}
                      realTimePrice={
                        realTimeData?.price || data?.[0]?.price_live || realtimePriceByPair
                      }
                    />
                  </MDBox>
                )}
              </MDBox>
              {/* Show only small devices */}
              <MDBox
                sx={({ breakpoints }) => ({
                  [breakpoints.down("lg")]: {
                    display: "block",
                  },
                  [breakpoints.up("lg")]: {
                    display: "none",
                  },
                })}
              >
                <Scanner contractAddress={contractAddress} chain={chain} />
                <MDBox sx={{ marginTop: "8px", marginBottom: "8px" }}>
                  <TextAd placement={"project_right_bar"} />
                </MDBox>
                <MDBox
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("sm")]: {
                      display: "none",
                    },
                    [breakpoints.up("sm")]: {
                      display: "block",
                    },
                  })}
                >
                  <FavoriteList
                    currentPair={data?.[0]?.pool_address}
                    currentProjectId={nativeProjectData.id}
                    currentProjectName={data?.[0]?.symbol}
                    currentProject={data?.[0]}
                    logo={nativeProjectData?.logo}
                    realTimePrice={
                      realTimeData?.price || data?.[0]?.price_live || realtimePriceByPair
                    }
                  />
                </MDBox>
              </MDBox>
              <MDBox
                sx={{
                  display: mobileView ? "block" : "none",
                }}
              >
                <MDBox>
                  <VotingContainer
                    projectId={nativeProjectData.id}
                    memeDefiScore={nativeProjectData?.id ? memeDefiScore || 50 : 50}
                  />
                </MDBox>

                <MDBox sx={{ marginTop: "8px", marginBottom: "8px" }}>
                  {projectDescription && <ProjectDescription description={projectDescription} />}
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Project;
