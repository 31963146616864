/*  */

import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import MDTypography from "components/MDTypography";

import { shortenHex } from "utils/token";
import { useMaterialUIController } from "context";

const DefaultText = "Copy to clipboard";

const ContractCopyWithTooltip = ({
  address,
  isTooltip = true,
  tooltipDefaultText = DefaultText,
  fontSize = "12px",
  fontWeight = "regular",
  addressFormat = 4,
  enableLink = true,
  fullUrl = "",
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [tokenTooltipText, setTokenTooltipText] = useState("");

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(address);
      setTokenTooltipText("Copied");
    } catch (err) {
      // console.error("Unable to copy text: ", err);
    }

    // Reset the "Copied" state after a short delay
    setTimeout(() => {
      setTokenTooltipText("");
    }, 2000);
  };

  if (!address) {
    return null;
  }

  return (
    <MDTypography
      variant="p"
      fontWeight={fontWeight}
      fontSize={fontSize}
      style={{ textAlign: "left", display: "flex", alignItems: "center" }}
    >
      <a style={{ display: "flex", alignItems: "center" }}>
        <span style={{ display: "flex", alignItems: "center" }}>
          <a
            href={enableLink ? fullUrl || `https://etherscan.io/address/${address}` : null}
            style={{ color: darkMode ? "#00aec6" : "#00aec6" }}
            target="_blank"
            rel="noreferrer"
          >
            {shortenHex(address, addressFormat)}
          </a>
        </span>
        <Tooltip
          title={<small>{tokenTooltipText}</small>}
          open={!!tokenTooltipText && isTooltip}
          onOpen={() => setTokenTooltipText(tooltipDefaultText)}
          onClose={() => setTokenTooltipText("")}
          placement="top"
          TransitionProps={{ timeout: 0 }}
        >
          <ContentCopyIcon sx={{ marginLeft: "4px" }} onClick={handleCopyClick} />
        </Tooltip>
      </a>
    </MDTypography>
  );
};

ContractCopyWithTooltip.defaultProps = {
  address: "",
  isTooltip: true,
  tooltipDefaultText: DefaultText,
  fontSize: "12px",
  fontWeight: "regular",
  addressFormat: 4,
  fullUrl: "",
  enableLink: true,
};

ContractCopyWithTooltip.propTypes = {
  address: PropTypes.string,
  isTooltip: PropTypes.bool,
  enableLink: PropTypes.bool,
  tooltipDefaultText: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  addressFormat: PropTypes.number,
  fullUrl: PropTypes.string,
};

export default ContractCopyWithTooltip;
