/* eslint-disable react/prop-types */

import React from "react";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import MDTypography from "components/MDTypography";
import TooltipWrapper from "./tooltipWrapper";

function ThumbsDown({ setNegativeRating, negativeRatings, total, isVotingAllowed, votingMessage }) {
  function dislikeHandler(count = 0) {
    const negativeProgress = document.getElementById("negativeProgress");

    if (count === 0) {
      negativeProgress.style.width = `${negativeRatings + count}%`;
    } else {
      setNegativeRating((prevRatings) => {
        negativeProgress.style.width = `${prevRatings + count}%`;
        return prevRatings + count;
      });
    }
  }

  React.useEffect(() => {
    dislikeHandler();
  }, []);

  const percentage = ((negativeRatings * 100) / total).toFixed(2);
  return (
    <TooltipWrapper showTooltip={!isVotingAllowed} title={votingMessage}>
      <div className="thumb-container">
        <div style={{ display: "flex" }}>
          <ThumbDownAltIcon
            htmlColor={isVotingAllowed ? "#F44335" : "inherit"}
            onClick={() => (isVotingAllowed ? dislikeHandler(1) : null)}
            sx={isVotingAllowed ? { cursor: "pointer" } : {}}
          />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ marginLeft: "8px" }}
          >
            {percentage}%
          </MDTypography>
        </div>
      </div>
    </TooltipWrapper>
  );
}

export default ThumbsDown;
