/* eslint-disable react/prop-types */

import * as React from "react";
import { useMaterialUIController } from "context";

export default function IconStyle({ icon: Icon, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return <Icon sx={iconsStyle} {...rest} style={{ cursor: "pointer" }} />;
}
