/* eslint-disable react/prop-types */

import { useState } from "react";

import { Grid } from "@mui/material";

import Icon from "@mui/material/Icon";

// 0xHub Dashboard React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Billing page components
import AddressCopy from "layouts/project/addressCopyWithTooltip";

// Sidenav root
import ConfiguratorRoot from "./rightDrawer";
import { useMaterialUIController } from "context";
import { Divider } from "@mui/material";

import ProjectInfoSubmitModal from "./ProjectInfoSubmitModal";

const UpdateSocialButton = ({ projectId, pairAddress, contractAddress, chain }) => {
  const [controller] = useMaterialUIController();
  const [open, setOpen] = useState(false);
  const [openConfigurator, setOpenConfigurator] = useState(false);

  const { darkMode } = controller;

  const handleClickOpen = () => {
    setOpen(true);
    setOpenConfigurator(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MDBox
        display="inline"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          cursor: "pointer",
          position: "relative",
          marginLeft: "4px",
          top: "-4px",
        }}
        onClick={() => setOpenConfigurator(true)}
      >
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          fontSize="13px"
          sx={{
            border: `1px solid #36BB91`,
            padding: "2px 8px",
            marginTop: "0px",
            borderRadius: "16px",
            textAlign: "center",
            minWidth: "100px",
          }}
        >
          Update Info
        </MDTypography>
      </MDBox>
      <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator: openConfigurator }}>
        <MDBox
          display="inline"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={1}
        >
          <MDBox>
            <MDTypography variant="h5">Update Project Info</MDTypography>
            <ListContainer
              style={{
                marginTop: "0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <MDTypography variant="button" fontSize="14px">
                  <MDTypography
                    variant="text"
                    fontWeight="regular"
                    sx={{
                      marginRight: "8px",
                    }}
                  >
                    Add / Update project info
                  </MDTypography>
                </MDTypography>
              </div>
              <MDBox sx={{ position: "absolute", right: "8px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{
                    color: "#36BB91",
                    border: "1px solid inherit",
                  }}
                >
                  Free
                </MDTypography>
              </MDBox>
            </ListContainer>
          </MDBox>
          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `${size.lg} !important`,
              color: darkMode ? white.main : dark.main,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            })}
            onClick={() => setOpenConfigurator(false)}
            style={{
              position: "absolute",
              top: "24px",
              right: "8px",
            }}
          >
            close
          </Icon>
        </MDBox>
        <Divider />
        <MDBox>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <ListContainer
                sx={{ textAlign: "center", lineHeight: "1" }}
                borderRadius="sm"
                m={"2px"}
                mx={"8px"}
                pb={"0px"}
                pt={"4px"}
              >
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ListContainer style={{ color: "#36BB91" }}>
                    <>
                      <>
                        <MDTypography
                          variant="text"
                          color="warning"
                          fontWeight="regular"
                          fontSize="14px"
                          marginRight="8px"
                        >
                          1.
                        </MDTypography>
                      </>
                    </>
                  </ListContainer>
                  <MDButton
                    onClick={handleClickOpen}
                    variant="outlined"
                    target="_blank"
                    size="small"
                    color="text"
                    sx={{ margin: 0 }}
                  >
                    Click To Update
                  </MDButton>
                  <ProjectInfoSubmitModal
                    open={open}
                    onClose={handleClose}
                    projectId={projectId}
                    pairAddress={pairAddress}
                    contractAddress={contractAddress}
                    chain={chain}
                  />
                </MDTypography>
              </ListContainer>
            </Grid>
            <Grid item xs={12}>
              <ListContainer
                sx={{ textAlign: "center", lineHeight: "1" }}
                borderRadius="sm"
                m={"2px"}
                mx={"8px"}
                pb={"0px"}
                pt={"4px"}
              >
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <>
                      <MDTypography
                        variant="text"
                        color="warning"
                        fontWeight="regular"
                        fontSize="14px"
                        marginRight="8px"
                      >
                        2.
                      </MDTypography>
                    </>
                    0xHub team approves and list your token. <br />
                    <br />
                    <MDTypography
                      variant="text"
                      color="warning"
                      fontWeight="regular"
                      fontSize="14px"
                      margin="16px 0px"
                    >
                      Need Help?
                    </MDTypography>
                    <br />
                    Contact 0xHub admins for support: <br />
                    <AddressCopy
                      address={"https://t.me/OxHub_io"}
                      fullUrl={"https://t.me/OxHub_io"}
                      fontSize={"12px"}
                      addressFormat="20"
                    />
                  </div>
                </MDTypography>
              </ListContainer>
              <Divider sx={{ margin: 0, padding: 0 }} />
            </Grid>
          </Grid>
          <br />
        </MDBox>
      </ConfiguratorRoot>
    </>
  );
};

export default UpdateSocialButton;

const ListContainer = ({ children, justifyContent = "space-between", style = {}, ...rest }) => (
  <MDBox
    {...rest}
    sx={{ display: "flex", alignItems: "center", justifyContent: justifyContent }}
    style={style}
  >
    {children}
  </MDBox>
);
