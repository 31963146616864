/* eslint-disable react/prop-types */

import MDTypography from "components/MDTypography";

import { useMaterialUIController } from "context";

export default function useRewardData(rewardData = []) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return {
    columns: [
      { Header: "Month", accessor: "month", align: "left" },
      { Header: "Rank", accessor: "rank", align: "left" },
      { Header: "Est. Reward", accessor: "reward", align: "left" },
      { Header: "Status", accessor: "is_paid", align: "left" },
    ],
    rows: rewardData.map((data) => {
      const count = Number(data?.count ?? "0").toFixed(0);
      return {
        month: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data?.created_month ?? "-"}
          </MDTypography>
        ),
        rank: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data?.rank ?? "-"}
          </MDTypography>
        ),
        reward: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data?.amount ?? 0} ETH
          </MDTypography>
        ),
        is_paid: (
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="regular"
            sx={{
              color: data?.is_paid ? "#36BB91" : "#F44335",
            }}
          >
            {data?.is_paid ? "Paid" : "Not paid"}
          </MDTypography>
        ),
      };
    }),
  };
}
