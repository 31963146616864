import React, { useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";

import PropTypes from "prop-types";

import useApi from "utils/useApi";
import useLogin from "utils/useLogin";
import { newCommentState } from "../../../atoms/commentAtom";
import { CommentButtons, CommentInputBar } from "./addComments";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Alert from "@mui/material/Alert";
import { useMaterialUIController } from "context";
import Paper from "@mui/material/Paper";
import { avatarStyle } from "utils/avatarStyle";
import { isError } from "lodash";

const formatTimeDifference = (createdOn) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC to the local time zone
  const zonedTime = toZonedTime(new Date(createdOn), timeZone);

  // Format the time difference from now
  return formatDistanceToNow(zonedTime, { addSuffix: true });
};

export const UserComments = ({ projectId, refetch, isLoading, isError, comments = [] }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [commentData, setCommentData] = useRecoilState(newCommentState);
  const [expandedComments, setExpandedComments] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const [reply, setReply] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editCommentId, setEditCommentId] = useState({});
  const [updatedCommentText, setUpdatedCommentText] = useState("");

  const { userInfo, isTokenValid, isLoggedIn } = useLogin("");
  const { getAllCommentsByProjectId, createComments, deleteComment, editComments } = useApi();

  const handleSubmitReply = async (commentId, text, clearInput) => {
    try {
      const newComment = {
        ...commentData,
        comment: text,
        projectId,
        parentId: commentId || null,
      };

      if (isLoggedIn && isTokenValid) {
        await createComments(newComment);
        clearInput("");
        setReply((prev) => ({ ...prev, [commentId]: false }));
        await refetch();
      }
    } catch (error) {
      console.error("Error adding a new comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment(commentId);
      refetch();
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditComment = async (commentId, updatedComment, bearishCount, bullishCount) => {
    try {
      const updatedData = {
        comment: updatedComment,
        bearishCount: bearishCount,
        bullishCount: bullishCount,
      };
      await editComments(commentId, updatedData);
      refetch();
      setEditCommentId(null);
      setIsEditing(false);
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const toggleThreadVisibility = (commentId) => {
    setExpandedComments((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  const handleClickReply = (commentId) => {
    setReply((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReply(value);
    setUpdatedCommentText(value);
    setCommentData({
      ...commentData,
      [name]: value,
      projectId: projectId,
      username: userInfo.username,
    });
  };
  const handleCancelReply = (commentId) => {
    setReply((prevState) => ({
      ...prevState,
      [commentId]: false,
    }));
  };

  const handleCancelComment = (commentId, clearInput) => {
    setReply((prevState) => ({
      ...prevState,
      [commentId]: false,
    }));
    clearInput("");
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleDeleteClick = () => {
    setShowAlert(true);
  };

  const handleEditClick = (commentId) => {
    let commentToEdit = null;

    commentToEdit = comments.find((comment) => comment.id === commentId);
    if (!commentToEdit) {
      comments.forEach((comment) => {
        const threadToEdit = comment.threads.find((thread) => thread.id === commentId);
        if (threadToEdit) {
          commentToEdit = {
            ...threadToEdit,
            parent_id: comment.id,
            project_id: projectId,
          };
        }
      });
    }

    if (commentToEdit) {
      setEditCommentId(commentId);
      setIsEditing(true);
      setUpdatedCommentText(commentToEdit);
    } else {
      console.error("Comment or thread not found.");
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        minHeight: "350px",
        maxHeight: "500px",
        overflowY: "auto",
        backgroundColor: darkMode ? "#131722" : "#f8f9fa",
      }}
      elevation={0}
    >
      <MDBox
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: darkMode ? "#131722" : "#f8f9fa",
          padding: "8px 0",
          mb: 2,
        }}
      >
        <CommentInputBar
          rows={3}
          darkMode={darkMode}
          handleCancelReply={handleCancelComment}
          handleSubmit={handleSubmitReply}
          onChange={handleChange}
          value={reply}
          autofocus={false}
          disabled={!(isLoggedIn && isTokenValid)}
          placeholder="Add a comment"
          name="comment"
          additionalStyles={{
            width: "98%",
            ml: 1,
            backgroundColor: darkMode ? "#131722 !important" : "light !important",
            resize: "vertical",
            "& fieldset": {
              border: "1.3px solid transparent !important",
              background: "linear-gradient(70deg, #0062ff, #29e49d 70%) border-box !important",
              WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
            },
            rows: 2,
            "&:focus-within .submit-button": {
              display: "block",
            },
          }}
        />
      </MDBox>

      <MDBox>
        {!isLoggedIn ||
          (!isTokenValid && (
            <MDTypography>
              <Alert
                severity="info"
                sx={{
                  background: darkMode ? "#344767" : "transparent",
                  color: "inherit",
                }}
              >
                Please login first to add a comment.
              </Alert>
            </MDTypography>
          ))}

        {isTokenValid && isLoading && <MDTypography>Loading...</MDTypography>}

        {isTokenValid && isError && (
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <MDTypography>Error loading comments</MDTypography>
          </MDBox>
        )}

        {isTokenValid && (!Array.isArray(comments) || (comments.length === 0 && !isLoading)) && (
          <MDTypography sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Alert
              severity="info"
              sx={{
                background: darkMode ? "#344767" : "transparent",
                color: "inherit",
              }}
            >
              Be First One to Comment
            </Alert>
          </MDTypography>
        )}

        {comments.map((comment) => (
          <MDBox key={comment.id} style={{ marginBottom: "10px", display: "flex" }}>
            <MDAvatar
              src={comment.username[0]}
              alt={comment.username}
              sx={({ borders: { borderWidth }, palette: { white } }) => ({
                ...avatarStyle(comment.username, darkMode),
                width: 30,
                color: "inherit",
                height: 30,
                ml: 1,
                mr: 1,
                border: `${borderWidth[1]} solid ${white.main}`,
                boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)",
              })}
            />
            <MDBox sx={{ flex: 1 }}>
              <MDTypography fontWeight="small" fontSize="16px">
                {comment.username}
                <MDTypography
                  component="span"
                  color="secondary"
                  fontWeight="light"
                  fontSize="12px"
                  sx={{ ml: 1 }}
                >
                  {formatTimeDifference(comment.createdOn)}
                </MDTypography>
              </MDTypography>
              <MDTypography
                fontWeight="light"
                fontSize="14px"
                sx={{ opacity: 1, color: darkMode ? "white" : "default", width: "96%" }}
              >
                {comment.comment}
              </MDTypography>
              <CommentButtons
                createdByUserId={comment.userId}
                disabled={!(isLoggedIn && isTokenValid)}
                darkMode={darkMode}
                comment={comment.comment}
                bullishCount={comment.bullishCount}
                bearishCount={comment.bearishCount}
                handleClickReply={() => handleClickReply(comment.id)}
                commentId={comment.id}
                expandedComments={expandedComments}
                toggleThreadVisibility={toggleThreadVisibility}
                isParentComment={true}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
                hasThreads={comment.threads.length > 0}
                handleDeleteClick={handleDeleteClick}
                handleDeleteComment={handleDeleteComment}
                handleEditClick={() => handleEditClick(comment.id)}
              />
              {reply[comment.id] && (
                <MDBox
                  sx={{
                    backgroundColor: darkMode ? "#131722" : "#f8f9fa",
                    padding: "8px 0",
                    mb: 1,
                  }}
                >
                  <MDBox p={2} pt={0} display="flex" flexDirection="column">
                    <CommentInputBar
                      darkMode={darkMode}
                      autofocus={true}
                      handleCancelReply={() => handleCancelReply(comment.id)}
                      commentId={comment.id}
                      handleSubmit={handleSubmitReply}
                      additionalStyles={{
                        rows: 2,
                        "&:focused": {
                          display: "block",
                        },
                        "&:focus-within .submit-button": {
                          display: "block",
                        },
                      }}
                    />
                  </MDBox>
                </MDBox>
              )}
              {editCommentId === comment.id && (
                <MDBox
                  sx={{
                    backgroundColor: darkMode ? "#131722" : "#f8f9fa",
                    padding: "8px 0",
                    mb: 1,
                  }}
                >
                  <MDBox p={2} pt={0} display="flex" flexDirection="column">
                    <CommentInputBar
                      darkMode={darkMode}
                      autofocus={true}
                      isEditing={true}
                      onChange={handleChange}
                      handleCancelReply={() => setEditCommentId({})}
                      commentId={editCommentId}
                      handleSubmit={(commentId, text, clearInput, bullishCount, bearishCount) => {
                        handleEditComment(commentId, text, bullishCount, bearishCount);
                        clearInput("");
                      }}
                      currentComment={comment.comment}
                      additionalStyles={{
                        rows: 2,
                        "&:focused": {
                          display: "block",
                        },
                        "&:focus-within .submit-button": {
                          display: "block",
                        },
                      }}
                    />
                  </MDBox>
                </MDBox>
              )}

              {comment.threads.length > 0 && expandedComments[comment.id] && (
                <MDBox sx={{ mt: 1 }}>
                  {comment.threads.map((thread) => (
                    <MDBox key={thread.id} sx={{ display: "flex", mb: 1 }}>
                      <MDAvatar
                        src={thread.username[0]}
                        alt={thread.username}
                        sx={({ borders: { borderWidth }, palette: { white } }) => ({
                          ...avatarStyle(thread.username, darkMode),
                          width: 24,
                          color: "inherit",
                          height: 24,
                          mr: 1,
                          border: `${borderWidth[1]} solid ${white.main}`,
                          boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)",
                        })}
                      />
                      <MDBox sx={{ width: "100%" }}>
                        <MDTypography fontWeight="small" fontSize="16px">
                          {thread.username}
                          <MDTypography
                            component="span"
                            color="secondary"
                            fontWeight="light"
                            fontSize="12px"
                            sx={{ ml: 1 }}
                          >
                            {formatTimeDifference(thread.created_on)}
                          </MDTypography>
                        </MDTypography>
                        <MDTypography
                          fontWeight="light"
                          fontSize="14px"
                          sx={{ opacity: 1, color: darkMode ? "white" : "default", width: "96%" }}
                        >
                          {thread.comment}
                        </MDTypography>

                        <CommentButtons
                          createdByUserId={thread.user_id}
                          darkMode={darkMode}
                          comment={thread.comment}
                          bullishCount={thread.bullish_count}
                          bearishCount={thread.bearish_count}
                          handleClickReply={() => handleClickReply(`${comment.id}-${thread.id}`)}
                          commentId={thread.id}
                          expandedComments={expandedComments}
                          toggleThreadVisibility={toggleThreadVisibility}
                          hasThreads={false}
                          isParentComment={false}
                          handleOpenMenu={handleOpenMenu}
                          handleCloseMenu={handleCloseMenu}
                          handleDeleteClick={handleDeleteClick}
                          handleDeleteComment={handleDeleteComment}
                          handleEditClick={() => {
                            handleEditClick(thread.id);
                          }}
                        />
                        {editCommentId === thread.id && (
                          <MDBox
                            sx={{
                              backgroundColor: darkMode ? "#131722" : "#f8f9fa",
                              padding: "8px 0",
                              mb: 1,
                            }}
                          >
                            <MDBox p={2} pt={0} display="flex" flexDirection="column">
                              <CommentInputBar
                                darkMode={darkMode}
                                autofocus={true}
                                isEditing={true}
                                onChange={handleChange}
                                handleCancelReply={() => setEditCommentId({})}
                                commentId={editCommentId}
                                handleSubmit={(
                                  commentId,
                                  text,
                                  clearInput,
                                  bearishCount,
                                  bullishCount
                                ) => {
                                  handleEditComment(commentId, text, bearishCount, bullishCount);
                                  clearInput("");
                                }}
                                currentComment={thread.comment}
                                additionalStyles={{
                                  rows: 2,
                                  "&:focused": {
                                    display: "block",
                                  },
                                  "&:focus-within .submit-button": {
                                    display: "block",
                                  },
                                }}
                              />
                            </MDBox>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  ))}
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        ))}
      </MDBox>
    </Paper>
  );
};

UserComments.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  refetch: PropTypes.any,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  comments: PropTypes.any,
};
