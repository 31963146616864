import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";

import { format } from "date-fns";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// 0xHub Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { numPrettyFormatter, getDecimalValue } from "utils/price";
import useApi from "utils/useApi";
import useLogin from "utils/useLogin";
import ProfileForm from "./profileForm";
import Chart from "./referralChart";
import PortfolioTracker from "./portfolioTracker";
import Trending from "examples/Trending";
import MDAvatar from "components/MDAvatar";
import EtherscanIcon from "assets/images/etherscanLogo.png";

import { useMaterialUIController } from "context";
import { Card, Divider } from "@mui/material";
import AddressCopy from "layouts/project/addressCopyWithTooltip";
import FormDialog from "./formDialog";
import useReferralData from "./referral/data";
import useRewardData from "./referral/rewardData";
import TextAd from "../advertisement/textAd";
import useMobileView from "utils/useMobileView";

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;

function Dashboard() {
  const [controller] = useMaterialUIController();
  const {
    getProfileInfo,
    createOrUpdateReferral,
    getReferralCodeData,
    createOrUpdateRewardWallet,
    getReferralStatsDataForUser,
  } = useApi();
  const { mobileView } = useMobileView();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("referral");

  const { isLoggedIn, userInfo: tokenUserInfo = {}, logout } = useLogin("", true);

  const { darkMode } = controller;

  const {
    data: userData = [],
    refetch: refetch,
    error,
    isFetched,
  } = useQuery(
    `getProfileInfo`,
    async () => {
      try {
        const res = await getProfileInfo(tokenUserInfo?.id);
        return res;
      } catch (e) {
        if (e?.message === "jwt expired") {
          logout();
          return;
        }
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isLoggedIn && !!tokenUserInfo?.id,
    }
  );

  const { data: referralData = [] } = useQuery(`getReferralCodeData`, () => getReferralCodeData(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn && !!tokenUserInfo?.id,
  });

  const { data: referralStatsData = [] } = useQuery(
    `getReferralStatsDataForUser`,
    () => getReferralStatsDataForUser(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isLoggedIn && !!tokenUserInfo?.id,
    }
  );

  const userInfo = userData?.[0] ?? {};

  const createReferralCode = async (code) => {
    if (!code) {
      return;
    }
    if (!ALPHA_NUMERIC_DASH_REGEX.test(code)) {
      toast.error(`Code should be only digits and numbers`);
      return;
    }
    try {
      await createOrUpdateReferral(code);
      refetch();
      toast.success(
        userInfo?.code
          ? `Referral code updated successfully!`
          : `Referral code created successfully!`
      );
      localStorage.setItem("0xhub-referral", code);
    } catch (e) {
      toast.error(`Failed to save referral code!`);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("0xhub-token") && !isLoggedIn) {
      navigate("/authentication/sign-in");
      return;
    }
  }, [isLoggedIn]);

  const saveRewardWallet = async (walletAddress) => {
    if (!walletAddress) {
      return;
    }
    try {
      await createOrUpdateRewardWallet(walletAddress);
      refetch();
      toast.success(
        userInfo?.code
          ? `Referral wallet address updated successfully!`
          : `Referral wallet address created successfully!`
      );
    } catch (e) {
      toast.error(`Failed to save referral wallet address!`);
    }
  };

  useEffect(() => {
    if (error && error?.message === "Not Authenticated") {
      logout();
      return;
    }
  }, [error]);

  useEffect(() => {
    if (userInfo && userInfo?.code) {
      localStorage.setItem("0xhub-referral", userInfo?.code);
    }
  }, [userInfo]);

  const { columns: referralColumns, rows: referralRows } = useReferralData(
    referralData?.data ?? []
  );

  const referralStats = referralStatsData?.stats ?? [];
  const referralRewards = referralStatsData?.rewards ?? [];
  const referralRewardPool = referralStatsData?.pool ?? [];
  const currentMonthStats = referralStats.find(
    (each) => each.month === format(new Date(), "yyyy-MM")
  );
  const currentMonthRewards = referralRewards.find(
    (each) => each.created_month === format(new Date(), "yyyy-MM")
  );
  const { columns: rewardColumns, rows: rewardRows } = useRewardData(referralRewards || []);

  const referralChartData = useMemo(() => {
    if (referralData?.data?.length > 0) {
      let trafficCount = [];
      let usersCount = [];
      let chartsCount = [];
      let date = [];
      const chartData = (referralData?.data ?? [])?.slice().reverse();
      for (let each of chartData) {
        const formattedDate = format(new Date(each.created_date), "yyyy-MM-dd");
        if (date.includes(formattedDate)) {
          const dateIndex = date.indexOf(formattedDate);
          let existingTrafficCount = trafficCount[dateIndex];
          let existingUsersCount = usersCount[dateIndex];
          let existingChartsCount = chartsCount[dateIndex];
          trafficCount[dateIndex] =
            parseInt(existingTrafficCount, 10) + parseInt(each.traffic_count, 10);
          usersCount[dateIndex] =
            parseInt(existingUsersCount, 10) + parseInt(each.total_ip_source_count, 10);
          chartsCount[dateIndex] =
            parseInt(existingChartsCount, 10) + parseInt(each.total_contract_count, 10);
        } else {
          trafficCount.push(each.traffic_count);
          usersCount.push(each.total_ip_source_count);
          chartsCount.push(each.total_contract_count);
          date.push(formattedDate);
        }
      }

      return {
        datasets: [
          {
            label: "Daily visits",
            data: trafficCount,
            borderColor: "#36BB91",
            backgroundColor: "#36BB91",
          },
          {
            label: "Daily users",
            data: usersCount,
            borderColor: "rgba(255, 159, 64, 0.8)",
            backgroundColor: "rgba(255, 159, 64, 0.8)",
          },
          {
            label: "Daily charts",
            data: chartsCount,
            borderColor: "rgba(54, 162, 235, 0.6)",
            backgroundColor: "rgba(54, 162, 235, 0.6)",
          },
        ],
        date,
      };
    }
    return {
      datasets: [],
      date: [],
    };
  }, [referralData?.data ?? []]);

  const rewardChartData = useMemo(() => {
    if (referralRewards?.length > 0) {
      let count = [];
      let date = [];
      const chartData = (referralRewards ?? [])?.slice().reverse();
      for (let each of chartData) {
        const formattedDate = format(new Date(each.created_month), "yyyy-MM");
        if (date.includes(formattedDate)) {
          const dateIndex = date.indexOf(formattedDate);
          let existingCount = count[dateIndex];
          count[dateIndex] = parseInt(existingCount, 10) + parseInt(each.amount, 10);
        } else {
          count.push(each.amount);
          date.push(formattedDate);
        }
      }
      return {
        datasets: [{ label: "Montly referral reward stats", data: count }],
        date,
      };
    }
    return {
      datasets: [],
      date: [],
    };
  }, [referralData?.data ?? []]);

  const getChartData = (chartData = {}) => {
    return {
      chartData: {
        labels: chartData.date,
        datasets: chartData.datasets.map((each) => {
          return {
            label: each.label,
            lineTension: 0.3,
            data: each.data,
            borderColor: each.borderColor || "#344767",
            backgroundColor: each.backgroundColor || "rgba(75, 192, 192, 0.6)",
          };
        }),
      },
    };
  };

  return (
    <DashboardLayout>
      {/* Main navbar */}
      <DashboardNavbar />
      <Trending />
      <MDBox py={2} mt={0}>
        <Grid container spacing={1}>
          {/* Left side */}
          <Grid item xs={12} md={8} lg={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={1.5} sx={{ minHeight: 250 }}>
                  <ProfileForm userInfo={userInfo} darkMode={darkMode} />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <Card
                    elevation={0}
                    sx={{
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                      paddingTop: 0,
                      minHeight: 250,
                      border: "2px solid #344767",
                    }}
                  >
                    <MDBox
                      bgColor={darkMode ? "#344767" : "linear-gradient(0deg, #52525a, #2b2b2b)"}
                      mx={0}
                      mt={0}
                      p={0}
                      mb={0}
                      textAlign="center"
                      elevation={0}
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        color="white"
                        my={1}
                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        Reward wallet
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ padding: "24px", paddingBottom: 0 }}>
                      <MDTypography variant="button">Ethereum wallet address:</MDTypography>
                      <MDBox
                        mb={1}
                        display="flex"
                        justifyContent="space-between"
                        sx={{ border: "1px solid #aaa", padding: "4px 8px" }}
                      >
                        <MDBox sx={{ display: "flex", alignItems: "center" }}>
                          <MDTypography variant="button">
                            <AddressCopy address={userInfo?.wallet?.address ?? "XXX"} />
                          </MDTypography>
                        </MDBox>
                        <FormDialog
                          actionIcon={<EditIcon />}
                          title="Update your wallet address"
                          description="Change your wallet address to receive rewards from the referral system."
                          secondaryDescription="Note: 0xHub whales holding wallets may receive bonus rewards!"
                          label="wallet"
                          name="wallet"
                          defaultValue={userInfo?.wallet?.address}
                          actionCancelText="Cancel"
                          actionMainText="Submit"
                          onSubmit={(wallet) => saveRewardWallet(wallet)}
                          type="text"
                          disabled={false}
                        />
                      </MDBox>
                      <Grid
                        container
                        spacing={1}
                        layout={"row"}
                        sx={{
                          display: "flex",
                          alignItems: "stretch",
                        }}
                      >
                        {[
                          {
                            label: "Eth Balance",
                            value: `${getDecimalValue(userInfo?.wallet?.ethBalance ?? 0, 4)} ETH`,
                          },
                          {
                            label: "0xH Balance",
                            value: `${numPrettyFormatter(
                              userInfo?.wallet?.memeDefiBalance ?? 0
                            )} 0xH ($${getDecimalValue(
                              userInfo?.wallet?.memeDefiDollarValue ?? 0
                            )})`,
                          },
                        ].map(({ label, value }, i) => {
                          const highlight = label === "Est. rewards";
                          return (
                            <Grid item xs={6} sm={6} key={label + i}>
                              <MDBox
                                sx={{ textAlign: "center", lineHeight: "1", height: "100%" }}
                                bgColor={darkMode ? "transparent" : "grey-100"}
                                borderRadius="sm"
                                border={`${highlight ? "2px" : "0.1px"} solid #344767`}
                                m={"0px"}
                                mt={highlight ? "0px" : "2px"}
                                pb={"4px"}
                                pt={"4px"}
                              >
                                <MDTypography
                                  variant="button"
                                  fontWeight="medium"
                                  sx={{ display: "block" }}
                                >
                                  {label}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="text"
                                  fontWeight="regular"
                                  fontSize="14px"
                                >
                                  {value}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            </Grid>
            <Tabs
              value={currentTab}
              onChange={(e, value) => setCurrentTab(value)}
              aria-label="Profile tabs"
              sx={{
                borderRadius: "0",
                padding: "8px",
                marginBottom: "8px",
              }}
            >
              <Tab value="referral" label="Referral" />
              <Tab value="rewards" label="Rewards" />
              <Tab
                value="portfolio"
                label={<span style={{ opacity: 0.5 }}>Portfolio Tracker</span>}
                disabled
              />
            </Tabs>
            {currentTab === "portfolio" && (
              <MDBox>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <PortfolioTracker />
                  </Grid>
                </Grid>
              </MDBox>
            )}
            {currentTab === "referral" && (
              <MDBox>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={6}>
                    {referralRows?.length === 0 ? (
                      <Card
                        sx={{
                          minHeight: 254,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <MDTypography variant="button" fontWeight="regular">
                          No referral data. <br />
                          Use referral code to see your rewards here.
                        </MDTypography>
                      </Card>
                    ) : (
                      <DataTable
                        table={{ columns: referralColumns, rows: referralRows }}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Card sx={{ padding: "8px" }}>
                      <Chart
                        chartData={getChartData(referralChartData).chartData}
                        displayTitle="Referral chart"
                      />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            )}
            {currentTab === "rewards" && (
              <MDBox>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={6}>
                    {rewardRows?.length === 0 ? (
                      <Card
                        sx={{
                          minHeight: 254,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <MDTypography variant="button" fontWeight="regular">
                          No rewards data. <br />
                          Use referral code to earn rewards every month.
                        </MDTypography>
                      </Card>
                    ) : (
                      <DataTable
                        table={{ columns: rewardColumns, rows: rewardRows }}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Card sx={{ padding: "8px" }}>
                      <Chart
                        chartData={getChartData(rewardChartData).chartData}
                        displayTitle="Referral chart"
                      />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </Grid>
          {/* Right side */}
          <Grid item xs={12} md={4} lg={3}>
            <Card elevation={0} sx={{ borderRadius: 0, padding: "16px" }}>
              <MDTypography
                variant="text"
                fontWeight="medium"
                fontSize="18px"
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                Referral code
              </MDTypography>
              <MDBox>
                <MDTypography variant="button" sx={{ display: "flex", alignItems: "center" }}>
                  Code:&nbsp;
                  <Tooltip
                    title={
                      <span>
                        Use the code while sharing the chart of any token.
                        <br /> Add <code style={{ color: "#36BB91" }}>?s={userInfo?.code}</code> at
                        the end of the chart or click logo of any token to copy chart with your
                        referral code.
                      </span>
                    }
                    placement="bottom"
                  >
                    <InfoIcon sx={{ opacity: 0.5 }} />
                  </Tooltip>
                </MDTypography>
                <MDBox
                  mb={1}
                  display="flex"
                  justifyContent="space-between"
                  sx={{ border: "1px solid #aaa", padding: "4px 8px" }}
                >
                  <MDBox>
                    <MDTypography variant="button">{userInfo?.code}</MDTypography>
                  </MDBox>
                  <FormDialog
                    actionIcon={<EditIcon />}
                    title="Update referral code"
                    description="Change the referral code and click submit to update the code."
                    label="code"
                    name="code"
                    defaultValue={userInfo?.code}
                    actionCancelText="Cancel"
                    actionMainText="Submit"
                    onSubmit={(code) => createReferralCode(code?.toLowerCase())}
                    type="text"
                    disabled={false}
                    inputValidation={(code) => !ALPHA_NUMERIC_DASH_REGEX.test(code)}
                  />
                </MDBox>
                <MDTypography variant="button" fontSize="14px" fontWeight="regular">
                  <Accordion
                    elevation={0}
                    sx={({ typography: { size }, palette: { dark, white } }) => ({
                      color: darkMode ? white.main : dark.main,
                      background: "inherit",
                      border: `none`,
                      padding: 0,
                      margin: 0,
                      fontSize: `${size.md} !important`,
                    })}
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        minHeight: "34px !important",
                        maxHeight: "34px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: 0,
                          display: "flex",
                          alignItems: "center",
                        },
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <MDTypography
                        variant="button"
                        fontSize="14px"
                        fontWeight="regular"
                        sx={{
                          textDecoration: "underline",
                        }}
                      >
                        How to use?
                      </MDTypography>
                      &nbsp;⚡️
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: 0,
                        margin: "4px",
                        fontSize: 14,
                      }}
                    >
                      <ul style={{ opacity: 0.8, marginLeft: "12px" }}>
                        <li>
                          Use the code at the end of a chart👇
                          <br />
                          <code style={{ color: "#36BB91" }}>?s={userInfo?.code}</code>
                        </li>
                        <li>
                          Or if you are loggedIn,
                          <br />
                          Click the logo on every token to copy chart with the refferal code.
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </MDTypography>
              </MDBox>
            </Card>
            <Card elevation={0} sx={{ borderRadius: 0, padding: "16px", marginTop: "8px" }}>
              <MDTypography
                variant="text"
                fontWeight="medium"
                fontSize="18px"
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                Reward Pool
              </MDTypography>
              <MDBox>
                <Grid container spacing={0} mt={"8px"}>
                  <Grid item xs={6}>
                    <MDBox
                      sx={{ textAlign: "center", lineHeight: "1" }}
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="sm"
                      border={`2px solid #344767`}
                      m={"2px"}
                      mt={"2px"}
                      pb={"4px"}
                      pt={"4px"}
                    >
                      <MDTypography variant="button" fontWeight="medium" sx={{ display: "block" }}>
                        Total Rewards
                      </MDTypography>
                      <a
                        href={`https://etherscan.io/address/${referralRewardPool[0]?.wallet}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <MDTypography
                          variant="p"
                          color="text"
                          fontWeight="regular"
                          fontSize="14px"
                          sx={{
                            textDecoration: "underline",
                          }}
                        >
                          {getDecimalValue(referralRewardPool[0]?.balance ?? 0, 4)} eth
                        </MDTypography>
                      </a>
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox
                      sx={{ textAlign: "center", lineHeight: "1" }}
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="sm"
                      border={`2px solid #344767`}
                      m={"2px"}
                      mt={"2px"}
                      pb={"4px"}
                      pt={"4px"}
                    >
                      <MDTypography variant="button" fontWeight="medium" sx={{ display: "block" }}>
                        Total participants
                      </MDTypography>
                      <MDTypography variant="p" color="text" fontWeight="regular" fontSize="14px">
                        {referralRewardPool[0]?.participants ?? 0} users
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox sx={{ marginTop: "8px", marginBottom: "8px" }}>
              <TextAd placement="profile_right_bar" />
            </MDBox>
            <Card
              sx={{
                "&.MuiPaper-root": {
                  padding: mobileView ? "4px" : "8px",
                  marginTop: "8px",
                },
              }}
              elevation={0}
            >
              <MDTypography
                variant="text"
                fontWeight="medium"
                fontSize="18px"
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                Your Referral Stats
              </MDTypography>
              <Grid container spacing={0} mt={"8px"}>
                <Grid item xs={12}>
                  <MDBox
                    sx={{ textAlign: "center", lineHeight: "1" }}
                    bgColor={darkMode ? "transparent" : "grey-100"}
                    borderRadius="sm"
                    border={`2px solid #344767`}
                    m={"2px"}
                    mt={"2px"}
                    pb={"4px"}
                    pt={"4px"}
                  >
                    <MDTypography variant="button" fontWeight="medium" sx={{ display: "block" }}>
                      Est. Upcoming Rewards
                    </MDTypography>
                    <MDTypography variant="p" color="text" fontWeight="regular" fontSize="14px">
                      {currentMonthRewards?.amount ?? 0} ETH
                    </MDTypography>
                  </MDBox>
                </Grid>
                {[
                  {
                    label: "User rank",
                    value: `${
                      currentMonthRewards?.rank
                        ? `${currentMonthRewards?.rank} / ${
                            referralRewardPool[0]?.participants ?? 0
                          }`
                        : "-"
                    }`,
                  },
                  {
                    label: "Total users",
                    value: `${numPrettyFormatter(
                      currentMonthStats?.total_source_ip_count ?? 0,
                      0
                    )}`,
                  },
                  {
                    label: "Total visits",
                    value: `${numPrettyFormatter(currentMonthStats?.traffic_count ?? 0, 0)}`,
                  },
                  {
                    label: "Total charts",
                    value: `${numPrettyFormatter(currentMonthStats?.total_contract_count ?? 0, 0)}`,
                  },
                ].map(({ label, value }, i) => {
                  const highlight = label === "Est. rewards";
                  return (
                    <Grid item xs={6} sm={6} key={label + i}>
                      <MDBox
                        sx={{ textAlign: "center", lineHeight: "1" }}
                        bgColor={darkMode ? "transparent" : "grey-100"}
                        borderRadius="sm"
                        border={`${highlight ? "2px" : "0.1px"} solid #344767`}
                        m={"2px"}
                        mt={highlight ? "0px" : "2px"}
                        pb={"4px"}
                        pt={"4px"}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          sx={{ display: "block" }}
                        >
                          {label}
                        </MDTypography>
                        <MDTypography variant="p" color="text" fontWeight="regular" fontSize="14px">
                          {value}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  );
                })}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
